import { Alert, Button, defaultTheme, IconButton, Pane,RefreshIcon,ShareIcon,Text, toaster, Tooltip } from "evergreen-ui";
import * as React from "react"
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import Editor from "./Editor";
import UiStore from "../../../../stores/UiStore";
import { inject, observer } from "mobx-react";
import IconSelector from "../IconSelector";
import PublicityStatusSelector from "../PublicityStatusSelector";
import translate from "app/i18n";
import slugify from "slugify";
import { useDebouncedCallback } from 'use-debounce';
import NotesStore from "app/stores/NotesStore";
import ProjectsStore from "app/stores/ProjectsStore";
import BacklinksSelector from "../BacklinksSelector";

interface Props {
  ui?: UiStore,
  notes?: NotesStore,
  projects?: ProjectsStore
}

const Form = (observer((props: Props) => {
  const { ui, notes, projects } = props;
  const note = ui.currentNote;
  const {noteForm, noteOptions} = translate();

  const handleNoteTitleSave = useDebouncedCallback(
    async (value: string) => {
      try{
        const n = await notes.updateWithoutAdd({id: ui.currentNote.id, title: value});
        ui.currentNote.setBreadcrumbs(n.breadcrumbs);
      }catch(e){
        toaster.danger(e.message);
        console.error(e);
      }
    },
    300
  );

  const handleNoteSlugSave = useDebouncedCallback(
    async (value: string) => {
      try{
        await notes.update({id: ui.currentNote.id, slug: value});
      }catch(e){
        toaster.danger(e.message);
        console.error(e);
      }
    },
    500
  );

  return <form style={{ width: '100%' }}>
    <Pane justifyContent="center" display="flex">
      <Pane maxWidth={832} width="100%">
        {note.deleted &&
          <Alert intent="danger" marginBottom={32} alignItems="center">
            <Pane display="flex" justifyContent="space-between" alignItems="center">
              <Text>{noteForm.thisPageIsInTrash}</Text>
            </Pane>
          </Alert>
        }

        <Pane display="flex" alignItems="flex-end">
          <IconSelector note={note} />
          <PublicityStatusSelector note={note} />
          {note.backlinks.length > 0 && <BacklinksSelector note={note} />}
        </Pane>

        <Pane display="flex" flexDirection="column">
          <Pane display="flex" justifyContent="center">
            <TitleTextarea
              autoFocus
              placeholder={noteForm.giveYourNoteTitle}
              value={note.title}
              disabled={projects.isReadonlyUser}
              onChange={(e: React.SyntheticEvent<HTMLTextAreaElement>) => {
                note.setTitle(e.currentTarget.value);
                handleNoteTitleSave(e.currentTarget.value);
              }}
            />
          </Pane>

          <SlugOptionsWrapper display="flex" justifyContent="center" marginTop={16} position="relative">
            <SlugTextarea
              disabled={note?.deleted || projects.isReadonlyUser}
              placeholder="note-slug"
              value={note.slug}
              onChange={(e: React.SyntheticEvent<HTMLTextAreaElement>) => {
                if(e.currentTarget.value && e.currentTarget.value.length > 0){
                  const slugified = slugify(e.currentTarget.value);
                  note.setSlug(slugified);
                  handleNoteSlugSave(slugified);
                }else{
                  note.setSlug("");
                }
              }}
            />

            <SlugOptionsButtons>
              <Tooltip content={noteOptions.openArticle}>
                <SlugButton disabled={note?.deleted || !projects.current?.isPublicVisibility} is="a" target="_blank" href={note?.publicUrl} marginRight={4} appearance="minimal" icon={ShareIcon}/>
              </Tooltip>

              <Tooltip content={noteOptions.refreshSlugFromTitle}>
                <SlugButton disabled={note?.deleted || projects.isReadonlyUser} appearance="minimal" icon={RefreshIcon} onClick={(e: React.SyntheticEvent<HTMLInputElement>) => {
                  e.preventDefault();

                  if(note.title && note.title.length > 0){
                    const slugified = slugify(note.title.toLowerCase());
                    note.setSlug(slugified);
                    handleNoteSlugSave(slugified);
                  }
                }}/>
              </Tooltip>
            </SlugOptionsButtons>
          </SlugOptionsWrapper>
        </Pane>

        <Pane>
          {note.type === "text" && <Editor />}
        </Pane>
      </Pane>
    </Pane>
  </form>
}));

const SlugOptionsButtons = styled(Pane)`
  position: absolute;
  right: 0px;
  top: 0px;
  visibility: hidden;
  display: flex;
  background-color: ${defaultTheme.colors.background.tint2};
  border-radius: 5px;
`

const SlugButton = styled(IconButton)`
  color: ${defaultTheme.colors.text.default};
`

const SlugOptionsWrapper = styled(Pane)`
  &:hover ${SlugOptionsButtons}{
    visibility: visible;
  }
`

const SlugTextarea = styled(TextareaAutosize)`
  border: 0;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 0;
  font-size: 22px;
  font-weight: 400;
  resize: none;
  width: 100%;
  color: ${defaultTheme.colors.text.muted};
  font-family: ${defaultTheme.typography.fontFamilies.ui};
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;

  &:focus{
    box-shadow: none;
    border: none;
    outline: 0;
    border-bottom: 1px dashed rgba(0,0,0,.2);
  }

  &:hover{
    border-bottom: 1px dashed rgba(0,0,0,.2);
  }

  ::placeholder{
    color: rgba(55, 53, 47, 0.15);
  }
`

const TitleTextarea = styled(TextareaAutosize)`
  border: 0;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 0;
  font-size: 32px;
  font-weight: 600;
  resize: none;
  width: 100%;
  color: rgb(55 53 47);
  font-family: ${defaultTheme.typography.fontFamilies.ui};
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;

  &:focus{
    box-shadow: none;
    border: none;
    outline: 0;
    border-bottom: 1px dashed rgba(0,0,0,.2);
  }

  &:hover{
    border-bottom: 1px dashed rgba(0,0,0,.2);
  }

  ::placeholder{
    color: rgba(55, 53, 47, 0.15);
  }
`

export default inject('ui', 'notes', 'projects')(Form);