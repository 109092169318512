import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { Pane, Heading, Text, ExpandAllIcon, defaultTheme, Popover, Position, Menu, Strong, TickIcon, AddIcon, RemoveIcon, LogOutIcon } from 'evergreen-ui';
import styled from 'styled-components';
import ProjectsStore from '../stores/ProjectsStore';
import SquaredAvatar from 'app/components/SquaredAvatar';
import NewProjectDialog from 'app/dialogs/NewProjectDialog';
import UiStore from 'app/stores/UiStore';
import { useHistory } from 'react-router-dom';
import translate, { isLocale, setLocale } from 'app/i18n';
import AuthStore from 'app/stores/AuthStore';

interface Props {
  auth?: AuthStore,
  ui?: UiStore,
  projects?: ProjectsStore
}

const ProjectsMenu = (observer((props: Props) => {
  const {accountMenu, projectMenu} = translate();
  const history = useHistory();
  const {ui, projects, auth} = props;

  const handleCreateNewProject = () => {
    ui?.showDialog(<NewProjectDialog />);
  }

  const handleChangeProject = (id: string) => {
    projects.setCurrent(id);
    history.push('/home');
  }

  const handleChangeLocale = (locale: string) => {

    return (e: React.SyntheticEvent,) => {
      e.preventDefault();

      if(isLocale(locale)){
        return;
      }
  
      try{
        setLocale(locale);
        location.reload();
      }catch(e){
        console.error(e);
      }
    }
  }

  return (
    <Popover
      minWidth={270}
      position={Position.BOTTOM_RIGHT}
      content={({close}) => {
        return <Menu>
          <Menu.Group>
            {projects.data.map(x => {
              return (
                <Menu.Item onSelect={() => { close(); handleChangeProject(x.id) }} key={x.id} paddingY={20}>
                  <Pane display="flex" flex="1" alignItems="center">
                    <SquaredAvatar size={30} marginRight={12} name={x.name} src={x.logoUrl}/>
                    <Pane display="flex" alignItems="center" justifyContent="space-between" flex="1">
                      <Pane display="flex" flexDirection="column" marginRight={8}>
                        <Strong size={300}>{x.name}</Strong>
                        <Text size={300} color="muted">{x.pricingPlan} - {x.members.length} {x.members.length > 1 ? projectMenu.members : projectMenu.member}</Text>
                      </Pane>
                      {projects.current?.id === x.id && <TickIcon />}
                    </Pane>
                  </Pane>
                </Menu.Item>
              )
            })}
          </Menu.Group>
          <Menu.Divider />

          <Menu.Item icon={AddIcon} onSelect={() => { close(); handleCreateNewProject(); }}>Create project</Menu.Item>

          <Menu.Divider />
          <Menu.Group title={accountMenu.locale}>
            <Menu.Item onClick={handleChangeLocale("en")}>
              <Pane display="flex" flex="1" alignItems="center" justifyContent="space-between">
                <Text>🇺🇸 &nbsp; English</Text>
                {isLocale("en") && <TickIcon />}
              </Pane>
            </Menu.Item>
            <Menu.Item onClick={handleChangeLocale("ru")}>
              <Pane display="flex" flex="1" alignItems="center" justifyContent="space-between">
                <Text>🇷🇺 &nbsp; Russian</Text>
                {isLocale("ru") && <TickIcon />}
              </Pane>
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item icon={LogOutIcon} intent="danger" onClick={() => { auth.clear() }}>
              Logout
            </Menu.Item>
          </Menu.Group>
        </Menu>
      }}
    >
      <Pane display="flex" cursor="pointer" justifyContent="flex-start" textDecoration="none">
        <ProjectWrapper flex={1} display="flex" alignItems="center" paddingY={7} paddingX={13}>
          <Pane flex={1} display="flex">
            <SquaredAvatar size={40} marginRight={12} name={projects.current?.name} src={projects.current?.logoUrl}/>
            <Pane display="flex" flexDirection="column">
              <Text color="muted">{projects.current?.pricingPlan}</Text>
              <Heading maxWidth={145} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{projects.current?.name}</Heading>
            </Pane>
          </Pane>
          <ExpandAllIcon size={14} color="muted"/>
        </ProjectWrapper>
      </Pane>
    </Popover>
  )
}));


const ProjectWrapper = styled(Pane)`
  position: relative;

  &:hover{
    background-color: ${defaultTheme.colors.border.default};
  }
`

export default inject('ui', 'projects', 'auth')(ProjectsMenu);