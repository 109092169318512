import * as React from 'react';
import styled from 'styled-components';
import { Heading as EgHeading, Paragraph as EgParagraph, Button as EgButton, defaultTheme, ButtonOwnProps} from 'evergreen-ui';

const Heading = styled(EgHeading)`
  color: rgb(55, 53, 47);
`

const Paragraph = styled(EgParagraph)`
  color: rgb(55, 53, 47);
`

const PrimaryButton = styled(EgButton)`
  background-image: none;
  background-color: #027bfc;

  &:hover, :not([disabled]):not([data-disabled]):hover{
    background-image: none;
    background-color: #015cbd;
  }

  :not([disabled]):not([data-disabled]):focus{
    box-shadow: 0 0 0 3px rgb(7 136 222 / 40%), inset 0 0 0 1px rgb(67 90 111 / 14%), inset 0 -1px 1px 0 rgb(67 90 111 / 30%);
  }
`

const MinimalButtonWrapper = styled(EgButton)`
  :not([disabled]):not([data-disabled]):focus{
    box-shadow: 0 0 0 3px rgb(7 136 222 / 40%), inset 0 0 0 1px rgb(67 90 111 / 14%), inset 0 -1px 1px 0 rgb(67 90 111 / 30%);
  }
`

function MinimalButton(props){
  const {children, ...other} = props;
  return <MinimalButtonWrapper style={{ color: defaultTheme.colors.text.default }} appearance="minimal" {...other}>{children}</MinimalButtonWrapper>
}

export { Heading, Paragraph, PrimaryButton, MinimalButton };