import {
  serializable
} from "serializr"

export default class FeedbackCount {
  @serializable
  noteId: string = null;
  @serializable
  title: string = null;
  @serializable
  count: number = null;
}