import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, defaultTheme, Label, Pane, ShareIcon, Strong, Switch, Text, TextInput, toaster } from 'evergreen-ui';
import Panel from 'app/components/Panel';
import IntegrationsStore from 'app/stores/IntegrationsStore';
import Integration from 'app/models/Integration';
import translate from 'app/i18n';
import ProjectsStore from 'app/stores/ProjectsStore';
import { PrimaryButton } from 'app/components/Typography';
const ChatraImage = require('../../../../images/chatra.png');

interface Props {
  isShown: boolean,
  onClick?: () => void,
  integrations?: IntegrationsStore,
  projects?: ProjectsStore,
  integration: Integration
}

const ChatraPanel = observer((props: Props) => {
  const {integrations, projects, integration, isShown, onClick} = props;

  const [saving, setSaving] = React.useState(false);
  const {common} = translate();

  const handleToggleChanged = () => {
    integration?.setEnabled(!integration.enabled);
  }

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    integration.setChatraId(e.currentTarget.value);
  }

  const handleSubmitChanges = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);
    try{
      await integrations.update(integration.toPayload());
      toaster.success(common.savedMessage, { duration: 2 });
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setSaving(false);
    }
  };

  return <form onSubmit={handleSubmitChanges}>
    <Panel previewValue={<Switch disabled={projects.isReadonlyUser} onChange={handleToggleChanged} checked={integration.enabled}/>}
                  headerLogoUrl={ChatraImage}
                  header={<Strong>Chatra</Strong>} isShown={isShown} onClick={onClick} 
                  footer={<PrimaryButton disabled={projects.isReadonlyUser} type="submit" appearance="primary" isLoading={saving}>{common.btnSaveChanges}</PrimaryButton>}>
      
      <Pane display="flex" marginY={12} flexDirection="column">
        <Label marginBottom={5}>ID</Label>
        <TextInput onChange={handleChange} autoFocus={true} placeholder="w8DDjmQLDh2APzSwy" width="100%" height={38} marginBottom={20} value={integration.chatraId || ''}/>
      </Pane>
    </Panel>
  </form>
});

export default inject('integrations', 'projects')(ChatraPanel);