import translate from 'app/i18n';
import { Pane, SearchIcon, SearchInput, Text } from 'evergreen-ui';
import { IObservableArray } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import Member from '../../../../models/Member';
import ProjectsStore from '../../../../stores/ProjectsStore';
import Loader from '../../../Loader';
import Item from './Item';

interface Props {
  projects?: ProjectsStore
}

const searchBy = (q?: string, members?: IObservableArray<Member>) => {
  if(!q || q.trim().length === 0){
    return members;
  }

  return members.filter(x => {
    return x.user.name.toLowerCase().includes(q.toLowerCase()) || 
            x.user.email.toLowerCase().includes(q.toLowerCase())
  })
}

const NoMembersFound = () => {
  const {membersScreen} = translate();

  return <Text padding={100} alignItems="center" display="flex" flex="1" justifyContent="center">
    <SearchIcon marginRight={10}/> {membersScreen.noMembersFound}
  </Text>
}

const Members = observer((props: Props) => {
  const {membersScreen} = translate();
  const {projects} = props;
  const [query, setQuery] = React.useState(null);

  const handleFilterChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  }

  const items = projects.current ? searchBy(query, projects.current.members) : [];

  return <Pane width="100%">
    <Pane display="flex" marginTop={40} width="100%" flexDirection="column">
    
      <Pane display="flex">
        <SearchInput height={38} placeholder={membersScreen.filterMembers} width="100%" onChange={handleFilterChange}/>
      </Pane>

      <Pane display="flex" flex="1" flexDirection="column" marginTop={20} borderTop="default" paddingTop={10}>
        {projects.current ?
          items.length === 0 ? <NoMembersFound /> : items.map(x => <Item key={x.id} member={x} />)
        :
          <Loader />
        }
      </Pane>
    </Pane>
  </Pane>
});

export default inject('projects')(Members);