import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { Pane, Text } from 'evergreen-ui';
import FadeIn from 'app/components/FadeIn';
import Breadcrumbs from 'app/components/Breadcrumbs';
import ProjectsStore from 'app/stores/ProjectsStore';
import { Tab, Tablist } from 'app/components/Tablist';
import SummaryStore from 'app/stores/SummaryStore';
import Item from 'app/components/NoteList/components/Item';
import RecentNotesTablist from './RecentNotesTablist';
import translate from 'app/i18n';
import { Heading, Paragraph } from 'app/components/Typography';

interface Props {
  summary?: SummaryStore
}

const Dashboard = observer((props: Props) => {
  const { summary } = props;
  const { dashboardScreen } = translate();

  React.useEffect(() => {
    document.title = "Wannadocs - Dashboard";
  }, []);

  useEffect(() => {
    summary.loadWorkedOn();
  }, []);

  return (<>
    <Pane display="flex" flex="1" backgroundColor="white">
      <Pane display="flex" flex="1" flexDirection="column" paddingX={17} paddingY={24}>
        <Pane marginX={15} display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center" marginBottom={20} justifyContent="space-between">
            <Heading size={900}>{dashboardScreen.title}</Heading>
          </Pane>
        </Pane>

        <Pane marginX={15} marginTop={4}>
          <FadeIn style={{display: 'flex'}}>
            <Pane display="flex" flex="1" flexDirection="column">
              <Pane>
                <RecentNotesTablist />
              </Pane>
            </Pane>
          </FadeIn>
        </Pane>
      </Pane>
    </Pane>
    </>)
});

export default inject('summary')(Dashboard);
