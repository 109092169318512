import translate from "app/i18n";
import { Button, CaretDownIcon, defaultTheme, Pane, PlusIcon, SelectMenu, SelectMenuItem } from "evergreen-ui"
import * as React from "react"
import styled from "styled-components"

interface Props {
  options?: SelectMenuItem[],
  selected?: string | string[],
  title?: string,
  iconBefore?: React.ElementType | JSX.Element | null | false,
  buttonTitle?: string,
  children?: React.ReactNode,
  onSelect?(item: SelectMenuItem): void
}

export default (props: Props) => {
  const { options, selected, title, iconBefore, buttonTitle, onSelect, children } = props;
  const {common} = translate();

  return <Pane display="flex" border="default" borderRadius={5}>
    <SelectMenu
      closeOnSelect={true}
      isMultiSelect={false}
      title={title}
      options={options}
      selected={selected}
      onSelect={onSelect}
      filterPlaceholder={common.filterPlaceholder}
    >
      <Button height={30} borderBottomRightRadius={children ? 0 : 3} borderTopRightRadius={children ? 0 : 3}
        style={{ color: defaultTheme.colors.text.default }}
        appearance="minimal"
        iconBefore={iconBefore}
        iconAfter={CaretDownIcon}>{buttonTitle}</Button>
    </SelectMenu>

    {children && (
        React.Children.map(children, child => (
          child ? <IconWrapper onClick={(child as React.ReactElement<any>).props.onClick}>{child}</IconWrapper> : <></>
        ))
    )}

  </Pane>
}

const IconWrapper = styled('div')<{ onClick?: (e: React.SyntheticEvent) => void }>((props) => `
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px;
  align-items: center;
  justify-content: center;
  color: ${defaultTheme.colors.text.default};
  cursor: pointer;

  &:hover {
    background: rgba(67, 90, 111, 0.06);
  }
`)

