import { NativeTypes } from "react-dnd-html5-backend"
import { useDrop } from "react-dnd"
import * as React from "react";
import styled from "styled-components";
import { Badge, defaultTheme, Link, Pane, Text } from "evergreen-ui";
import translate from "../i18n";

export interface RefObject {
  reset: () => void
}

interface Props {
  url?: string,
  name?: string,
  backgroundColor?: string,
  hidePreview?: boolean;
  onChange?(file: File): void
}

const FORMATS = ["jpg", "png", "gif"]

const ImageFileInput = React.forwardRef((props: Props, ref: React.Ref<RefObject>) => {

  const {imageFileInput} = translate();

  React.useImperativeHandle(ref, () => ({ reset }));

  const reset = () => {
    setImage(null);
    fileInputRef.current.value = "";
    if(url){
      setPreview(url);
    }else{
      setPreview(null);
    }
  }

  const {name, url, backgroundColor, onChange, hidePreview} = props;

  const [image, setImage] = React.useState<File>();
  const [preview, setPreview] = React.useState<string>();

  const fileInputRef = React.useRef<HTMLInputElement>();

  const [{ isDragging }, dropRef] = useDrop({
      accept: NativeTypes.FILE,

      collect: ((monitor) => ({
          isDragging: !!monitor.isOver()
      })),
      drop: (item: any) => {
        if (item.files.length > 0 && item.files[0].type.substr(0, 5) === "image"){
          if(onChange) { onChange(item.files[0]) }
          setImage(item.files[0]);
        }
      },
  });

  React.useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  React.useEffect(() => setPreview(url), [url]);

  const handleOpenSelectFile = (e: React.SyntheticEvent) => {
    e.preventDefault();
    fileInputRef.current.click();
  }

  const handleFileChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files[0];

    if (file && file.type.substr(0, 5) === "image") {
      setImage(file);
      if(onChange) { onChange(file) }
    } else {
      setImage(null);
      if(onChange) { onChange(null) }
    }
  }

  return <Pane height={140} width="100%" flex="1" display="flex">
      {!hidePreview && <Pane width={140} 
            display="flex" height={140} alignItems="center"
            justifyContent="center"
            backgroundColor={backgroundColor || defaultTheme.colors.background.tint1}
            padding={10}
            borderRadius={3}
            marginRight={10}>

        {preview ? <img src={preview} style={{maxWidth: '100px', maxHeight: '100px'}}/> : <Text fontSize={26} color="white">?</Text>}
      </Pane>}

      <DashedBorder ref={dropRef} style={isDragging ? {background: defaultTheme.colors.background.tint1} : {}}>
        <Pane display="flex" alignItems="center" flexDirection="column">
          <Pane marginBottom={5}>
            {FORMATS.map(x => <Badge color="neutral" marginRight={8} key={x}>{x}</Badge>)}
          </Pane>

          <Pane>
            <Text size={400}>{imageFileInput.dropYourFiles} <Link href="#" onClick={handleOpenSelectFile}>{imageFileInput.orBrowse}</Link></Text>
          </Pane>
        </Pane>

        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          name={name}
          accept="image/*"
          onChange={handleFileChange}
        />
      </DashedBorder>
    </Pane>;
});

const DashedBorder = styled('div')`
  border: 1px dashed ${defaultTheme.colors.border.default};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export default ImageFileInput;