import BaseModel from "./BaseModel";
import {
  date,
  serializable
} from "serializr"
import { makeObservable, observable } from "mobx";

export default class Purchase extends BaseModel {
  @serializable
  cancelUrl: string = null;

  @serializable(date())
  nextBillDate: Date = null;

  @serializable
  paidPriceUsd: string = null;

  @serializable
  paymentProcessor: string = null;

  @serializable
  receiptUrl: string = null;

  @serializable
  status: string = null;

  @serializable
  updateUrl: string = null;

  @serializable
  paymentProcessorSubscriptionId: string = null;

  @serializable
  subscriptionPlanId: string = null;

  @serializable(date())
  createdAt: Date = null;

  @serializable(date())
  updatedAt: Date = null;

  @serializable
  subscriptionPlanName: string = null;

  @serializable
  paymentProcessorPlanId: string = null;
  
  constructor(){
    super();

    makeObservable(this, {
      id: observable
    })
  }
}