import * as React from 'react'
import { Button, ChevronLeftIcon, defaultTheme, Link, Pane, Text } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react';

interface BreadcrumbItem {
  href: string,
  label: string
}

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumbs = (props: Props) => {
  const { items } = props;

  const history = useHistory();

  const goTo = (href: string) => {
    history.push(href);
  }

  const renderLabel = (item: BreadcrumbItem, index: number) => {
    return item.label;
  }

  return <Pane flex={1} flexWrap="wrap" alignItems="center" display="flex" marginBottom={8}>
    {items.map((item, i) => {
      return <Text key={i}>
        <StyledLink size={500} onClick={(e: React.SyntheticEvent) => { e.preventDefault(); goTo(item.href) }} href={item.href}>{renderLabel(item, i)}</StyledLink>
        {(i === items.length - 1) ? <></> : <Text color={defaultTheme.colors.border.default} size={500} marginX={5}>/</Text>}
      </Text>
    })}
  </Pane>
};

const StyledLink = styled(Link)`
  color: ${defaultTheme.colors.text.muted};
  text-decoration: none;

  &:hover{
    color: ${defaultTheme.colors.text.muted};
    text-decoration: underline;
  }
`

export default observer(Breadcrumbs);
