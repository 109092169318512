import translate from 'app/i18n';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Form } from '../../components/Settings/components/Members';
import Layout from './Layout';


const Members = observer(() => {
  const {membersScreen} = translate();

  return <Layout header={membersScreen.title}>
    <Form />
  </Layout>
});

export default Members;