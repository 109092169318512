import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Badge, Button, defaultTheme, Dialog, Pane, SearchIcon, SearchInput, Spinner, Strong, Text, TextInput, toaster } from 'evergreen-ui';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import NotesStore from '../../stores/NotesStore';
import Item from './Item';
import { useDebouncedCallback } from 'use-debounce';
import Note from 'app/models/Note';
import translate from 'app/i18n';
import useKeyPress from 'react-use/lib/useKeyPress';
import keyboardJs from "keyboardjs";
import application from "../../components/Application";
import UiStore from 'app/stores/UiStore';

interface Props {
  ui?: UiStore,
  notes?: NotesStore,
}

const SEARCH_COMBINATION = application.isMacOs() ? "command+k" : "ctrl+k";

const SearchDialog = (observer((props: Props) => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState<Note[]>(null);

  const [isArrowUpPressed] = useKeyPress('ArrowUp');
  const [isArrowDownPressed] = useKeyPress('ArrowDown');
  const [isEnterPressed] = useKeyPress('Enter');

  const [selected, setSelected] = React.useState(null);

  const { notes, ui } = props;

  const i18n = translate().searchDialog;

  const runSearch = useDebouncedCallback(
    async (value) => {
      try{
        const items = await notes.globalSearch(value);
        setResults(items);
        setLoading(false)
      }catch(e){
        toaster.danger(`${i18n.error} ${e.message}`)
        console.error(e);
        setLoading(false);
      }
    },
    300
  );

  const handleSearch = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    if(!e.currentTarget.value){
      setLoading(false);
      setResults(null);
    }else{
      runSearch(e.currentTarget.value);
    }
  }

  React.useEffect(() => {
    if(!results) return;
    if(!ui.isShowSearchDialog) return;

    if(isArrowUpPressed){
      const index = selected !== null ? selected - 1 : 0;

      if(results[index]){
        setSelected(index);
      }else{
        setSelected(results.length - 1);
      }

    }else if(isArrowDownPressed){
      const index = selected !== null ? selected + 1 : 0;

      if(results[index]){
        setSelected(index);
      }else{
        setSelected(0);
      }
    }else if(isEnterPressed){
      if(results[selected]){
        setSelected(null);
        ui.setShowSearchDialog(false);
        history.push(`/notes/${results[selected].id}`);
      }
    }
  }, [isArrowUpPressed, isArrowDownPressed, isEnterPressed]);

  React.useEffect(() => {
    setSelected(null);
  }, [results]);


  React.useEffect(() => {
    if (!keyboardJs) {
      return;
    }

    const down = (e) => { e.stopPropagation(); ui.setShowSearchDialog(true); }

    keyboardJs.bind(SEARCH_COMBINATION, down);

    return () => {
      keyboardJs.unbind(SEARCH_COMBINATION, down);
    };
  }, []);

  const handleItemClick = (note: Note) => {
    setSelected(null);
    ui.setShowSearchDialog(false);
    history.push(`/notes/${note.id}`);
  }

  const renderResults = () => {
    if(loading){
      return <Pane display="flex" flex="1" justifyContent="center" padding={30}>
        <Spinner marginX="auto" />
      </Pane>
    }

    if(results?.length === 0){
      return <Pane display="flex" flex="1" justifyContent="center" padding={30}>
        <Text color="muted">{i18n.empty}</Text>
      </Pane>
    }

    return <Pane flexDirection="column" display="flex" flex="1">
      {results && results.map((x, index) => <Item onClick={handleItemClick} isSelected={index === selected} key={x.id} note={x}/>)}
    </Pane>
  }

  return <Dialog
    hasHeader={false}
    hasFooter={false}
    isShown={ui.isShowSearchDialog}
    onCloseComplete={() => ui.setShowSearchDialog(false)}
    contentContainerProps={{padding:0, margin: 0, display: 'flex', justifyContent: 'center'}}
  >
    <Pane display="flex" flexDirection="column">
      <Pane borderBottom={results ? 'default' : 'none'} paddingX={8}>
        <WrappedSearchInput autoFocus={true} width="100%" placeholder={i18n.placeholder} height={38} onChange={handleSearch}/>
      </Pane>
    </Pane>

    <ResultsWrapper display="flex" flexDirection="column" flex="1" maxHeight={300} overflowY="auto">
      {renderResults()}
    </ResultsWrapper>

    {results && <Pane paddingX={18} paddingY={8} display="flex" borderTop="default">
      {results?.length > 0 && <Text><Strong>{results.length}</Strong> <Text color="muted">{i18n.results}</Text></Text>}
      <Text marginLeft={results?.length > 0 ? 8 : 0}><Strong>↑↓</Strong> <Text color="muted">{i18n.select}</Text></Text>
      <Text marginLeft={8}><Strong>↵</Strong> <Text color="muted">{i18n.open}</Text></Text>
    </Pane>}
  </Dialog>
}));

const WrappedSearchInput = styled(SearchInput)`
  box-shadow: none;
  font-size: 16px;

  &:focus {
    box-shadow: none;
  }
`

const ResultsWrapper = styled(Pane)`
  ::-webkit-scrollbar{
    width: 12px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb{
    border: 2px solid transparent;
    border-radius: 6px;
    box-shadow: 0 0 0 8px ${defaultTheme.colors.border.default} inset;
    width: 12px;
  }
`

export default inject('ui', 'notes')(SearchDialog);