import * as React from 'react';
import ProjectsStore from 'app/stores/ProjectsStore';
import { inject, observer } from 'mobx-react';
import { Button, defaultTheme, Label, Pane, RepeatIcon, ShareIcon, Strong, Switch, Text, TextInput, toaster } from 'evergreen-ui';
import Panel from 'app/components/Panel';
import { Link } from 'react-router-dom';
import translate from 'app/i18n';
import { MinimalButton, PrimaryButton } from 'app/components/Typography';

interface Props {
  isShown: boolean,
  onClick?: () => void,
  projects?: ProjectsStore
}

const RestrictAccessPanel = observer((props: Props) => {
  const {projects, isShown, onClick} = props;

  const [enabled, setEnabled] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const {restrictAccess, common} = translate();

  React.useEffect(() => {
    if(projects.current) {
      setEnabled(projects.current.restrictAccessEnabled);
      setPassword(projects.current.restrictAccessPassword || Math.random().toString(36).substring(5));
    }
  }, [projects.current]);

  const handleToggleChanged = () => {
    setEnabled(!enabled);
  }

  const handlePasswordChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  }

  const handleGenerate = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setPassword(Math.random().toString(36).substring(5));
  }

  const handleSubmitChanges = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);
    try{
      await projects.setRestrictAccess(enabled, password);
      toaster.success(common.updatedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setSaving(false);
    }
  };

  return <form onSubmit={handleSubmitChanges}>
    <Panel previewValue={<Switch disabled={projects.isReadonlyUser} onChange={handleToggleChanged} checked={enabled}/>}
                  header={<Strong>{restrictAccess.title}</Strong>} isShown={isShown} onClick={onClick} 
                  footer={<PrimaryButton disabled={projects.isReadonlyUser} type="submit" appearance="primary" isLoading={saving}>{common.btnSaveChanges}</PrimaryButton>}>
      
      {projects.current && <Pane display="flex" marginY={12}>
        <Pane display="flex" marginY={12} flexDirection="column">
          <Label marginBottom={5}>{restrictAccess.password}</Label>
          <Pane display="flex">
            <TextInput disabled={projects.isReadonlyUser} value={password} onChange={handlePasswordChange} placeholder="password" required minLength={5} maxLength={100}/>
            <MinimalButton disabled={projects.isReadonlyUser} marginLeft={2} iconBefore={RepeatIcon} onClick={handleGenerate}>{restrictAccess.generate}</MinimalButton>
          </Pane>
        </Pane>
      </Pane>}

      <Text>{restrictAccess.descripton}</Text>
    </Panel>
  </form>
});

export default inject('projects')(RestrictAccessPanel);