import translate from "app/i18n"
import Note from "app/models/Note"
import { defaultTheme, Heading, Pane, Paragraph, SideSheet } from "evergreen-ui"
import { inject, observer } from "mobx-react"
import * as React from "react"
import UiStore from "../../stores/UiStore"
import Form from "./Form"

interface Props {
  ui?: UiStore,
  note: Note
}

const NoteOptionsDialog = (observer((props: Props) => {
  const { note } = props;

  const  {noteOptions} = translate();

  return <React.Fragment>
    <SideSheet isShown={true} width={385} onCloseComplete={() => { props.ui?.hideDialog(); }}>
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16} borderBottom="muted" backgroundColor={defaultTheme.colors.background.blueTint}>
          <Heading size={600}>{noteOptions.title}</Heading>
          <Paragraph size={400} color="muted">{noteOptions.subtitle}</Paragraph>
        </Pane>
      </Pane>

      <Pane flex="1" backgroundColor="white" padding={16}>
        <Form note={note}/>
      </Pane>
    </SideSheet>
  </React.Fragment>
}))

export default inject('ui')(NoteOptionsDialog);