import { Button, defaultTheme, MediaIcon, Pane, Popover, Spinner, Tab, Tablist, toaster } from 'evergreen-ui';
import * as React from 'react'
import ImageFileInput from '../../../ImageFileInput';

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import NotesStore from '../../../../stores/NotesStore';
import Note from '../../../../models/Note';
import SquaredAvatar from '../../../SquaredAvatar';
import translate from 'app/i18n';
import ProjectsStore from 'app/stores/ProjectsStore';

interface Props {
  note: Note,
  notes?: NotesStore,
  projects?: ProjectsStore
}

const IconSelector: React.FC<Props> = ({note, notes, projects}) => {
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState<"emoji" | "upload">("emoji");

  const {common, iconSelector} = translate();

  const handleSelectEmoji = async (emoji) => {
    setLoading(true);

    try{
      await notes.setEmoji(note.id, emoji);
    }catch(e){
      toaster.danger(e.message);
    }finally{
      setLoading(false);
    }
  }

  const handleSelectFile = async (file: File) => {
    if(!file) return;

    const formData = new FormData();
    formData.append("file", file);
    
    setLoading(true);

    try{
      await notes.setLogoFile(note.id, formData);
    }catch(e){
      toaster.danger(e.message);
    }finally{
      setLoading(false);
    }
  }

  const handleRemove = async () => {
    setLoading(true);

    try{
      await notes.removeLogo(note.id);
    }catch(e){
      toaster.danger(e.message);
    }finally{
      setLoading(false);
    }
  }

  const renderIcon = () => {
    return <Pane width={note.logoType === "none" ? 100 : 60} marginBottom={10}>
      {note.logoType !== "none" &&
        <View>
          {loading && <Spinner />}
          {!loading && note.logoType === "emoji" && <Pane dangerouslySetInnerHTML={{__html: note.logoPresentable}}/>}
          {!loading && note.logoType === "file" && <SquaredAvatar size={42} src={note.logoPresentable} />}
        </View>
      }

      {note.logoType === "none" &&
        <Button
          type="button"
          style={{ color: defaultTheme.colors.text.default }}
          iconBefore={MediaIcon}
          marginTop={6}
          appearance="minimal">{iconSelector.addIcon}</Button>
      }
      </Pane>
  }

  if(projects.isReadonlyUser){
    return renderIcon();
  }

  return <Popover
    content={
      <Pane
        width={420}
        height={350}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Tablist marginTop={16} marginBottom={5} paddingLeft={10} width="100%">
          <Tab isSelected={tab === "emoji"} onSelect={() => setTab("emoji")}>{iconSelector.emoji}</Tab>
          <Tab isSelected={tab === "upload"} onSelect={() => setTab("upload")}>{iconSelector.uploadAnImage}</Tab>
          <Tab disabled={note.logoType === "none"} color="muted" float="right" onSelect={handleRemove}>{iconSelector.remove}</Tab>
        </Tablist>

        <Pane display="flex" flex="1" borderTop="default" width="100%" padding={10}>
          {tab === "upload" && <Pane is="form" flex="1">
            <ImageFileInput name="logo" onChange={handleSelectFile} hidePreview={true}/>
          </Pane>}

          {tab === "emoji" && <Picker onSelect={handleSelectEmoji} set='apple' style={{ width: "100%", border: 'none'}} />}
        </Pane>
      </Pane>
    }
  >
    {renderIcon()}
  </Popover>
}

const View = styled(Pane)`
  cursor: pointer;
  font-size: 38px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color: #eee;
    border-radius: 5px;
  }
`

export default inject('notes', 'projects')(observer(IconSelector));