import translate from 'app/i18n';
import SquaredAvatar from 'app/components/SquaredAvatar';
import Activity from 'app/models/Activity';
import { Text , Pane, Strong, defaultTheme, Avatar } from 'evergreen-ui';
import * as React from 'react'
import { Link } from 'react-router-dom';
import TimeAgo from "react-timeago";
import styled from 'styled-components';

interface Props {
  activity: Activity
}

const ActivityItem: React.FC<Props> = ({activity}) => {
  const {allUpdatesScreenListItem, timeAgo} = translate();

  const renderLink = () => {
    if(activity.actionableType !== 'Note'){
      return activity.actionableTitle
    }

    return <Link to={`/notes/${activity.actionableLink}`} style={{textDecoration: 'none', color: '#0366d6'}}>{activity.actionableTitle}</Link>
  }

  return <Wrapper>
    <Pane flexBasis="5%">
      <Avatar size={32} name={activity.userName} src={activity.userPhotoUrl} marginRight={8}/>
    </Pane>
    <Pane flexBasis="10%">
    <Strong marginRight={8}>{activity.userName}</Strong>
    </Pane>
    <Pane flexBasis="85%">
    <Text color="muted" marginRight={8}>{allUpdatesScreenListItem.actions[activity.action] || activity.action} {renderLink()} {allUpdatesScreenListItem.types[activity.actionableType.toLowerCase()]} <TimeAgo date={activity.createdAt} formatter={timeAgo}/></Text>
    </Pane>
  </Wrapper>
}

const Wrapper = styled('li')`
  display: flex;
  align-items: center;
  padding: 7px 7px;
  margin: 0px;
  &:hover{
    background-color: ${defaultTheme.colors.background.tint2}
  }
`
export default ActivityItem;