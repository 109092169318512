import Breadcrumbs from 'app/components/Breadcrumbs';
import { Heading } from 'app/components/Typography';
import ProjectsStore from 'app/stores/ProjectsStore';
import { Pane } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import FadeIn from '../../components/FadeIn';

interface Props {
  projects?: ProjectsStore,
  header: string,
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({header, children, projects}) => {
  React.useEffect(() => {
    document.title = "Wannadocs - Settings";
  }, []);

  return <Pane display="flex" flex="1" backgroundColor="white">
    <Pane display="flex" flex="1" flexDirection="column" paddingX={17} paddingY={24}>
      <Pane borderBottom="default" paddingBottom={16} marginX={15} display="flex" flexDirection="column">
        <Pane display="flex" alignItems="center" marginBottom={20} justifyContent="space-between">
          <Heading size={900}>{header}</Heading>
        </Pane>
      </Pane>

      <Pane padding={16} marginX={15} marginTop={4} display="flex" flex="1" backgroundColor="white" justifyContent="center">
        <Pane maxWidth={700} width="100%" display="flex">
          <FadeIn>{children}</FadeIn>
        </Pane>
      </Pane>
    </Pane>
  </Pane>
}

export default inject('projects')(observer(Layout));