import * as React from 'react'
import { inject, observer } from 'mobx-react';
import VariantsStore from '../../stores/VariantsStore';
import NotesTree from '../../components/NotesTree';
import { Text, Pane, CogIcon, SearchInput, PlusIcon, SelectMenuItem, toaster, Button, CaretDownIcon, Link, defaultTheme } from 'evergreen-ui';
import NotesTreeStore from 'app/stores/NotesTreeStore';
import ProjectsStore from 'app/stores/ProjectsStore';
import { RouteComponentProps, useHistory } from 'react-router';
import CreateButton from 'app/components/CreateButton';
import styled from 'styled-components';
import VariantDialog from 'app/dialogs/VariantDialog';
import UiStore from 'app/stores/UiStore';
import { computed } from 'mobx';
import Breadcrumbs from 'app/components/Breadcrumbs';
import NotesStore from 'app/stores/NotesStore';
import FilteredItem from 'app/components/NotesTree/components/FilteredItem';
import ActionSelectMenu from 'app/components/ActionSelectMenu';
import Note from 'app/models/Note';
import EmptyList from 'app/components/EmptyList';
import { useDebouncedCallback } from 'use-debounce/lib';
import Loader from 'app/components/Loader';
import translate from 'app/i18n';
import { Heading, Paragraph, PrimaryButton } from 'app/components/Typography';
import { ExternalLink } from 'react-feather';

interface MatchParams {
  variantId?: string
}

interface Props extends RouteComponentProps<MatchParams>{
  variants?: VariantsStore,
  notesTree?: NotesTreeStore,
  notes?: NotesStore,
  projects?: ProjectsStore,
  ui?: UiStore
}

const Home = observer((props: Props) => {
  React.useEffect(() => {
    document.title = "Wannadocs - Home";
  }, []);

  const { variants, notesTree, projects, ui, notes, match } = props;

  const [isLoading, setLoading] = React.useState(false);

  const [filter, setFilter] = React.useState("");
  const [filtered, setFiltered] = React.useState<Note[]>([]);

  const [activeVariant, setActiveVariant] = React.useState(match.params.variantId || localStorage.getItem("lastVariantId"));
  const history = useHistory();
  const {variantSelectMenu, common, homeScreen} = translate();

  const runFilter = useDebouncedCallback(
    async (value) => {
      try{
        const result = await notes.search(value, selectedVariant?.id);
        setFiltered(result);
        setLoading(false);
      }catch(e){
        toaster.danger(`Error while searching: ${e.message}`)
        console.error(e);
        setLoading(false);
      }
    },
    300
  );

  const selectedVariant = computed(() => {
    return variants.data.find(x => x.id === activeVariant);
  }).get()

  React.useEffect(() => {
    if(projects.current) load();
  }, [projects.current?.id, match.params.variantId]);

  React.useEffect(() => {
    setFilter("");
    setFiltered([]);
  }, [selectedVariant]);

  const load = async () => {
    try{
      if(!notesTree.root) setLoading(true);

      await variants.list();

      if(variants.data.length > 0) {
        const variantId = variants.data.find(x => x.id === match.params.variantId)?.id || 
                           variants.data.find(x => x.id === localStorage.getItem("lastVariantId"))?.id ||
                            variants.data[0].id;

        notesTree.loadTree(variantId);
        setActiveVariant(variantId);
        localStorage.setItem("lastVariantId", variantId);
      }else{
        setActiveVariant(null);
      }
    }catch(err){
      console.error(err);
    }finally{
      setLoading(false);
    }
  }
  
  const handleNewVariant = (e: React.SyntheticEvent) => {
    e.preventDefault();

    ui.showDialog(<VariantDialog />);
  }

  const handleEditVariant = (e: React.SyntheticEvent) => {
    e.preventDefault();

    ui.showDialog(<VariantDialog variant={selectedVariant} />);
  }

  const handleFilter = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value);

    if(e.currentTarget.value !== ""){
      setFiltered([]);
      setLoading(true);
      runFilter(e.currentTarget.value);
    }
  }

  const handleChangeVariant = (item: SelectMenuItem) => {
    history.push(`/home/${item.value}`);
  }

  return (<>
    <Pane display="flex" flex="1" backgroundColor="white">
      <Pane display="flex" flex="1" flexDirection="column" paddingX={17} paddingY={24}>
        <Pane borderBottom="default" paddingBottom={16} marginX={15} display="flex" flexDirection="column">

          <Pane display="flex" alignItems="baseline" marginBottom={20}>
            <Heading size={900}>{homeScreen.title}</Heading>
          </Pane>

          <Pane paddingBottom={16}>
            <Paragraph size={400}>{homeScreen.subtitle}</Paragraph>
          </Pane>
          <Pane display="flex" alignItems="center" justifyContent="space-between">
            <ActionSelectMenu onSelect={handleChangeVariant} title={variantSelectMenu.title} buttonTitle={selectedVariant?.titleWithIcon || common.loading} selected={selectedVariant?.id} options={variants.data.map((x) => ({label: x.titleWithIcon, value: x.id}))}>
              {!projects.isReadonlyUser && <CogIcon onClick={handleEditVariant}/>}
              {!projects.isReadonlyUser && <PlusIcon onClick={handleNewVariant}/>}
            </ActionSelectMenu>      

            <CreateButton variant={selectedVariant}/>
          </Pane>
        </Pane>

        <Pane marginX={15}>
          {filter === "" && <NotesTree isLoading={isLoading} root={notesTree.root}/>}
          {filter !== "" && <Pane display="flex" flexDirection="column" flex="1" marginTop={4}>
            {isLoading ? <Loader /> : (filtered.length > 0 ? filtered.map(x => <FilteredItem key={x.id} note={x}/>) : <EmptyList text={common.noResults}/>)}
          </Pane>}
        </Pane>
      </Pane>   
    </Pane>
    </>
  )
});

export default inject('projects', 'variants', 'notesTree', 'ui', 'notes')(Home);
