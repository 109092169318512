import translate from 'app/i18n';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Form } from '../../components/Settings/components/Invitations';
import Layout from './Layout';


const Invitations = observer(() => {
  const {invitationsScreen} = translate();

  return <Layout header={invitationsScreen.title}>
    <Form />
  </Layout>
});

export default Invitations;