import * as React from 'react'
import { inject, observer } from 'mobx-react';
import {Tooltip, Text, Badge, defaultTheme, Heading, Button, TrashIcon, InheritanceIcon, EditIcon, Pane, Dialog, WarningSignIcon } from 'evergreen-ui';
import styled from 'styled-components';
import DroppableLine from './DroppableLine';
import {useHistory} from "react-router-dom";
import Note from '../../../models/Note';
import Draggable from './Draggable';
import Caret from './Caret';
import NotesTreeStore from '../../../stores/NotesTreeStore';
import Menu, {Wrapper as MenuWrapper} from './Menu';
import { computed } from 'mobx';
import SquaredAvatar from '../../SquaredAvatar';
import translate from 'app/i18n';
import NotesStore from 'app/stores/NotesStore';
import ProjectsStore from 'app/stores/ProjectsStore';

interface Props {
  url?: string,
  notesTree?: NotesTreeStore,
  notes?: NotesStore,
  projects?: ProjectsStore,
  level?: number,
  note?: Note
}

const Item = (observer((props: Props) => {
  const {note, level, notes, projects, notesTree} = props;
  const [showDeletingDialog, setShowDeletingDialog] = React.useState(false);
  // const titleColor = note?.type === "link" ? defaultTheme.colors.text.selected : defaultTheme.colors.text.default;
  const i18n = translate();

  const handleExpandToggle = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    notesTree.toggleExpanded(note.id);
  }

  const handleOpenDeleteDialog = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setShowDeletingDialog(true);
  }

  const handleDelete = (close: () => void) => {
    notesTree.remove(note.id);
    notes.delete(note.id);
    close();
    setShowDeletingDialog(false);
  }

  const isExpanded = computed(() => {
    if(!note) return false;
    return notesTree.expanded.has(note.id);
  }).get()

  return (
    <Draggable note={note} disabled={projects.isReadonlyUser}>
      <Wrapper style={{paddingLeft: 14 * (level || 1)}} onClick={handleExpandToggle}>
        <Caret expanded={isExpanded} onClick={handleExpandToggle}/>

        {note.logoType !== "none" &&
        <Pane display="flex" width={20} height={20} justifyContent="center" alignItems="center" marginRight={6}>
          {note.logoType === "file" && <SquaredAvatar size={16} src={note.logoPresentable} />}
          {note.logoType === "emoji" && <Pane dangerouslySetInnerHTML={{__html: note.logoPresentable}}/>}
        </Pane>}

        {note?.isDraft && <Badge color="yellow" marginRight={4}>{i18n.notesTree.private}</Badge>}
        {note?.isTableOfContents && <Badge color="purple" marginRight={4}>{i18n.notesTree.toc}</Badge>}
        <Text>{note?.title === '' ? 'Untitled' : note?.title}</Text>
        <Menu note={note} handleDelete={handleOpenDeleteDialog}/>
      </Wrapper>

      {isExpanded && note.children.length === 0 ?
        <Text paddingLeft={14 * ((level || 1) + 1)} color="muted" size={300}>{i18n.notesTree.noPagesInside} <DroppableLine note={note} mode={"after"} /> </Text>
        :
        <DroppableLine note={note} mode={isExpanded ? "children" : "after"} />
      }

      {isExpanded && <div>
        {note.children.map(x => <Item projects={projects} notesTree={notesTree} notes={notes} key={x.id} note={x} level={level + 1} />)}
      </div>}

      <Dialog
        isShown={showDeletingDialog}
        title={i18n.deleteArticleDialog.title}
        intent="danger"
        onCloseComplete={() => setShowDeletingDialog(false)}
        onConfirm={handleDelete}
        confirmLabel={i18n.common.btnDelete}
        cancelLabel={i18n.common.btnCancel}
      >
        <Text display="flex" flex="1" alignItems="center" justifyContent="center" paddingY={8}>
          <WarningSignIcon marginRight={4}/> {i18n.deleteArticleDialog.subtitle}
        </Text>
      </Dialog>
    </Draggable>
  )
}));

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  padding: 2px 14px;
  background: transparent;
  transition: background 20ms ease-in 0s;

  &:hover {
    background-color: ${defaultTheme.colors.background.tint2};
  }

  &:hover ${MenuWrapper}{
    visibility: visible;
  }
`

export default inject('notesTree', 'notes', 'projects')(Item);