import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from "react-router";
import NotesStore from '../../stores/NotesStore';
import Form from '../../components/Note/components/Form';
import ProjectsStore from '../../stores/ProjectsStore';
import UiStore from '../../stores/UiStore';
import { Pane, Text, Avatar, Tooltip, Button, defaultTheme, MoreIcon, StarIcon, IconButton, StarEmptyIcon } from 'evergreen-ui';
import Loader from '../../components/Loader';
import FadeIn from '../../components/FadeIn';
import NoteMenu from 'app/components/Note/components/Form/NoteMenu';
import VariantsStore from 'app/stores/VariantsStore';
import { computed } from 'mobx';
import Breadcrumbs from 'app/components/Breadcrumbs';
import translate from 'app/i18n';

interface MatchParams {
  id?: string
}

interface Props extends RouteComponentProps<MatchParams> {
  ui?: UiStore,
  notes?: NotesStore,
  projects?: ProjectsStore,
  variants?: VariantsStore,
}

const Note = observer((props: Props) => {
  const { ui, match, notes, projects, variants } = props;

  const {common} = translate();

  React.useEffect(() => {
    ui.setCurrentNote(null);
    load(match.params.id);
  }, [match.params.id])

  const load = async (id: string) => {
    const n = await notes.info({ id: id });

    projects.setCurrent(n.projectId);
   
    document.title = `Wannadocs - ${n.title}`;

    ui.setCurrentNote(n);
  }

  const breadcrumbs = computed(() => {
    const base = [
      {
        label: ui.currentNote?.variantTitle,
        href: `/home/${ui.currentNote?.variantId}`
      },
    ]

    const items = base.concat(ui.currentNote?.breadcrumbs?.map(x => { 
      return {
        label: x.title,
        href: `/notes/${x.id}`
      } 
    })).filter(x => x);

    return items;
  }).get();

  const participants = computed(() => {
    if(!ui.currentNote) return [];

    return ui.currentNote.participants.map(x => projects.current?.members.find(m => m.user.id === x)).filter(x => x)
  }).get();

  const handleMakeFavorite = () => {
    if(ui.currentNote?.deleted) return;

    ui.currentNote.setFavorite(!ui.currentNote.isFavorite);
    notes.favorite(ui.currentNote.id);
  }

  return  <Pane display="flex" flex="1" backgroundColor="white">
    <Pane display="flex" flex="1" flexDirection="column" paddingX={20} paddingBottom={24}>
      <Pane display="flex" flexDirection="column">
        <Pane backgroundColor="white" zIndex={9} top={0} position="sticky" paddingBottom={8} paddingTop={12} marginBottom={16} display="flex" alignItems="center" justifyContent="space-between">
          <Breadcrumbs items={breadcrumbs}/>

          <Pane display="flex" justifyContent="center" alignItems="center">
            <Text textAlign="center" color={notes?.isUpdating ? "muted" : "#1bb157"}>{notes?.isUpdating ? common.saving : common.saved}</Text>
            <Pane marginX={8} color="#d1d5db">|</Pane>
            {participants.map(x => <Tooltip key={x.user.id} content={`${x.user.name} has contributed to this document.`}>
              <Avatar marginX={3} src={x.user.photoUrl} name={x.user.name}/></Tooltip>)}
            <Pane marginX={8} color="#d1d5db">|</Pane>
            <IconButton onClick={handleMakeFavorite} disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} 
                        style={{ color: defaultTheme.colors.text.default }} appearance="minimal"
                        icon={ui.currentNote?.isFavorite ? StarIcon : StarEmptyIcon}/>
            <Pane marginX={8} color="#d1d5db">|</Pane>
            <NoteMenu />
          </Pane>
        </Pane>

        <Pane>
          {ui.currentNote ?

            <FadeIn><Form /></FadeIn>
            :
            <Loader />
          }
        </Pane>
      </Pane>
    </Pane>
  </Pane>
});

export default inject('ui', 'notes', 'projects', 'variants')(Note);
