import translate from 'app/i18n';
import Note from 'app/models/Note';
import NotesStore from 'app/stores/NotesStore';
import ProjectsStore from 'app/stores/ProjectsStore';
import { Text, Pane, defaultTheme, Heading, toaster, UndoIcon, Button } from 'evergreen-ui';
import { inject } from 'mobx-react';
import * as React from 'react'
import { Link } from 'react-router-dom';
import TimeAgo from "react-timeago";
import styled from 'styled-components';

interface Props {
  note: Note,
  notes?: NotesStore,
  projects?: ProjectsStore
}

const ListItem: React.FC<Props> = ({note, notes, projects}) => {
  const {trashScreen, common, timeAgo} = translate();

  const handleRestore = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try{
      await notes.restore(note.id);
    } catch (e) {
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }
  }

  return <Wrapper>
    <StyledLink to={`/notes/${note.id}`} style={{textDecoration: 'none', color: defaultTheme.colors.text.default}}>
      <Pane display="flex" flexDirection="column">
        <Pane display="flex" alignItems="center">
          <Heading size={500} marginRight={8}>{note.title}</Heading>
        </Pane>
        <Text color="muted" marginRight={8}>{note.discardedBy && note.discardedBy.name} {trashScreen.deleted} <TimeAgo date={note.discardedAt} formatter={timeAgo}/></Text>
      </Pane>

      {!projects.isReadonlyUser && <ButtonsWrapper>
        <Button iconBefore={UndoIcon} style={{color: defaultTheme.colors.text.default}} appearance="minimal" onClick={handleRestore}>{trashScreen.restore}</Button>
      </ButtonsWrapper>}
    </StyledLink>
  </Wrapper>
}

const ButtonsWrapper = styled(Pane)`
  visibility: hidden
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 7px 7px;
  margin: 0px
`

const Wrapper = styled('li')`
  cursor: pointer;

  &:hover {
    background-color: ${defaultTheme.colors.background.tint2};
  }

  &:hover ${ButtonsWrapper}{
    visibility: visible;
  }
`

export default inject('notes', 'projects')(ListItem);