import translate from "app/i18n";
import { Button, IconButton, Pane, SelectMenu, SelectMenuItem, Text, toaster, TrashIcon } from "evergreen-ui"
import { inject, observer } from "mobx-react";
import * as React from "react"
import styled from "styled-components"
import Member from "../../../../models/Member";
import AuthStore from "../../../../stores/AuthStore";
import ProjectsStore from "../../../../stores/ProjectsStore";
import SquaredAvatar from "../../../SquaredAvatar";

interface Props {
  auth?: AuthStore,
  projects?: ProjectsStore,
  member?: Member
}

const ROLES = [{
  label: translate().roles.owner,
  value: 'owner'
}, {
  label: translate().roles.readOnly,
  value: 'read_only'
},{
  label: translate().roles.admin,
  value: 'admin'
},{
  label: translate().roles.editor,
  value: 'editor'
}];

const Item = observer((props: Props) => {
  const {auth, member, projects} = props;
  const {membersScreen, common, roles} = translate();

  const handleChangeRole = async(e: SelectMenuItem) => {
    try{
      await projects.changeRole(member.user.id, e.value as string);
      toaster.success(common.updatedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    } 
  }

  const handleDeleteMember = async(e: React.SyntheticEvent) => {
    e.preventDefault();

    try{
      await projects.deleteMember(member.user.id);
      toaster.success(common.deletedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    } 
  }

  return <Wrapper>
    <SquaredAvatar src={member.user.photoUrl} size={30}/>
    <Pane display="flex" flexDirection="column" marginLeft={10}>
      <Text>{member.user.name}</Text>
      <Text>{member.user.email}</Text>
    </Pane>

    <Pane display="flex" justifyContent="flex-end" flex="1" alignItems="center">
      {auth.role === "admin" || auth.role === "owner" && auth.user.id !== member.user.id ?
        <Pane display="flex">
          <SelectMenu shouldCloseOnExternalClick={true}
            closeOnSelect={true}
            hasTitle={false}
            hasFilter={false}
            title={membersScreen.selectRole}
            options={ROLES}
            onSelect={handleChangeRole}
            selected={roles[member.role]}
          >
            <Button>{ROLES.find(x => x.value === member.role).label}</Button>
          </SelectMenu>
          <IconButton icon={TrashIcon} intent="danger" marginLeft={10} onClick={handleDeleteMember}/>
        </Pane>
        :
        <Text color="muted" textTransform="capitalize">{ROLES.find(x => x.value === member.role).label}</Text>
      }
    </Pane>
  </Wrapper>
});

const Wrapper = styled('div')`
  padding: 15px;
  border-radius:3px;
  display: flex;
  flex: 1;
  align-items: center;
  transition: 0.1s;

  &:hover {
    background: rgb(55, 53, 47, 0.08);
  }
`

export default inject('auth', 'projects')(Item);