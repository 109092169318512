import * as React from 'react';
import ProjectsStore from 'app/stores/ProjectsStore';
import { inject, observer } from 'mobx-react';
import { Button, defaultTheme, FloppyDiskIcon, GlobeIcon, Label, LockIcon, Pane, Radio, RadioGroup, SavedIcon, ShareIcon, Strong, Switch, Text, TextInput, toaster } from 'evergreen-ui';
import Panel from 'app/components/Panel';
import { Link } from 'react-router-dom';
import translate from 'app/i18n';
import { PrimaryButton } from 'app/components/Typography';

interface Props {
  isShown: boolean,
  onClick?: () => void,
  projects?: ProjectsStore
}

const VisibilityPanel = observer((props: Props) => {
  const {projects, isShown, onClick} = props;
  const {visibility, common} = translate();

  const [options] = React.useState([
    { label: common.public, value: 'public' },
    { label: common.private, value: 'private' },
  ]);

  const [value, setValue] = React.useState('public');
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if(projects.current) setValue(projects.current.visibilityStatus);
  }, [projects.current]);


  const handleSaveChanges = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSaving(true);
    try{
      await projects.setVisibility(value);
      toaster.success(common.updatedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setSaving(false);
    }
  };

  return <Panel hidePreviewValueOnShow={true}
                previewValue={<Text textTransform="capitalize" color="muted">{projects.current?.visibilityStatus === "public" ? common.public : common.private}</Text>}
                header={<Strong>{visibility.title}</Strong>}
                isShown={isShown} onClick={onClick}
                footer={<PrimaryButton disabled={projects.isReadonlyUser} isLoading={saving} appearance="primary" onClick={handleSaveChanges}>{common.btnSaveChanges}</PrimaryButton>}>
    {projects.current && <Pane display="flex" marginY={12} flexDirection="column">
      <RadioGroup
        size={16}
        value={value}
        options={options}
        onChange={event => setValue(event.target.value)}
      />
    </Pane>}

    {value === "public" && <Text display="flex" alignItems="center"> <GlobeIcon marginRight={6}/> {visibility.textForPublic}</Text>}
    {value === "private" && <Text display="flex" alignItems="center"> <LockIcon marginRight={6}/> {visibility.textForPrivate}</Text>}
  </Panel>
});

export default inject('projects')(VisibilityPanel);