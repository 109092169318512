import translate from "app/i18n";
import { action, computed, IObservableArray, makeObservable, observable } from "mobx";
import { serializable, object } from "serializr";
import BaseModel from "./BaseModel";

export default class Variant extends BaseModel {
  @serializable
  title: string = null;
  @serializable
  lang: string = null;
  @serializable
  icon: string = null;
  @serializable
  slug: string = null;
  @serializable
  isMain: boolean = false;

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      title: observable,
      lang: observable,
      icon: observable,
      isMain: observable,
      setMain: action,
      titleWithIcon: computed
    });
  }

  get titleWithIcon(){
    const {variantDialog} = translate();
   return `${this.icon} ${this.title} ${this.isMain ? `[${variantDialog.main}]` : ''}`
  }

  setMain(value: boolean) {
    this.isMain = value;
  }
}