import { Button, TextInput, Text, Alert, Pane, Heading, defaultTheme, SmallTickIcon, TickIcon, DisableIcon, toaster } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { Redirect, useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import Centered from './Centered';
import AuthStore from '../stores/AuthStore';
import Invite from '../models/Invite';
import Loader from '../components/Loader';
import InvitesStore from '../stores/InvitesStore';
import YellowBox from '../components/YellowBox';

interface MatchParams {
  id?: string
}

interface Props extends RouteComponentProps<MatchParams> {
  auth?: AuthStore,
  invites?: InvitesStore
}

const Join = observer((props: Props) => {
  const { auth, match, invites } = props;
  const [invite, setInvite] = React.useState<Invite>(null);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const fetchPage = async (id?: string) => {
    if (!id) return;

    try {
      const item = await invites.info({ id: id });
      setInvite(item);
    } catch (e) {
      console.error(e);
      toaster.danger("Invite not found", { duration: 30});
      history.push('/home');
    }
  }
  
  const handleAccept = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await invites.accept(invite.id);
      setLoading(false);
      location.href = "/home"; // reload page
    } catch (e) {
      console.error(e);
      toaster.danger(`Error while accepting invite: ${e.message}`);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    (async () => fetchPage(match.params.id))();
  }, [match.params.id]);

  if (!auth.authenticated) {
    return <Redirect to="/" />
  } else {
    return <Pane display="flex" flex="1" justifyContent="center" padding={100}>
      {invite ?
        <YellowBox maxWidth={420}>
          <Heading size={900} paddingBottom={45}>Join the Wannadocs project {invite.projectName}</Heading>
          <Pane is="form" display="flex" flexDirection="column">
            <Button
              appearance="primary"
              height={40}
              iconBefore={TickIcon}
              type="submit"
              isLoading={loading}
              onClick={handleAccept}
              marginBottom="1em">Accept invite</Button>
            <Button
              is="a"
              style={{ color: defaultTheme.colors.text.default }}
              appearance="minimal"
              href="/home"
              height={40}
              iconBefore={DisableIcon}
              type="submit"
              marginBottom="1em">Ignore</Button>
          </Pane>
        </YellowBox>
        :
        <Loader />
      }
    </Pane>
  }
});

export default inject('auth', 'invites')(Join);
