import BaseModel from "./BaseModel";
import {
  serializable
} from "serializr"
import { action, makeObservable, observable } from "mobx";

export default class Integration extends BaseModel {
  @serializable
  type: string = null;
  @serializable
  enabled: boolean = false;
  // Integrations::CustomJs/Css
  @serializable
  code: string = null;

  // Integrations::Chatra
  @serializable
  chatraId: string = null;

  // Integrations::Disqus
  @serializable
  shortname: string = null;

  // Integrations::IntercomMessenger / Integrations::IntercomChatWidget
  @serializable
  appId: string = null;
  @serializable
  clientId: string = null;
  @serializable
  connected: boolean = null;

  constructor(){
    super();

    makeObservable(this, {
      id: observable,
      type: observable,
      enabled: observable,
      code: observable,
      setCode: action,
      setEnabled: action,
      chatraId: observable,
      setChatraId: action,
      shortname: observable,
      setShortname: action,
      toPayload: action,
      appId: observable,
      clientId: observable,
      connected: observable
    })
  }
  
  setCode(code: string){
    this.code = code;
  }

  setChatraId(id: string){
    this.chatraId = id;
  }
  
  setShortname(shortname: string){
    this.shortname = shortname;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }

  setAppId(appId: string){
    this.appId = appId;
  }

  toPayload() {
    switch(this.type){
      case "Integrations::CustomJs":
        return {
          type: this.type,
          enabled: this.enabled,
          content: JSON.stringify({
            code: this.code
          })
        }
      case "Integrations::CustomCss":
        return {
          type: this.type,
          enabled: this.enabled,
          content: JSON.stringify({
            code: this.code
          })
        }
      case "Integrations::Chatra":
        return {
          type: this.type,
          enabled: this.enabled,
          content: JSON.stringify({
            chatraId: this.chatraId
          })
        }
      case "Integrations::Disqus":
        return {
          type: this.type,
          enabled: this.enabled,
          content: JSON.stringify({
            shortname: this.shortname
          })
        }
      case "Integrations::IntercomMessenger":
        return {
          type: this.type,
          enabled: this.enabled
        }
      case "Integrations::IntercomChatWidget":
        return {
          type: this.type,
          enabled: this.enabled,
          content: JSON.stringify({
            app_id: this.appId
          })
        }
      default:
        console.error(`Unknown type ${this.type}`)
    }
  }
}