import translate from 'app/i18n';
import { observer } from 'mobx-react';
import * as React from 'react';
import BackupForm from '../../components/Settings/components/Backup';
import Layout from './Layout';

const Backup = observer(() => {
  const {backupScreen} = translate();

  return <Layout header={backupScreen.title}>
    <BackupForm />
  </Layout>
});

export default Backup;