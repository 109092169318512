import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { Text, Button, CaretDownIcon, defaultTheme, Pane, SelectMenu, SelectMenuItem, TimeIcon } from 'evergreen-ui';
import AnalyticsStore from '../../stores/AnalyticsStore';
import PopularViews from 'app/components/Analytics/PopularViews';
import FadeIn from 'app/components/FadeIn';
import Feedbacks from 'app/components/Analytics/Feedbacks';
import Breadcrumbs from 'app/components/Breadcrumbs';
import ProjectsStore from 'app/stores/ProjectsStore';
import translate from 'app/i18n';
import Paywall from 'app/components/Paywall';
import { Heading, Paragraph } from 'app/components/Typography';

interface Props {
  analytics?: AnalyticsStore,
  projects?: ProjectsStore
}

const INTERVALS = [
  {
    label: "Last day",
    value: "day"
  },
  {
    label: "Last week",
    value: "week"
  },
  {
    label: "Last month",
    value: "month"
  },
  {
    label: "Six months",
    value: "six_months"
  },
  {
    label: "Year",
    value: "year"
  }
]

const DEFAULT_INTERVAL = INTERVALS.find(x => x.value === "week")

const Analytics = observer((props: Props) => {
  const { analytics, projects } = props;
  const [selected, setSelected] = React.useState<SelectMenuItem>(DEFAULT_INTERVAL);
  const {analyticsScreen} =  translate();

  React.useEffect(() => {
    document.title = "Wannadocs - Analytics";
  }, []);

  useEffect(() => {
    analytics.loadPopularViews("week");
    analytics.loadLikeFeedbacks("week");
    analytics.loadNeutralFeedbacks("week");
    analytics.loadDislikeFeedbacks("week");
  }, [])

  const handleSelectPopularViewsInterval = (item: SelectMenuItem) => {
    analytics.loadPopularViews(item.value as string);
  }

  const handleSelectFeedbacksInterval = (item: SelectMenuItem) => {
    analytics.loadLikeFeedbacks(item.value as string);
    analytics.loadNeutralFeedbacks(item.value as string);
    analytics.loadDislikeFeedbacks(item.value as string);
  }

  const handleSelectInterval = (item: SelectMenuItem) => {
    setSelected(item);
    handleSelectPopularViewsInterval(item);
    handleSelectFeedbacksInterval(item);
  }

  if(projects.current?.availableQuota?.hasAnalytics === false){
    return <Paywall />
  }

  return (<>
    <Pane display="flex" flex="1" backgroundColor="white">
      <Pane display="flex" flex="1" flexDirection="column" paddingX={17} paddingY={24}>
        <Pane marginX={15} paddingBottom={16} display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center" marginBottom={20} justifyContent="space-between">
            <Heading size={900}>{analyticsScreen.title}</Heading>
          </Pane>

          <Pane paddingBottom={16}>
            <Paragraph size={400}>{analyticsScreen.subtitle}</Paragraph>
          </Pane>

          <Pane display="flex" alignItems="center">
            <Pane display="flex" border="default" borderRadius={5}>
              <SelectMenu
                hasFilter={false}
                closeOnSelect={true}
                isMultiSelect={false}
                title={analyticsScreen.selectInterval}
                options={INTERVALS.map(x => {
                  return {label: analyticsScreen.intervals[x.value], value: x.value}
                })}
                selected={selected.value as string}
                onSelect={handleSelectInterval}
              >
                <Button height={30}
                  style={{ color: defaultTheme.colors.text.default }}
                  appearance="minimal"
                  iconBefore={TimeIcon}
                  iconAfter={CaretDownIcon}>{analyticsScreen.intervals[selected.value]}</Button>
              </SelectMenu>
            </Pane>
          </Pane>
        </Pane>

        <Pane marginX={15}>
          <FadeIn style={{display: 'flex'}}>
            <PopularViews items={analytics.popularViews} onSelectInterval={handleSelectPopularViewsInterval}/>
          </FadeIn>

          <FadeIn style={{display: 'flex', marginTop: '30px'}}>
            <Feedbacks dislikes={analytics.dislikeFeedbacks}
                        neutrals={analytics.neutralFeedbacks}
                        likes={analytics.likeFeedbacks}
                        onSelectInterval={handleSelectFeedbacksInterval}
                        />
          </FadeIn>
        </Pane>
      </Pane>
    </Pane>
    </>)
});

export default inject('analytics', 'projects')(Analytics);
