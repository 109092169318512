import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Badge, Button, defaultTheme, Label, Pane, RefreshIcon, ShareIcon, Strong, Switch, Text, TextInput, toaster } from 'evergreen-ui';
import Panel from 'app/components/Panel';
import IntegrationsStore from 'app/stores/IntegrationsStore';
import Integration from 'app/models/Integration';
import styled from 'styled-components';
import translate from 'app/i18n';
import ProjectsStore from 'app/stores/ProjectsStore';
import { PrimaryButton } from 'app/components/Typography';
const IntercomImage = require('../../../../images/intercom.png');

interface Props {
  isShown: boolean,
  onClick?: () => void,
  integrations?: IntegrationsStore,
  projects?: ProjectsStore,
  integration: Integration
}

const IntercomMessengerPanel = observer((props: Props) => {
  const {integrations, projects, integration, isShown, onClick} = props;

  const [saving, setSaving] = React.useState(false);
  const {common, intercomMessenger} = translate();

  const handleToggleChanged = () => {
    integration?.setEnabled(!integration.enabled);
  }

  const handleSubmitChanges = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);
    try{
      await integrations.update(integration.toPayload());
      toaster.success(common.savedMessage, { duration: 2 });
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setSaving(false);
    }
  };

  return <form onSubmit={handleSubmitChanges}>
    <Panel previewValue={integration?.connected && <Switch disabled={projects.isReadonlyUser} onChange={handleToggleChanged} checked={integration.enabled}/>}
                  headerLogoUrl={IntercomImage}
                  header={<Strong>{intercomMessenger.title}</Strong>} isShown={isShown} onClick={onClick} 
                  footer={integration?.connected && <PrimaryButton disabled={projects.isReadonlyUser} type="submit" appearance="primary" isLoading={saving}>{common.btnSaveChanges}</PrimaryButton>}>

       <Pane display="flex" marginY={12}>
       {integration?.connected ?
          <Pane display="flex" flexDirection="column">
            <Label marginY={5}>{intercomMessenger.status}: <Badge color="green">{intercomMessenger.connected}</Badge></Label>
            <Label marginY={5}>ID: {integration?.appId || ''}</Label>
            <PrimaryButton iconBefore={RefreshIcon} marginTop={6} is="a" href={`https://app.intercom.com/oauth?client_id=${integration?.clientId}&state=initial`} appearance="primary" isLoading={saving}>
              {intercomMessenger.reinstall}
            </PrimaryButton>
          </Pane>
          :
          <PrimaryButton disabled={projects.isReadonlyUser} is="a" href={`https://app.intercom.com/oauth?client_id=${integration?.clientId}&state=initial`} appearance="primary" isLoading={saving}>
            {intercomMessenger.install}
          </PrimaryButton>
        }
      </Pane>
    </Panel>
  </form>
});

export default inject('integrations', 'projects')(IntercomMessengerPanel);