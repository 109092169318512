import Figma from './Figma';
import YouTube from './YouTube';
import Airtable from './Airtable';
import Codepen from './Codepen';
import Gist from './Gist';
import Spotify from './Spotify';
import Trello from './Trello';
import Typeform from './Typeform';
import Vimeo from './Vimeo';
import VkVideo from './VkVideo';

const EMBEDS = [
  {
    title: "YouTube",
    keywords: "youtube video",
    icon: YouTube.Icon,
    matcher: url => {
      return url.match(YouTube.Regex);
    },
    component: YouTube.View
  },{
    title: "VK Video",
    keywords: "vkontakte vk video",
    icon: VkVideo.Icon,
    matcher: url => {
      return url.match(VkVideo.Regex);
    },
    component: VkVideo.View
  },{
    title: "Figma",
    keywords: "figma svg vector",
    icon: Figma.Icon,
    matcher: url => {
      return url.match(Figma.Regex);
    },
    component: Figma.View
  },{
    title: "Airtable",
    keywords: "spreadsheet",
    icon: Airtable.Icon,
    matcher: url => {
      return url.match(Airtable.Regex);
    },
    component: Airtable.View
  },{
    title: "Codepen",
    keywords: "code editor",
    icon: Codepen.Icon,
    matcher: url => {
      return url.match(Codepen.Regex);
    },
    component: Codepen.View
  },{
    title: "Github Gist",
    keywords: "code",
    icon: Gist.Icon,
    matcher: url => {
      return url.match(Gist.Regex);
    },
    component: Gist.View
  },{
    title: "Spotify",
    keywords: "music",
    icon: Spotify.Icon,
    matcher: url => {
      return url.match(Spotify.Regex);
    },
    component: Spotify.View
  },{
    title: "Trello",
    keywords: "kanban",
    icon: Trello.Icon,
    matcher: url => {
      return url.match(Trello.Regex);
    },
    component: Trello.View
  },{
    title: "Typeform",
    keywords: "form survey",
    icon: Typeform.Icon,
    matcher: url => {
      return url.match(Typeform.Regex);
    },
    component: Typeform.View
  },{
    title: "Vimeo",
    keywords: "video",
    icon: Vimeo.Icon,
    matcher: url => {
      return url.match(Vimeo.Regex);
    },
    component: Vimeo.View
  }
]

export default EMBEDS;
