import PricingTable from "app/components/PricingTable";
import PurchasesList from "app/components/PurchasesList";
import QuotaTable from "app/components/QuotaTable";
import { Tab, Tablist } from "app/components/Tablist";
import translate from "app/i18n";
import PurchasesStore from "app/stores/PurchasesStore";
import { Pane, toaster } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  tab?: string,
  purchases?: PurchasesStore
}

const Billing = observer((props: Props) => {
  const history = useHistory();
  const {purchases, tab} = props;
  const i18n = translate();

  React.useEffect(() => {
    purchases.list();
  }, []);

  const handlePurchase = () => {
    toaster.success(i18n.purchases.complete);
    history.push('/settings/billing/purchases');
  }

  return <Pane display="flex" flex="1" background="white" paddingTop={16} flexDirection="column" padding={10}>
    <Tablist marginTop={16} marginBottom={12}>
      <Tab isSelected={tab === undefined || tab === "quota"} marginLeft={0} onSelect={() => history.push('/settings/billing/quota')}>{i18n.billingQuota.title}</Tab>
      <Tab isSelected={tab === "purchases"} onSelect={() => history.push('/settings/billing/purchases')}>{i18n.purchases.title}</Tab>
      <Tab isSelected={tab === "pricing"} onSelect={() => history.push('/settings/billing/pricing')}>{i18n.pricingPlans.title}</Tab>
    </Tablist>

    <Pane display="flex" flex="0" flexDirection="column">
      {(tab === undefined || tab === "quota") && <QuotaTable />}
      {tab === "purchases" && <PurchasesList />}
      {tab === "pricing" && <PricingTable onCompletePurchase={handlePurchase}/>}
    </Pane>
  </Pane>
});

export default inject('purchases')(Billing);