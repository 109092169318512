import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { useDrag, useDrop } from 'react-dnd'
import DragObject from './DragObject';
import Note from '../../../models/Note';
import NotesStore from '../../../stores/NotesStore';
import VariantsStore from '../../../stores/VariantsStore';
import NotesTreeStore from '../../../stores/NotesTreeStore';

interface Props {
  notesTree?: NotesTreeStore,
  note?: Note,
  notes?: NotesStore,
  children: React.ReactNode,
  disabled?: boolean,
}

const Draggable = inject('notes', 'notesTree')(observer((props: Props) => {
  const {notesTree, note, notes, disabled, children} = props;

  const [{ opacity }, dragRef] = useDrag<DragObject, any, any>({
    item: { type: 'Note', id: note.id },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    })
  })

  const [{isOver}, dropRef] = useDrop<DragObject, any, any>({accept: 'Note',
    drop: (_, monitor) => {
      const {id: draggedId} = monitor.getItem();
      if (!monitor.isOver({shallow: true})) return
      const draggedNode = notesTree.find(draggedId);

      if(notesTree.findAncestors(note).find(x => x.id === draggedNode.id) || note.id === draggedId){
        return;
      }

      notesTree.setExpanded(note.id);
     // note.expand();
      notesTree.add(draggedNode, note);
      notes.reorder(notesTree.root, draggedNode);
    },
    collect: (monitor) => {
      if (!monitor.isOver({shallow: true})) return {isOver: false}

      return {
        isOver: monitor.isOver() ? true : false
      }
    }
  })

  const attachRef = (el) => {
    dragRef(el);
    dropRef(el);
  }

  if (!note || disabled){
    return <div>{children}</div>
  }

  return <div ref={attachRef} style={{ opacity, backgroundColor: isOver ? '#00adff1f' : null }}>{children}</div>
}))

export default Draggable;