import { Link } from "evergreen-ui";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  href: string
  children: React.ReactChild
  // Other props
  [x:string]: any;
}

const RoutedLink: React.FC<Props> = (props) => {
  const history = useHistory();
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    history.push(props.href);
  }

  return <Link {...props} onClick={handleClick}>{props.children}</Link>
}

export default RoutedLink;