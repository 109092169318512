import { action, computed, IObservableArray, makeObservable, observable } from "mobx";

import {
  list,
  object,
  primitive,
  serializable
} from "serializr"
import BacklinkItem from "./BacklinkItem";
import BaseModel from "./BaseModel";
import BreadcrumbsItem from "./BreadcrumbsItem";
import User from "./User";

export default class Note extends BaseModel{
  @serializable
  type: string = null;
  @serializable
  title: string = null;
  @serializable
  slug: string  = null;
  @serializable
  text: string  = "";
  @serializable
  createdAt: string = null;
  @serializable
  discardedAt: string = null;
  @serializable
  updatedAt: string = null;
  @serializable(list(object(Note)))
  children: IObservableArray<Note> = observable<Note>([]);

  @serializable
  parentId: string  = null;
  @serializable(list(object(BreadcrumbsItem)))
  breadcrumbs: IObservableArray<BreadcrumbsItem> = observable<BreadcrumbsItem>([]);
  @serializable
  isDraft: boolean = false;
  @serializable
  isTableOfContents: boolean = false;

  changed: boolean = false;
  @serializable
  variantId: string = null;
  @serializable
  variantTitle: string = null;

  @serializable
  variantSlug: string = null;

  @serializable
  authorId: string = null;

  @serializable
  authorName: string = null;

  @serializable
  description: string = null;

  @serializable
  fullTitle: string = null;

  @serializable
  projectId: string = null;

  @serializable
  projectName: string = null;
  
  @serializable
  deleted: boolean = false;

  @serializable
  logoType: string = null;

  @serializable
  logoPresentable: string = null;

  @serializable(object(User))
  lastEditedBy: User = null;

  @serializable(object(User))
  discardedBy: User = null;

  @serializable
  publicUrl: string = null;

  @serializable(list(primitive()))
  tags: IObservableArray<string> = observable<string>([]);

  @serializable(list(primitive()))
  participants: IObservableArray<string> = observable<string>([]);

  @serializable
  isFavorite: boolean = null;

  @serializable
  innerUrl: string = null;

  @serializable(list(object(BacklinkItem)))
  backlinks: Array<BacklinkItem> = [];

  constructor(){
    super()

    makeObservable(this, {
      id: observable,
      type: observable,
      title: observable,
      slug: observable,
      text: observable,
      createdAt: observable,
      updatedAt: observable,
      setTitle: action,
      setText: action,
      children: observable,
      parentId: observable,
      add: action,
      breadcrumbs: observable,
      setBreadcrumbs: action,
      isDraft: observable,
      setDraft: action,
      isTableOfContents: observable,
      setTableOfContents: action,
      changed: observable,
      setChanged: action,
      variantId: observable,
      variantTitle: observable,
      setSlug: action,
      authorId: observable,
      authorName: observable,
      setAuthorId: action,
      description: observable,
      setDescription: action,
      variantSlug: observable,
      deleted: observable,
      setDeleted: action,
      logoType: observable,
      logoPresentable: observable,
      publicUrl: observable,
      discardedAt: observable,
      discardedBy: observable,
      tags: observable,
      setTags: action,
      projectName: observable,
      isFavorite: observable,
      setFavorite: action,
      participants: observable,
      visibilityStatus: observable
    });
  }
  
  setDeleted(value: boolean){
    this.deleted = value;
  }

  setTitle(value: string) {
    if(this.title !== value){
      this.changed = true;
    }
    this.title = value;
  }

  setText(value: string) {
    if(this.text !== value){
      this.changed = true;
    }
    this.text = value;
  }

  setDraft(value: boolean) {
    this.changed = true;
    this.isDraft = value;
  }

  setTableOfContents(value: boolean) {
    this.changed = true;
    this.isTableOfContents = value;
  }

  add(newNote: Note) {
    newNote.parentId = this.id;
    this.children.push(newNote);
  }

  setBreadcrumbs(breadcrumbs: IObservableArray<BreadcrumbsItem>){
    this.breadcrumbs = breadcrumbs;
  }

  setChanged(value: boolean) {
    this.changed = value;
  }

  setSlug(value: string) {
    this.slug = value;
  }

  setAuthorId(value: string) {
    this.changed = true;
    this.authorId = value;
  }

  setDescription(value: string) {
    this.description = value;
  }

  setTags(values: string[]){
    this.tags.replace(values);
  }

  setFavorite(value: boolean) {
    this.isFavorite = value;
  }

  visibilityStatus(): string {
    if(this.isDraft){
      return "Private";
    }

    return "Published";
  }
}