import { Tablist as EvergreenTablist, Tab as EvergreenTab } from 'evergreen-ui';
import styled from 'styled-components';

export const Tablist = styled(EvergreenTablist)`
  border-bottom: 1px solid rgb(218, 225, 233);
`

export const Tab = styled(EvergreenTab)`
  &[role="tab"] {
    font-size: 14px;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }

    padding-bottom: 5px;
  }

  &:active{
    background-color: transparent;
    color: #027bfc;
  }

  &[aria-selected="true"] {
    background-color: transparent;
    border-bottom: 3px solid #027bfc;
    color: #027bfc;
  }

  &[aria-selected="false"] {
    color: rgb(155, 166, 178);

    &:hover{
      color: #027bfc;
      box-shadow: rgb(218, 225, 233) 0px 3px;
    }
  }
`

export const Filter = styled('div')`
  height: 28px;
  float: right;
  margin-right: 0px;
  padding-right: 0px;
`