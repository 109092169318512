import { UploadyContext, useItemProgressListener } from "@rpldy/uploady";
import { UPLOADER_EVENTS } from "@rpldy/uploader";
import { NativeTypes } from "react-dnd-html5-backend"
import { useDrop } from "react-dnd"
import * as React from "react";
import styled from "styled-components";
import { Badge, Button, defaultTheme, Link, Pane, Spinner, Text, TextInput, toaster } from "evergreen-ui";
import ApiClient, { IResponse } from "../utils/ApiClient";
import translate from "../i18n";

interface Props {
  type: "url" | "file",
  formatLabels: Array<string>,
  onFinish?(response: IResponse)
}

const FileUploader = ((props: Props) => {
  const {formatLabels, type, onFinish} = props;
  const uploadyContext = React.useContext(UploadyContext);
  const progressData = useItemProgressListener();
  const [processing, setProcessing] = React.useState(false);
  const {common, fileUploaderInput} = translate();

  React.useEffect(() => {
    const itemStartCallback = () => {
      setProcessing(true);
    };

    const itemFinishCallback = (item) => {
      onFinish && onFinish(item.uploadResponse.data);
      setProcessing(false);
    };

    const itemErrorCallback = (item) => {
      if(item?.uploadResponse?.data?.error) {
        toaster.danger(item?.uploadResponse?.data?.message);
      }
      setProcessing(false);
    };

    uploadyContext.on(UPLOADER_EVENTS.ITEM_START, itemStartCallback);
    uploadyContext.on(UPLOADER_EVENTS.ITEM_FINISH, itemFinishCallback);
    uploadyContext.on(UPLOADER_EVENTS.ITEM_ERROR, itemErrorCallback);

    return () => {
      uploadyContext.off(UPLOADER_EVENTS.ITEM_START, itemStartCallback);  
      uploadyContext.off(UPLOADER_EVENTS.ITEM_FINISH, itemFinishCallback);
      uploadyContext.off(UPLOADER_EVENTS.ITEM_ERROR, itemErrorCallback);
    };
  }, [uploadyContext]);

  const [{ isDragging }, dropRef] = useDrop({
      accept: NativeTypes.FILE,

      collect: ((monitor) => ({
          isDragging: !!monitor.isOver()
      })),
      drop: (item: any) => {
        if (uploadyContext) {
          uploadyContext.upload(item.files, uploadyContext.getOptions());
        }
      },
  });

  const handleOpenSelectFile = () => {
    uploadyContext.showFileUpload(uploadyContext.getOptions());
  }

  const [url, setUrl] = React.useState('');
  const handleImportFromWebsite = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setProcessing(true);
    try{
      const res = await new ApiClient('').post(uploadyContext.getOptions().destination.url, {...uploadyContext.getOptions().params, url: url});
      onFinish && onFinish(res);
    }catch(e){
      console.error(e);
      toaster.danger(common.errorMessage);
    }finally{
      setProcessing(false);
    }
  }

  return <Pane>
    {type === 'file' &&
      <div ref={dropRef} style={isDragging ? {background: defaultTheme.colors.background.tint1} : {}}>
        <Wrapper>
          <Pane display="flex" alignItems="center" flexDirection="column">
            <Pane marginBottom={5}>
              {formatLabels.map(x => <Badge color="neutral" marginRight={8} key={x}>{x}</Badge>)}
            </Pane>
            {progressData && progressData.completed < 100 ?
              <Pane display="flex" flexDirection="column" alignItems="center">
                <Spinner />
                <Text size={400}>{progressData?.completed.toFixed(1)} %</Text>
              </Pane>
              :
              (processing ? 
                <Pane display="flex" flexDirection="column" alignItems="center">
                  <Spinner />
                  <Text size={400}>{fileUploaderInput.processing}</Text>
                </Pane>
                :
                <Pane>
                  <Text size={400}>{fileUploaderInput.dropYourFiles} <Link href="#" onClick={handleOpenSelectFile}>{fileUploaderInput.orBrowse}</Link></Text>
                </Pane>
              )
            }
          </Pane>
        </Wrapper>
      </div>
    }

    {type === 'url' &&
      (processing ? 
        <Pane display="flex" flexDirection="column" alignItems="center">
          <Spinner />
          <Text size={400}>{fileUploaderInput.processing}</Text>
        </Pane>
        :
        <form>
          <TextInput required autoFocus={true} name="url" id="url" placeholder="https://website.com/docs" width="100%" height={38} marginBottom={20} onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setUrl(e.currentTarget.value)}/>
          <Button appearance="primary" height={40} width="100%" justifyContent="center" onClick={handleImportFromWebsite}>Import from website</Button>
        </form>
      )
    }
  </Pane>
  ;
});

const Wrapper = styled('div')`
  border: 1px dashed ${defaultTheme.colors.border.default};
  height: 200px;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default FileUploader;