import {
  Alert,
  Button,
  defaultTheme,
  Heading,
  Pane,
  Select,
  TextInput,
  Text,
  HelpIcon,
  Tooltip,
  Paragraph,
  Position,
  Strong,
  Switch, PlusIcon, TrashIcon, CrossIcon
} from "evergreen-ui";
import * as React from "react"
import Label from "../../../Label";
import ImageFileInput, { RefObject } from "../../../ImageFileInput";

import { HexColorPicker } from "react-colorful";
import "react-colorful/dist/index.css";
import styled from "styled-components";
import translate from "app/i18n";
import { MinimalButton, PrimaryButton } from "app/components/Typography";
import CodeEditor from "app/screens/Settings/Integrations/components/CodeEditor";
import Panel from "app/components/Panel";
import MetaTag from "app/models/MetaTag";

interface Props {
  name?: string,
  subdomain?: string,
  customDomain?: string,
  primaryColor?: string,
  logoUrl?: string,
  faviconUrl?: string,
  isLoading?: boolean,
  homePageUrl?: string,
  themeName?: string,
  storefrontHome?: string,
  footerText?: string,
  metaTags?: MetaTag[],
  onDelete?(e: React.SyntheticEvent): void,
  onSubmit?(state: State): void,
  onCancel?(state: State): void
}

export interface State {
  name?: string,
  customDomain?: string,
  primaryColor?: string,
  homePageUrl?: string,
  logo?: File
  favicon?: File,
  themeName?: string,
  storefrontHome?: string,
  footerText?: string,
  metaTags?: MetaTag[],
  metaTagsKeys?: string
  metaTagsValues?: string
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const THEMES = ['simple'];

const Form: React.FC<Props> = (props) => {
  const {logoUrl, faviconUrl, name, subdomain, homePageUrl, customDomain, primaryColor, themeName, isLoading, storefrontHome, footerText, metaTags, onSubmit, onCancel, onDelete} = props;
  const {projectForm, common, deleteProjectDialog} = translate();

  const [formState, setFormState] = React.useState<State>({
    name: name,
    primaryColor: primaryColor,
    customDomain: customDomain,
    homePageUrl: homePageUrl,
    storefrontHome: storefrontHome,
    footerText: footerText,
    logo: null,
    favicon: null,
    themeName: themeName,
    metaTags: metaTags,
    metaTagsKeys: metaTags.map(x => x.key).join(),
    metaTagsValues: metaTags.map(x => x.value).join()
  });

  const logoRef = React.useRef<RefObject>();
  const faviconRef = React.useRef<RefObject>();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(onSubmit) onSubmit(formState);

    setFormState({...formState, logo: null, favicon: null, metaTagsKeys: formState.metaTags.map(x => x.key).join(), metaTagsValues: formState.metaTags.map(x => x.value).join()});
  }

  const handleFooterTextChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    setFormState({...formState, footerText: e.currentTarget.value});
  }

  const handleNameChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormState({...formState, name: e.currentTarget.value});
  }

  const handleHomePageUrlChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormState({...formState, homePageUrl: e.currentTarget.value});
  }

  const handleCustomDomainChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormState({...formState, customDomain: e.currentTarget.value});
  }

  const handleLogoChange = (file: File) => {
    setFormState({...formState, logo: file});
  }

  const handleFaviconChange = (file: File) => {
    setFormState({...formState, favicon: file});
  }

  const handlePrimaryColorChange = (color: string) => {
    setFormState({...formState, primaryColor: color});
  }

  const handleStoreFrontHomeFirstPage = () => {
    setFormState({...formState, storefrontHome: 'first_page'});
  }

  const handleStoreFrontHomeRoots = () => {
    setFormState({...formState, storefrontHome: 'roots'});
  }

  // const handleThemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setFormState({...formState, themeName: e.target.value});
  // }

  const handleMetaTagChange = (value: string, id: string, korv: 'key' | 'value') => {
    const tags = formState.metaTags.map(x => {
      if (x.id === id) {
        return new MetaTag(id, korv === 'key' ? value : x.key, korv === 'value' ? value : x.value)
      } else {
        return x
      }
    })

    setFormState({...formState, metaTags: tags, metaTagsKeys: tags.map(x => x.key).join(), metaTagsValues: tags.map(x => x.value).join()});
  }

  const handleMetaTagRemove = (id: string) => {
    const mt = formState.metaTags.filter(x => x.id !== id)
    setFormState({...formState, metaTags: mt, metaTagsKeys: mt.map(x => x.key).join(), metaTagsValues: mt.map(x => x.value).join()});
  }

  const handleAddMetaTag = () => {
    const mt = [...formState.metaTags, new MetaTag(generateUuid(), '', '')];

    setFormState({...formState, metaTags: mt, metaTagsKeys: mt.map(x => x.key).join(), metaTagsValues: mt.map(x => x.value).join()});
  }

  const generateUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const handleFormCancel = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormState({...formState, name, homePageUrl, customDomain, primaryColor, themeName, storefrontHome, logo: null, favicon: null});

    if(logoRef) logoRef.current.reset();
    if(faviconRef) faviconRef.current.reset();

    if(onCancel) onCancel(formState);
  }

  const hasChanges = (): boolean => {
    return (formState.name != name ||
      formState.customDomain != customDomain ||
      formState.primaryColor != primaryColor ||
      formState.homePageUrl != homePageUrl ||
      formState.themeName != themeName ||
      formState.storefrontHome != storefrontHome ||
      formState.footerText != footerText ||
      formState.logo !== null ||
      formState.favicon !== null || formState.metaTags.length !== metaTags.length ||
      formState.metaTagsKeys !== metaTags.map(x => x.key).join() ||
      formState.metaTagsValues !== metaTags.map(x => x.value).join()
    )
  }

  const dnsTooltip = () => {
    return <Pane display="flex" flexDirection="column">
      <Text marginBottom={8}>{projectForm.dnsTooltip}</Text>
      <Text><Strong>Host: </Strong>help</Text>
      <Text><Strong>Value: </Strong>{subdomain}</Text>
    </Pane>
  }

  return <form style={{width: '100%'}} onSubmit={handleFormSubmit}>
    <Pane paddingBottom={20} display="flex" marginTop={40} flexDirection="column">
      <Pane display="flex" justifyContent="space-between" flex="1">
        <Pane display="flex" flexDirection="column" flex="1">
          <Label htmlFor="name">{projectForm.projectName}</Label>
          <TextInput required
                    onChange={handleNameChange}
                    autoFocus={true} name="name" id="name"
                    placeholder={projectForm.projectNamePlaceholder}
                    width="100%" height={38}
                    value={formState.name}/>
        </Pane>

        <Pane display="flex" flexDirection="column" flex="1" marginLeft={10}>
          <Label htmlFor="name">{projectForm.homePageUrl}</Label>
          <TextInput onChange={handleHomePageUrlChange}
                    name="homePageUrl" id="homePageUrl"
                    placeholder="https://my-brand.com"
                    width="100%" height={38}
                    value={formState.homePageUrl || ''}/>
        </Pane>
      </Pane>

      <Label htmlFor="theme" marginTop={20}>{projectForm.storeFrontHome}</Label>
      <Pane display="flex" justifyContent="space-between" paddingBottom={40} marginBottom={20}>
        <Pane height={240} minWidth={240} width="100%">
          <Text size={300}>{projectForm.firstPageHint}</Text>
          <Pane marginTop={6} onClick={handleStoreFrontHomeFirstPage} border="default" borderColor={formState.storefrontHome === 'first_page' ? '#027bfc' : 'default'} borderRadius={5} cursor={"pointer"} display={"flex"} flexDirection={"column"} overflow={"hidden"}>
            <Pane width="100%" height={20} background={"tint2"}></Pane>
            <Pane width="100%" height={240} display={"flex"}>
              <Pane width="30%" height="100%" borderRight={`1px solid ${defaultTheme.colors.background.tint2}`} paddingX={10}>
                <Pane width="100%" height={10} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="100%" height={10} background={"tint2"} marginY={10}></Pane>
                <Pane width="80%" height={10} background={"tint2"} marginY={10}></Pane>
                <Pane width="90%" height={10} background={"tint2"} marginY={10}></Pane>
              </Pane>
              <Pane width="70%" height="100%" background={"white"} paddingX={10}>
                <Pane width="60%" height={16} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="100%" height={6} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="90%" height={6} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="100%" height={6} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="100%" height={6} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="100%" height={6} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
                <Pane width="90%" height={6} background={formState.storefrontHome === 'first_page' ? '#027bfc' : 'tint2'} marginY={10}></Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>

        <Pane height={240} minWidth={240} width="100%" marginLeft={10}>
          <Text size={300} marginBottom={10}>{projectForm.listingPagesHint}</Text>
          <Pane marginTop={6} onClick={handleStoreFrontHomeRoots} border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'default'} borderRadius={5} cursor={"pointer"} display={"flex"} flexDirection={"column"} overflow={"hidden"}>
            <Pane width="100%" height={20} background={"tint2"}></Pane>
            <Pane width="100%" height={240} display={"flex"}>
              <Pane width="30%" height="100%" borderRight={`1px solid ${defaultTheme.colors.background.tint2}`} paddingX={10}>
                <Pane width="100%" height={10} background={"tint2"} marginY={10}></Pane>
                <Pane width="100%" height={10} background={"tint2"} marginY={10}></Pane>
                <Pane width="80%" height={10} background={"tint2"} marginY={10}></Pane>
                <Pane width="90%" height={10} background={"tint2"} marginY={10}></Pane>
              </Pane>

              <Pane width="70%" height="100%" background={"white"} padding={10} display={"flex"} flexDirection={"column"}>
                <Pane width="100%" height="20%" display={"flex"} gap={10}>
                  <Pane width="20%" height="100%" border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'tint2'} borderRadius={5}></Pane>
                  <Pane width="20%" height="100%" border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'tint2'} borderRadius={5}></Pane>
                  <Pane width="20%" height="100%" border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'tint2'} borderRadius={5}></Pane>
                  <Pane width="20%" height="100%" border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'tint2'} borderRadius={5}></Pane>
                </Pane>

                <Pane width="100%" height="20%" display={"flex"} gap={10} marginTop={10}>
                  <Pane width="20%" height="100%" border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'tint2'} borderRadius={5}></Pane>
                  <Pane width="20%" height="100%" border="default" borderColor={formState.storefrontHome === 'roots' ? '#027bfc' : 'tint2'} borderRadius={5}></Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>

      </Pane>

      <Label htmlFor="theme" marginTop={20}>{projectForm.footerText}</Label>

      <Pane display="flex">
        <TextInput onChange={handleFooterTextChange}
                   name="footer_text" id="footer_text"
                   value={formState.footerText || ''}
                   placeholder={`Copyright © ${formState.name}`}
                   width="100%" height={38} />
      </Pane>

      <Label htmlFor="metaTags" marginTop={20}>{projectForm.metaTagsTitle}</Label>

      <Pane display="flex" paddingBottom={20} flexDirection="column" gap={8}>
        {formState.metaTags.map((metaTag, index) => {
          return <Pane display="flex" justifyContent="space-between" flex="1" gap={8} key={metaTag.id}>
            <TextInput width="100%" height={38} placeholder={'name'} value={metaTag.key} onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleMetaTagChange(e.currentTarget.value, metaTag.id, 'key')} />
            <TextInput width="100%" height={38} placeholder={'content'} value={metaTag.value} onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleMetaTagChange(e.currentTarget.value, metaTag.id, 'value')}/>
            <MinimalButton type="button" height={38} onClick={() => handleMetaTagRemove(metaTag.id)}><CrossIcon /></MinimalButton>
          </Pane>
        })}

        {<MinimalButton iconBefore={PlusIcon} type="button" onClick={handleAddMetaTag}>{projectForm.addMetaTag}</MinimalButton>}
      </Pane>

      {/*<Label htmlFor="theme" marginTop={20}>{projectForm.theme}</Label>*/}

      {/*{*/}
      {/*  THEMES.indexOf(formState.themeName) === -1 ?*/}
      {/*  <Alert*/}
      {/*    appearance="card"*/}
      {/*    intent="none"*/}
      {/*    title={`You are using the custom theme ${capitalizeFirstLetter(themeName)} made special for you, ask support@wannadocs.com to change it.`}*/}
      {/*  />*/}
      {/*:*/}
      {/*  <Select minHeight={38} width="100%" height={38} value={formState.themeName} onChange={handleThemeChange}>*/}
      {/*    {THEMES.map(x => <option key={x} value={x}>{capitalizeFirstLetter(x)}</option>)}*/}
      {/*  </Select>*/}
      {/*}*/}

      <Label htmlFor="logo" marginTop={20}>{projectForm.logo}</Label>
      <ImageFileInput ref={logoRef} name="logo" url={logoUrl} onChange={handleLogoChange} backgroundColor={formState.primaryColor}/>

      <Label htmlFor="favicon" marginTop={20}>{projectForm.favicon}</Label>
      <ImageFileInput ref={faviconRef} name="favicon" url={faviconUrl} onChange={handleFaviconChange}/>

      <Pane paddingBottom={20} display="flex" marginTop={40} flexDirection="column">
        <Pane display="flex" justifyContent="space-between" flex="1">
          <Pane display="flex" flexDirection="column" flex="1">
            <Label htmlFor="subdomain">{projectForm.defaultDomain}</Label>
            <TextInput name="subdomain" id="subdomain"
                    defaultValue={subdomain} disabled={true}
                    width="100%" maxWidth={340} height={38} />
          </Pane>

          <Pane display="flex" flexDirection="column" flex="1" marginLeft={10}>
            <Label htmlFor="customDomain" display="flex" alignItems="center">
              {projectForm.customDomain}
                <Tooltip
                  content={dnsTooltip()}
                  appearance="card"
                  position={Position.BOTTOM_LEFT}
                >
                  <HelpIcon marginLeft={4}/>
                </Tooltip>
            </Label>
            <TextInput  name="customDomain" id="customDomain" placeholder="help.project.com"
                        onChange={handleCustomDomainChange}
                        value={formState.customDomain || ''} width="100%" maxWidth={340} height={38} />
          </Pane>
        </Pane>
      </Pane>

      <Label htmlFor="primaryColor" marginTop={20}>{projectForm.color}</Label>
      <Pane marginBottom={20}>
        <StyledHexColorPicker color={formState.primaryColor} onChange={handlePrimaryColorChange} />
      </Pane>
    </Pane>

    <Pane zIndex={10} borderTop={"default"} display="flex"
          flex="1" justifyContent="flex-end" paddingTop={20}
          paddingBottom={40} backgroundColor={"white"}
          position={"sticky"} bottom={0}>
      <MinimalButton type="button" disabled={!hasChanges()} height={40} onClick={handleFormCancel}>{common.btnCancel}</MinimalButton>
      <PrimaryButton type="submit" appearance="primary" disabled={!hasChanges()} height={40} marginLeft={10} isLoading={isLoading}>{common.btnSave}</PrimaryButton>
    </Pane>

    {onDelete && <Pane display="flex" flex="1" marginTop={30} marginBottom={40} border="default" borderColor={defaultTheme.colors.intent.danger} borderRadius={5} padding={30}>
      <Pane display="flex" flex="1" justifyContent="space-between" alignItems="center">
        <Pane display="flex" flex="1" flexDirection="column">
          <Heading size={400}>{deleteProjectDialog.title}</Heading>
          <Text>{deleteProjectDialog.subtitle}</Text>
        </Pane>
        <Pane>
          <Button onClick={onDelete} appearance="primary" intent="danger">{common.btnDelete}</Button>
        </Pane>
      </Pane>
    </Pane>}
  </form>
}

const StyledHexColorPicker = styled(HexColorPicker)`
  width: 100%;

  .react-colorful__saturation {
    border-radius: 3px 3px 0 0;
  }
  .react-colorful__hue {
    height: 30px;
    border-radius: 0 0 3px 3px;
  }
`

export default Form;
