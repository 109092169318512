import Divider from 'app/components/Divider';
import translate from 'app/i18n';
import Label from 'app/components/Label';
import Note from 'app/models/Note';
import NotesStore from 'app/stores/NotesStore';
import ProjectsStore from 'app/stores/ProjectsStore';
import UiStore from 'app/stores/UiStore';
import { defaultTheme, IconButton, Pane, RefreshIcon, Switch, TagInput, Text, Textarea, TextInput, toaster, Tooltip, UpdatedIcon } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form';
import slugify from 'slugify';
import styled from 'styled-components';
import { PrimaryButton } from 'app/components/Typography';

interface Props {
  ui?: UiStore,
  notes?: NotesStore,
  projects?: ProjectsStore,
  note: Note
}

interface IFormInputs {
  description: string,
  tags: string[],
}

const PersonalSettingsDialog = (observer((props: Props) => {
  const {note, ui, notes, projects} = props;
  const  {common, noteOptions} = translate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();

  const onSubmit = async (data: IFormInputs) => {
    try{
      await notes.update({id: note.id, ...data});
      toaster.success(common.savedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      ui.hideDialog();
    }
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <Pane paddingBottom={20} borderBottom="default">
      <Pane display="flex" flexDirection="column" flex="1">
        <Label>{noteOptions.description}</Label>
        <Textarea defaultValue={note.description} rows={6} placeholder={noteOptions.descriptionPlaceholder} {...register("description")}/>
      </Pane>

      <Pane display="flex" flexDirection="column" flex="1" marginTop={20}>
        <Label>{noteOptions.keywords}</Label>
        <Controller
            name="tags"
            control={control}
            defaultValue={note.tags}
            render={({ field }) =>
              <TagInput height={38} values={field.value} inputProps={{ placeholder: noteOptions.keywordsPlaceholder }} onChange={(values: string[]) => {
                field.onChange([...new Set(values)])
              }} />
            }
        />
      </Pane>
    </Pane>

    <Pane display="flex" flex="1" justifyContent="space-between" marginTop={20} marginBottom={20}>
      <PrimaryButton appearance="primary" height={40} type="submit">{noteOptions.btnApplyChangesAndClose}</PrimaryButton>
    </Pane>
  </form>
}));

export default inject('ui', 'notes', 'projects')(PersonalSettingsDialog);