import { action, IObservableArray, makeObservable, observable, ObservableSet, runInAction } from "mobx";
import Note from "../models/Note";
import RootStore from "./RootStore";

export default class NotesTreeStore {
  expanded: ObservableSet<String> = observable.set<String>();
  root: Note = null;

  constructor(private readonly rootStore: RootStore){
    makeObservable(this, {
      root: observable,
      setExpanded: action,
      toggleExpanded: action,
      loadTree: action,
      reset: action,
      remove: action,
      add: action,
      forEachNotes: action,
      find: action,
      moveTo: action,
      findAncestors: action
    });
  }

  setExpanded(id: string){
    this.expanded.add(id);
  }
  
  toggleExpanded(id: string){
    if(this.expanded.has(id)){
      this.expanded.delete(id);
    }else{
      this.expanded.add(id);
    }
  }

  async loadTree(variantId: string): Promise<Note>{
    const root = await this.rootStore.notes.tree(variantId);

    runInAction(() =>{
      this.root = root;
    });

    return this.root;
  }

  reset(){
    this.root = null;
  }

  // TreeUtils
  remove(id: string): number {
    let count = 0; // FIXME: make counting for subchildren?
    const target = this.find(id);

    const remove = (nodes: IObservableArray<Note>) => {
      if (nodes.remove(target)){
        count =+ 1
      }
      nodes.forEach(x => remove(x.children));
    }

    remove(this.root.children);

    return count;
  }

  add(node: Note, target: Note) {
    this.remove(node.id);
    target.add(node);
  }

  forEachNotes(f: (n: Note) => void) {
    const walk = (nodes: IObservableArray<Note>) => {
      nodes.forEach(x => {
        f(x);
        walk(x.children)
      });
    }

    walk(this.root.children);
  }

  find(noteId: string): Note {
    const walkAndFind = (id: string, nodes: IObservableArray<Note>): Note => {
      for(var i = 0; i < nodes.length; i++) {
        if (nodes[i].id === id){
          return nodes[i]
        }else if(nodes[i].children.length > 0){
          let node = walkAndFind(id, nodes[i].children)
          if (node) return node;
        }
      }
    }

    if(this.root.id === noteId) return this.root;

    return walkAndFind(noteId, this.root.children);
  }

  moveTo(after: Note, 
    target: Note, 
    items: IObservableArray<Note>, 
    parentId: string,
    position: "after" | "top" = "after") {

    target.parentId = parentId;

    const reorder = () => {
      if(!items.find(x => x.id === target.id)){ // Move from another list
        const index = items.indexOf(after);
        this.remove(target.id);

        const array = items.slice();
        if (position === "after") array.splice(index + 1, 0, target);
        if (position === "top") array.splice(0, 0, target);
        return array
      } else { // Move within one list(just reorder)
        const array = items.slice().filter(x => x.id !== target.id);

        if (position === "after") array.splice(array.indexOf(after) + 1, 0, target);
        if (position === "top") array.splice(0, 0, target);
        return array
      }
    }

    items.replace(reorder());
  }

  findAncestors(note: Note): Array<Note> {
    const ancestors = [];

    const walk = (nextNote: Note) => {
      const item = this.find(nextNote.parentId);
      if (item){
        ancestors.push(item)
        walk(item)
      }
    }

    const parent = this.find(note.parentId)
    if (parent){
      ancestors.push(parent);
      walk(parent)
    }

    return ancestors
  }
}