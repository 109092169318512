import Note from 'app/models/Note';
import { Pane, defaultTheme } from 'evergreen-ui';
import * as React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  note: Note,
  buttons?: React.ReactNode,
  info?: React.ReactNode
}

const Item: React.FC<Props> = ({note, buttons, info}) => {

  return <Wrapper>
    <StyledLink to={`/notes/${note.id}`} style={{textDecoration: 'none', color: defaultTheme.colors.text.default}}>
      <Pane display="flex" flexDirection="column">
        {info && info}
      </Pane>

      <ButtonsWrapper>
        {buttons && buttons}
      </ButtonsWrapper>
    </StyledLink>
  </Wrapper>
}

const ButtonsWrapper = styled(Pane)`
  visibility: hidden
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 7px 7px;
  margin: 0px
`

const Wrapper = styled('li')`
  cursor: pointer;
  min-height: 54px;

  &:hover {
    background-color: ${defaultTheme.colors.background.tint2};
  }

  &:hover ${ButtonsWrapper}{
    visibility: visible;
  }
`

export default Item;