import Item from 'app/components/NoteList/components/Item';
import Note from 'app/models/Note';
import { Pane, Heading, Text } from 'evergreen-ui';
import { observer } from 'mobx-react';
import * as React from 'react'

const RecentItem = observer((props: {item: Note}) => {

  const {item} = props;

  return <Item info={<>
    <Pane display="flex" alignItems="center">
      <Heading size={500} marginRight={8}>{item.title}</Heading>
    </Pane>
    <Text color="muted">{item.projectName}</Text>
  </>} note={item}>
  </Item>
});

export default RecentItem;