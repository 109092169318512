import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import { action, computed, makeObservable, observable, reaction, transaction } from "mobx";
import Variant from "../models/Variant";
import { client, IResponse } from "../utils/ApiClient";

export default class VariantsStore extends BaseStore<Variant> {
  constructor(protected readonly rootStore: RootStore){
    super(rootStore, Variant, 'variant');

    makeObservable(this, {
      setMain: action
    });
  }

  async setMain(id: string): Promise<IResponse> {
    const res = await client.post(`${this.modelName}s.set_main`, {id});

    this.data.forEach(x => x.setMain(x.id === id));

    return res;
  }
}