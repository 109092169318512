import * as React from 'react'
import {defaultTheme, Label as EvergreenLabel, LabelProps} from 'evergreen-ui'

const Label: React.FC<LabelProps> = (props) => {
  return <EvergreenLabel 
    {...props}
    marginBottom={5}
    display={props.display || 'block'}
    textTransform="uppercase"
    color={defaultTheme.colors.text.muted}
    letterSpacing="1.2px"
    fontSize={12}>{props.children}</EvergreenLabel>
}

export default Label;