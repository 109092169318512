import { Button, CaretDownIcon, CircleIcon, defaultTheme, MediaIcon, Pane, Popover, Position, SelectMenu, Spinner, SymbolCircleIcon, Tab, Tablist, Text, toaster } from 'evergreen-ui';
import * as React from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { inject, observer } from 'mobx-react';
import NotesStore from '../../../../stores/NotesStore';
import Note from '../../../../models/Note';
import styled from 'styled-components';
import translate from 'app/i18n';
import ProjectsStore from 'app/stores/ProjectsStore';

interface Props {
  note: Note,
  notes?: NotesStore,
  projects?: ProjectsStore
}

const PublicityStatusSelector: React.FC<Props> = ({note, notes, projects}) => {

  const {common} = translate();

  const handleChangeStatus = async (close: () => void, status: string) => {
    try{
      if(status === "public"){
        note.setDraft(false);
      }else{
        note.setDraft(true);
      }
      await notes.setVisibleStatus(note.id, status);
      close();
    }catch(e){
      toaster.danger(e.message);
    }
  }

  if(projects.isReadonlyUser){
    return <Pane marginBottom={10} marginLeft={8}>
        <Button
          disabled={true}
          type="button"
          style={{ color: defaultTheme.colors.text.default }}
          iconBefore={<SymbolCircleIcon color={note.visibilityStatus() === "Private" ? '#fbc916' :'#1bb157'}/>}
          iconAfter={CaretDownIcon}
          marginTop={6}
          appearance="minimal">
          {note.visibilityStatus() === "Private" ? common.private : common.published}
      </Button>
    </Pane>
  }

  return <Popover
    position={Position.BOTTOM_LEFT}
    content={({close}) => {
      return <Pane
              width={200}
              height={82}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <ItemWrapper onClick={() => handleChangeStatus(close, 'public')}>
                <SymbolCircleIcon size={16} color="#1bb157"/>
                <ItemTextWrapper fontWeight={note.visibilityStatus() === "Published" ? 'bolder' : 'normal'} color={note.visibilityStatus() === "Published" ? defaultTheme.colors.text.selected : 'default'} marginLeft={10}>
                  {common.published}
                </ItemTextWrapper>
              </ItemWrapper>

              <ItemWrapper onClick={() => handleChangeStatus(close, 'private')}>
                <SymbolCircleIcon size={16} color="#fbc916"/>
                <ItemTextWrapper fontWeight={note.visibilityStatus() === "Private" ? 'bolder' : 'normal'} color={note.visibilityStatus() === "Private" ? defaultTheme.colors.text.selected : 'default'} marginLeft={10}>
                  {common.private}
                </ItemTextWrapper>
              </ItemWrapper>
            </Pane>
    }}
  >
    <Pane marginBottom={10} marginLeft={8}>
      <Button
          type="button"
          style={{ color: defaultTheme.colors.text.default }}
          iconBefore={<SymbolCircleIcon color={note.visibilityStatus() === "Private" ? '#fbc916' :'#1bb157'}/>}
          iconAfter={CaretDownIcon}
          marginTop={6}
          appearance="minimal">
          {note.visibilityStatus() === "Private" ? common.private : common.published}
      </Button>
    </Pane>
  </Popover>
}

const ItemTextWrapper = styled(Text)`
  &:hover{
    color: ${defaultTheme.colors.text.selected};
  }
`

const ItemWrapper = styled(Pane)`
  width: 100%;
  cursor: pointer;
  flex: 1;
  display: flex;
  padding: 6px 12px;
  align-items: center;

  &:hover ${ItemTextWrapper}{
    color: ${defaultTheme.colors.text.selected};
  }

  &:hover{
    background-color: ${defaultTheme.colors.background.tint2};
  }
`

export default inject('notes', 'projects')(observer(PublicityStatusSelector));