import { SearchIcon, Text } from "evergreen-ui";
import * as React from "react";

interface Props {
  text: string
}

const EmptyList = (props: Props) => {
  return <Text padding={100} alignItems="center" display="flex" flex="1" justifyContent="center">
    <SearchIcon marginRight={10}/> {props.text}
  </Text>
}

export default EmptyList;