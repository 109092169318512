import Paywall from 'app/components/Paywall';
import { MinimalButton, PrimaryButton } from 'app/components/Typography';
import translate from 'app/i18n';
import { Button, Heading, InfoSignIcon, Pane, PlusIcon, SearchIcon, Text, Textarea, toaster } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import Dialog from '../../../../dialogs/Dialog';
import ProjectsStore from '../../../../stores/ProjectsStore';
import Label from '../../../Label';
import Loader from '../../../Loader';
import Item from './Item';

interface Props {
  projects?: ProjectsStore
}

const Empty = () => {
  const {common} = translate();

  return <Text padding={100} alignItems="center" display="flex" flex="1" justifyContent="center">
    <InfoSignIcon marginRight={10} /> {common.noInvitesPending}
  </Text>
}

const Invitations = observer((props: Props) => {
  const { projects } = props;

  const [showDialog, setShowDialog] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [emails, setEmails] = React.useState("");
  const {invitationsScreen, common} = translate();

  const handleShowInviteDialog = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowDialog(true);
  }

  const handleCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowDialog(false);
  }

  const handleSend = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSending(true);

    try {
      await projects.sendInvite(emails);
      toaster.success(invitationsScreen.invitesSended);
      setEmails('');
      setShowDialog(false);
    } catch (e) {
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    } finally {
      setSending(false);
    }
  }

  const handleEmailsChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setEmails(e.currentTarget.value);
  }

  const showPaywall = () => {
    return !projects.current.payable;
  }

  if (!projects.current) return <Loader />


  return <Pane width="100%" padding={10}>

    <Pane display="flex" flex="1" marginTop={40} width="100%" flexDirection="column">
      <Pane display="flex" flex="1" justifyContent="flex-end">
        <PrimaryButton height="40" appearance="primary" iconBefore={PlusIcon} onClick={handleShowInviteDialog} disabled={showPaywall() || projects.isReadonlyUser}>{invitationsScreen.btnInvite}</PrimaryButton>
      </Pane>
      <Pane display="flex" flex="1" flexDirection="column" marginTop={20} borderTop="default" paddingTop={16}>
        {showPaywall() ?
          <Paywall />
          :
          projects.current.invites.length === 0 ? <Empty /> : projects.current.invites.map(x => <Item key={x.id} invite={x} />)
        }
      </Pane>
    </Pane>


    <Dialog width={600} isShown={showDialog} hasFooter={false} hasHeader={false} onCloseComplete={() => setShowDialog(false)}>
      <Heading size={700} marginBottom={20}>{invitationsScreen.addPeopleTo} {projects.current?.name}</Heading>

      <Label htmlFor="emails">{invitationsScreen.emails}</Label>
      <Textarea id="emails" placeholder="john@mail.com, doe@mail.com" onChange={handleEmailsChange} />

      <Pane marginTop={20} paddingTop={20} display="flex" justifyContent="space-between" alignItems="baseline" borderTop="default">
        <MinimalButton height={40} onClick={handleCancel}>{common.btnCancel}</MinimalButton>
        <PrimaryButton height={40} appearance="primary" onClick={handleSend} isLoading={sending} disabled={emails.trim().length === 0}>{invitationsScreen.btnSendInvites}</PrimaryButton>
      </Pane>
    </Dialog>
  </Pane>
});

export default inject('projects')(Invitations);