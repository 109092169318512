import { MinimalButton, PrimaryButton } from "app/components/Typography";
import translate from "app/i18n";
import { Button, Combobox, Pane, TextInput, TrashIcon, StarEmptyIcon } from "evergreen-ui"
import * as React from "react"
import slugify from "slugify";
import Divider from "../../Divider"
import Label from "../../Label"
import useForm from "../../useForm";

interface Props {
  id?: string,
  title: string,
  lang: string,
  slug?: string,
  isMain: boolean,
  isLoading: boolean,
  isAllowDelete: boolean,
  onSubmit?(state: State): void
  onCancel?(state: State): void
  onDelete?(e: React.SyntheticEvent<HTMLInputElement>): void
  onSetAsMain?(e: React.SyntheticEvent<HTMLInputElement>): void
}

interface State {
  title: string,
  lang: string,
  slug: string
}

const SupportedLanguages = [
  { label: '🇺🇸  English', lang: 'en' },
  { label: '🇷🇺  Russian', lang: 'ru' },
  { label: '🇺🇦  Ukrainian', lang: 'ua' },
  { label: '🇩🇪  German', lang: 'de' },
  { label: '🇫🇷  French', lang: 'fr' },
  { label: '🇪🇸  Spanish', lang: 'es' },
  { label: '🇵🇹  Portuguese', lang: 'pt' },
];

const DefaultLanguage = SupportedLanguages.find(x => x.lang === 'en');

const Form: React.FC<Props> = (props) => {
  const {id, title, lang, slug, onSubmit, onCancel, onDelete, onSetAsMain, isMain, isLoading, isAllowDelete} = props;

  const {variantForm, common} = translate();

  const [formState, setFormState, handleSubmit, handleCancel, useChange] = useForm<State>({
    state: {
      title: title || '',
      lang: lang || DefaultLanguage.lang,
      slug: slug || ''
    },
    onSubmit,
    onCancel
  });

  const handleNameChange = useChange('title');
  const handleSlugChange = useChange('slug', (e: React.SyntheticEvent<HTMLInputElement>) => slugify(e.currentTarget.value));
  const handleLanguageChange = useChange('lang');

  const handleDelete = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    if(onDelete) onDelete(e);
  }

  const handleSetAsMain = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    if(onSetAsMain) onSetAsMain(e);
  }

  return <form onSubmit={handleSubmit}>
    <Label htmlFor="title">{variantForm.name}</Label>
    <TextInput required onChange={handleNameChange} autoFocus={true} name="title" id="title" placeholder={variantForm.namePlaceholder} width="100%" height={38} marginBottom={20} value={formState.title}/>

    {id &&
      <Pane>
        <Label htmlFor="slug">{variantForm.slug}</Label>
        <TextInput required onChange={handleSlugChange} name="slug" id="slug" placeholder={variantForm.slugPlaceholder} width="100%" height={38} marginBottom={20} value={formState.slug}/>
      </Pane>
    }
    <Label htmlFor="lang">{variantForm.chooseLang}</Label>
    <Combobox
      initialSelectedItem={SupportedLanguages.find(x => x.lang === formState.lang) || DefaultLanguage}
      width="100%"
      height={38}
      name="lang"
      id="lang"
      items={SupportedLanguages}
      itemToString={item => item ? item.label : ''}
      onChange={selected => selected && handleLanguageChange(selected.lang)}
      placeholder={variantForm.chooseLangPlaceholder}
      marginBottom={40}
    />
    <Divider />

    <Pane display="flex" flex="1" justifyContent="space-between" marginTop={20} marginBottom={40}>
      <MinimalButton type="button" onClick={handleCancel} height={40}>{common.btnCancel}</MinimalButton>
      {id ? <PrimaryButton type="submit" appearance="primary" height={40} isLoading={isLoading}>{common.btnUpdate}</PrimaryButton> : <PrimaryButton type="submit" appearance="primary" height={40} isLoading={isLoading}>{common.btnCreate}</PrimaryButton>}
    </Pane>

    {id &&
      <Pane>
        <Divider />

        <Pane display="flex" flex="1" marginTop={20} flexDirection="column">
          {!isMain && <Button type="button" height={40} iconBefore={StarEmptyIcon} appearance="minimal" intent="none" onClick={handleSetAsMain} isLoading={isLoading}>{variantForm.setAsMain}</Button>}
          {isAllowDelete && <Button type="button" height={40} iconBefore={TrashIcon} appearance="minimal" intent="danger" onClick={handleDelete} isLoading={isLoading}>{common.btnDelete}</Button>}
        </Pane>
      </Pane>
    }
  </form>
}

export default Form;