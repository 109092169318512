import * as React from "react";
import Props from "./Props";
const VKImage = require('../../../../images/vk.png');

const Component: React.FC<Props> = (props: Props) => {
    const { matches } = props.attrs;

    if (!matches) {
        return <span />;
    }

    const [_, ownerId, videoId] = matches;

    return (
        <iframe
            frameBorder="0"
            width="100%"
            height="400px"
            allow="encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
            allowFullScreen
            src={`https://vk.com/video_ext.php?oid=${ownerId}&id=${videoId}&hd=2`}
        />
    );
};

export default {
    View: Component,
    Regex: /(?:https?:\/\/)?(?:www\.)?vk\.com\/video(?<ownerId>[-\d]+)_(?<videoId>\d+)/i,
    Icon: () => (
        <img
            src={VKImage}
            width={24}
            height={24}
        />
    ),
};
