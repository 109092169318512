import { action, makeObservable, observable } from "mobx";
import BaseModel from "./BaseModel";
import {
  serializable
} from "serializr"

export default class User extends BaseModel {
  @serializable
  email: string = null;
  @serializable
  provider: string = null;
  @serializable
  name: string = null;
  @serializable
  lastActiveAt: string = null;
  @serializable
  createdAt: string = null;
  @serializable
  updatedAt: string = null;
  @serializable
  username: string = null;
  @serializable
  photoUrl: string = null;
  @serializable
  cloudProductId: string = null;

  constructor(){
    super();

    makeObservable(this, {
      id: observable,
      email: observable,
      provider: observable,
      name: observable,
      username: observable,
      photoUrl: observable,
      lastActiveAt: observable,
      createdAt: observable,
      updatedAt: observable,
      cloudProductId: observable,
      setUsername: action
    })
  }

  setUsername(value: string){
    this.username = value;
  }
}