import { Pane, SmallTickIcon } from "evergreen-ui";
import * as React from "react"

interface Props {
  onChange?(color: string): void
}

export interface State {
  name: string
}

export const COLORS = [
  "#26cb7c",
  "#03d1eb",
  "#3884ff",
  "#a44eed",
  "#ff4642",
  "#f77d05",
  "#ffd139", 
  "#183055",
  "#ffeb3b",
  "#9e9e9e"]

const ColorPresetSelector: React.FC<Props> = (props) => {
  const {onChange} = props;
  const [selected, setSelected] = React.useState(COLORS[0]);

  const handleChangeColor = (color: string) => {
    if(onChange) { onChange(color); }
    setSelected(color);
  }

  return <Pane display="flex" justifyContent="space-between">
    {COLORS.map(x => {
      return <Pane
        onClick={() => handleChangeColor(x)}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
        borderRadius={3}
        backgroundColor={x}
        width={38}
        height={38}
        key={x}>
          {x === selected && <SmallTickIcon size={25}/>}
      </Pane>
    })}
  </Pane>
}

export default ColorPresetSelector;