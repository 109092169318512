import * as React from 'react'
import { inject, Observer, observer } from 'mobx-react';
import { Switch, Menu, Popover, Text, Position, InheritanceIcon, DuplicateIcon, TrashIcon, ImportIcon, DocumentShareIcon, Pane, ArrowRightIcon, toaster, MoreIcon, ShareIcon, UndoIcon, StarIcon, StarEmptyIcon, Button, defaultTheme, ListIcon, IconButton, TagIcon } from 'evergreen-ui';
import UiStore from '../../../../stores/UiStore';
import NotesStore from '../../../../stores/NotesStore';
import MoveToDialog from '../../../../dialogs/MoveToDialog';
import ImportDialog from '../../../../dialogs/ImportDialog';
import WaitDialog from '../../../../dialogs/WaitDialog';
import { useHistory } from 'react-router-dom';
import NoteOptionsDialog from 'app/dialogs/NoteOptionsDialog';
import NoteShareDialog from 'app/dialogs/NoteShareDialog';
import ProjectsStore from 'app/stores/ProjectsStore';
import translate from 'app/i18n';

interface Props {
  notes?: NotesStore,
  ui?: UiStore,
  projects?: ProjectsStore
}

const NoteMenu = (observer((props: Props) => {
  const { ui, notes, projects } = props;
  const history = useHistory();
  
  const {common, noteMenu, noteOptions} = translate();

  const handleNewPage = (close: () => void) => {

    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      if(ui.currentNote?.deleted || projects.isReadonlyUser) return;

      ui?.showDialog(<WaitDialog text={noteMenu.creatingPage} />);

      try {
        const newNote = await notes.create({
          parent_id: ui.currentNote.id,
          text: "\n\\",
          title: "Untitled",
          variant_id: ui.currentNote.variantId,
          is_draft: true,
          type: "text"
        });
        ui.hideDialog();
        history.push(`/notes/${newNote.id}`);
      } catch (e) {
        console.error(e);
        ui.hideDialog();
        toaster.danger(`${common.errorMessage}: ${e.message}`);
      }
    }
  }

  const handleDelete = (close: () => void) => {

    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      if(projects.isReadonlyUser) return;


      ui.currentNote.setDeleted(true);
      await notes.softDelete(ui.currentNote.id);
      notes.info({id: ui.currentNote.id});
    
    }
  }

  const handleDuplicate = (close: () => void) => {

    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      if(ui.currentNote?.deleted || projects.isReadonlyUser) return;

      ui?.showDialog(<WaitDialog text={noteMenu.creatingDuplicate} />);
      try {
        const duplicate = await notes.duplicate(ui.currentNote.id);
        ui.hideDialog();
        history.push(`/notes/${duplicate.id}`);
      } catch (e) {
        console.error(e);
        ui.hideDialog();
        toaster.danger(`${common.errorMessage}: ${e.message}`);
      }
    }
  }

  const handleMoveTo = (close: () => void) => {
    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      if(ui.currentNote?.deleted || projects.isReadonlyUser) return;

      ui.showDialog(<MoveToDialog note={ui.currentNote} />);
    }
  }

  const handleShowMore = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if(ui.currentNote?.deleted || projects.isReadonlyUser) return;

    ui.showDialog(<NoteOptionsDialog note={ui.currentNote} />);
  }

  const handleImport = (close: () => void) => {
    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      if(ui.currentNote?.deleted || projects.isReadonlyUser) return;

      ui.showDialog(<ImportDialog note={ui.currentNote} />);
    }
  }

  const handleExportToPdf = (close: () => void) => {

    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      if(ui.currentNote?.deleted) return;

      ui.showDialog(<WaitDialog text={noteMenu.generatingPdf} />);

      try {
        const { path } = await notes.pdf(ui.currentNote.id);
        window.open(`${location.origin}/${path}`, '_blank');
      } catch (e) {
        toaster.danger(noteMenu.pdfGeneratingError);
      } finally {
        ui.hideDialog();
      }
    }
  }

  const handleRestore = (close: () => void) => {
    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();

      try {
        await notes.restore(ui.currentNote.id);
        notes.info({id: ui.currentNote.id});
      } catch (e) {
        console.error(e);
        toaster.danger(`${common.errorMessage}: ${e.message}`);
      }
    }
  }

  const handleMakeFavorite = (close: () => void) => {
    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      close();
      if(ui.currentNote?.deleted) return;

      ui.currentNote.setFavorite(!ui.currentNote.isFavorite);
      await notes.favorite(ui.currentNote.id);
    }
  }

  const handleTOCChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    ui.currentNote?.setTableOfContents(!ui.currentNote?.isTableOfContents);
    notes.update({id: ui.currentNote?.id, isTableOfContents: ui.currentNote?.isTableOfContents})
  }

  const renderMenu = (close: () => void) => {
    return <Observer>{
      () => (
      <Menu>
        <Menu.Group>
          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} height={32} icon={ListIcon} onClick={handleTOCChange}>
            <Pane display="flex" flex="1" justifyContent="space-between">
              <Text>{noteOptions.tableOfContents}</Text>
              <Switch disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} checked={ui.currentNote?.isTableOfContents} height={20} marginRight={5} onChange={handleTOCChange}/>
            </Pane>
          </Menu.Item>
        </Menu.Group>
        <Menu.Divider />

        <Menu.Group>
          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} height={32} icon={InheritanceIcon} onClick={handleNewPage(close)}>{noteMenu.newChildPage}</Menu.Item>
          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} height={32} icon={DuplicateIcon} onClick={handleDuplicate(close)}>{noteMenu.duplicate}</Menu.Item>
          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} height={32} icon={ArrowRightIcon} onClick={handleMoveTo(close)}>{noteMenu.moveToAnotherVariant}</Menu.Item>

          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted} height={32} icon={ui.currentNote?.isFavorite ? StarIcon : StarEmptyIcon} onClick={handleMakeFavorite(close)}>
            {ui.currentNote?.isFavorite ? noteMenu.removeFromFavorites : noteMenu.addToFavorites}
          </Menu.Item>

          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} height={32} icon={TagIcon} onClick={handleShowMore}>
            {noteMenu.more}
          </Menu.Item>
        </Menu.Group>

        <Menu.Divider />
        <Menu.Group>
          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted || projects.isReadonlyUser} height={32} icon={ImportIcon} onClick={handleImport(close)}>{noteMenu.import}</Menu.Item>
          <Menu.Item disabled={!ui.currentNote || ui.currentNote?.deleted} height={32} icon={DocumentShareIcon} onClick={handleExportToPdf(close)}>{noteMenu.exportAsPDF}</Menu.Item>
        </Menu.Group>

        <Menu.Divider />
        <Menu.Group>
        {ui.currentNote?.deleted && <Menu.Item height={32} intent="success" icon={UndoIcon} onClick={handleRestore(close)}>{noteMenu.restore}</Menu.Item>}
        {ui.currentNote?.deleted === false && <Menu.Item disabled={!ui.currentNote || projects.isReadonlyUser} height={32} intent="danger" icon={TrashIcon} onClick={handleDelete(close)}>{noteMenu.delete}</Menu.Item>}
        </Menu.Group>

        {!ui.currentNote && <Menu.Item></Menu.Item>}
      </Menu>)}
    </Observer>
  }

  return (
    <Popover
      shouldCloseOnExternalClick={true}
      minWidth={270}
      position={Position.BOTTOM_LEFT}
      content={({close}) => renderMenu(close)}
    >
      <IconButton style={{ color: defaultTheme.colors.text.default }} appearance="minimal" icon={MoreIcon} />
    </Popover>
  )
}));

export default inject('notes', 'ui', 'projects')(NoteMenu);