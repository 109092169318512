import * as React from 'react'
import { inject, observer } from 'mobx-react';
import AuthStore from '../stores/AuthStore';

interface Props {
  auth?: AuthStore,
  children: React.ReactChild
}

@inject('auth')
@observer
class Authenticated extends React.Component<Props> {

  rootUrl() {
    return `${location.protocol}//${location.host.replace("app.", "")}/`
  }

  render() {
    const {auth, children} = this.props;

    if (auth.authenticated){
      return children;
    }else{
      window.location.replace(this.rootUrl());
      return <></>;
    }
  }
}

export default Authenticated;
