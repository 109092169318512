import translate from 'app/i18n';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import BillingForm from '../../components/Settings/components/Billing';
import Layout from './Layout';

interface MatchParams {
  tab?: string
}

interface Props extends RouteComponentProps<MatchParams>{
}

const Billing = observer((props: Props) => {
  const {match} = props;
  const {billingScreen} = translate();

  return <Layout header={billingScreen.title}>
    <BillingForm tab={match.params.tab}/>
  </Layout>
});

export default Billing;