import { defaultTheme } from "evergreen-ui";
import styled from "styled-components";
import Box from "ui-box";

const YellowBox = styled(Box)`
  background-color: ${defaultTheme.colors.background.yellowTint};
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 5%), 0 0 20px 0 rgb(0 0 0 / 15%);
`

export default YellowBox;
