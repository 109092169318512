import { action, makeObservable, observable, IObservableArray, set, runInAction, computed } from "mobx";
import { deserialize } from "serializr";
import BaseModel from "../models/BaseModel";
import { client, IResponse } from "../utils/ApiClient";
import { Type } from "../utils/Type";
import RootStore from "./RootStore";

export default class BaseStore<T extends BaseModel> {
  data: IObservableArray<T> = observable<T>([]);
  model: Type<T>;
  modelName: string;
  isLoaded: boolean = false;
  isUpdating: boolean = false;

  constructor(protected readonly rootStore: RootStore, model: Type<T>, modelName: string){
    makeObservable(this, {
      reversed: computed,
      data: observable,
      add: action,
      update: action,
      list: action,
      create: action,
      delete: action,
      clear: action,
      replace: action,
      isLoaded: observable,
      isUpdating: observable
    });

    this.model = model;
    this.modelName = modelName;
  }

  clear() {
    runInAction(() => this.isLoaded = false);
    this.data.clear();
  }

  async delete(id: string): Promise<IResponse> {
    runInAction(() => this.data.replace(this.data.filter(x => x.id !== id)));

    return client.post(`${this.modelName}s.delete`, {id: id});
  }

  async create(params: Object | FormData): Promise<T> {
    if (!params) return
    let res: IResponse = null;

    if (params instanceof FormData){
      res = await client.sendFile(`${this.modelName}s.create`, params);
    }else{
      res = await client.post(`${this.modelName}s.create`, params);
    }

    return this.add(res.data);
  }

  async info(params: Object = {}): Promise<T> {
    if (!params) return
    const res = await client.post(`${this.modelName}s.info`, params);

    return this.add(res.data);
  }

  add(source: Object | T): T {
    const Model = this.model;

    const newItem = source instanceof BaseModel ? source : deserialize(Model, source);

    const exists = this.data.find(x => x.id === newItem.id);

    if(exists){
      set(exists, newItem);
      return exists;
    }

    this.data.push(newItem)

    return newItem;
  }

  async update(params: Object | FormData): Promise<T>{
    if (!params) return
    runInAction(() => this.isUpdating = true);
    const res = await client.post(`${this.modelName}s.update`, params);
    runInAction(() => this.isUpdating = false);
    return this.add(res.data);
  }

  async updateWithoutAdd(params: Object | FormData): Promise<T>{
    if (!params) return
    runInAction(() => this.isUpdating = true);
    const res = await client.post(`${this.modelName}s.update`, params);
    runInAction(() => this.isUpdating = false);
    return res.data;
  }

  async list(params: Object = {}): Promise<Array<T>>{
    const res = await client.post(`${this.modelName}s.list`, params);
    runInAction(() => {
      this.data.clear();
      this.isLoaded = true
    });
    return this.replace(res.data);
  }

  replace(items: Object[] | T[]): Array<T> {
    set(this.data, []);
    items.forEach(item => this.add(item));
    return this.data;
  }

  get reversed(){
    return this.data.slice().reverse();
  }
}