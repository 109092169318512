import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import Activity from "../models/Activity";
import { runInAction } from "mobx";
import { client, IResponse } from "../utils/ApiClient";

export default class ActivitiesStore extends BaseStore<Activity> {
  constructor(protected readonly rootStore: RootStore){
    super(rootStore, Activity, "activitie");
  }

  async loadMore(cursorId: string): Promise<IResponse>{
    const res = await client.post(`${this.modelName}s.list`, {cursorId: cursorId});
    runInAction(() => {
      this.isLoaded = true
      res.data.forEach(x => this.add(x));
    });

    return res;
  }
}