import ProjectsStore from 'app/stores/ProjectsStore';
import { Text, Pane, Strong, defaultTheme, DocumentIcon } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Note from '../../models/Note';
import UiStore from '../../stores/UiStore';

interface Props {
  projects?: ProjectsStore,
  note: Note,
  isSelected: boolean,
  onClick?: (note: Note) => void
}

const Item: React.FC<Props> = ({note, projects, isSelected, onClick}) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if(isSelected){
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: 'center'
      });
    }
  }, [isSelected]);

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(note)
  }

  return <Wrapper ref={ref} paddingY={8} borderBottom="default" onClick={handleItemClick} backgroundColor={isSelected ? defaultTheme.colors.background.tint2 : 'transparent'}>
    <Pane display="flex" flex="1" alignItems="flex-start" paddingX={18}>
      <Pane width={20}>
        <DocumentIcon color={defaultTheme.colors.text.muted}/>
      </Pane>
      <Pane display="flex" flex="1" flexDirection="column">
        <Text size={500}>{note.title}</Text>
        <Text size={400} color="muted">{projects.data.find(x => x.id === note.projectId)?.name}</Text>
      </Pane>
    </Pane>
  </Wrapper>
}

const Wrapper = styled(Pane)`
  cursor: pointer;
  &:hover{
    background-color: ${defaultTheme.colors.background.tint2};
  }
`


export default inject('projects')(observer(Item));