import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { defaultTheme, Heading, Pane, Text, toaster} from 'evergreen-ui';
import ProjectsStore from 'app/stores/ProjectsStore';
import Sidebar from 'app/components/Sidebar';
import Form, { State } from 'app/components/Project/components/Form';
import { useHistory } from 'react-router-dom';
import translate from 'app/i18n';
import { runInAction } from 'mobx';
import FadeIn from 'app/components/FadeIn';

interface Props {
  projects?: ProjectsStore
}

const Welcome = observer((props: Props) => {
  const { projects } = props;
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();

  const {common, welcomeScreen} = translate();

  const sidebarWidth = 240;

  React.useEffect(() => {
    document.title = "Wannadocs - Welcome";
  }, []);

  const handleSubmit = async (formState: State) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", formState.name);
    if(formState.logo) { formData.append("logo", formState.logo); }
    formData.append("primaryColor", formState.primaryColor);

    try{
      const newProject = await projects.create(formData);
      projects.setCurrent(newProject);
      runInAction(() => projects.noProjects = false);

      setLoading(false);
      history.push('/home');
    }catch(e){
      console.error(e);
      toaster.danger(common.errorMessage);
      setLoading(false);
    }
  }

  return (<>
    <Pane display="flex" flex="1" flexDirection="column">
      <Pane display="flex" flex="1">
        <Pane width={sidebarWidth} position="fixed" minHeight="100vh" flexDirection="column" backgroundColor={defaultTheme.colors.background.blueTint}>
          <Sidebar showPlaceholder={true}/>
        </Pane>
        <Pane display="flex" flex="1" marginLeft={sidebarWidth} padding={10}>
          <FadeIn>
            <Pane display="flex" flex="1" justifyContent="center" alignItems="center" marginTop={100} flexDirection="column">
              <Heading size={900}> 👋 {welcomeScreen.title}</Heading>
              <Text marginTop={16} size={600}>{welcomeScreen.subtitle}</Text>

              <Pane marginTop={24} maxWidth={600} width="100%">
                <Form isLoading={isLoading} onSubmit={handleSubmit}/>
              </Pane>
            </Pane>
          </FadeIn>
        </Pane>
      </Pane>
    </Pane>
    </>
  )
});

export default inject('projects')(Welcome);
