import BaseModel from "./BaseModel";
import {
  date,
  serializable
} from "serializr"
import { makeObservable, observable } from "mobx";

export default class Activity extends BaseModel {
  @serializable(date())
  createdAt: Date = null;

  @serializable
  action: string = null;
  
  @serializable
  actionableType: string = null;

  @serializable
  actionableId: string = null;

  @serializable
  actionableTitle: string = null;

  @serializable
  actionableLink: string = null;

  @serializable
  userName: string = null;

  @serializable
  userPhotoUrl: string = null;
  
  constructor(){
    super();

    // makeObservable(this, {
    //   createdAt: observable,
    //   action: observable,
    //   actionableType: observable,
    //   actionableId: observable,
    //   actionableTitle: observable,
    //   actionableLink: observable,
    //   userName: observable,
    //   userPhotoUrl: observable
    // })
  }
}