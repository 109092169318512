import { action, IObservableArray, makeObservable, observable, ObservableSet, runInAction } from "mobx";
import { deserialize } from "serializr";
import FeedbackCount from "../models/FeedbackCount";
import ViewCount from "../models/ViewCount";
import { client, IResponse } from "../utils/ApiClient";
import RootStore from "./RootStore";

export default class AnalyticsStore {
  popularViews: IObservableArray<ViewCount> = observable<ViewCount>([]);
  dislikeFeedbacks: IObservableArray<ViewCount> = observable<FeedbackCount>([]);
  neutralFeedbacks: IObservableArray<ViewCount> = observable<FeedbackCount>([]);
  likeFeedbacks: IObservableArray<ViewCount> = observable<FeedbackCount>([]);

  constructor(private readonly rootStore: RootStore){
    makeObservable(this, {
      popularViews: observable,
      loadPopularViews: action,
    });
  }

  async loadPopularViews(interval: string): Promise<IResponse> {
    const res = await client.post("analytics.views.popular", {interval: interval});
    runInAction(() => {
      this.popularViews.replace(res.data.map(x => deserialize(ViewCount, x)))
    });

    return res;
  }

  async loadDislikeFeedbacks(interval: string): Promise<IResponse> {
    const res = await client.post("analytics.feedbacks.dislikes", {interval: interval});
    runInAction(() => {
      this.dislikeFeedbacks.replace(res.data.map(x => deserialize(FeedbackCount, x)))
    });

    return res;
  }

  async loadNeutralFeedbacks(interval: string): Promise<IResponse> {
    const res = await client.post("analytics.feedbacks.neutrals", {interval: interval});
    runInAction(() => {
      this.neutralFeedbacks.replace(res.data.map(x => deserialize(FeedbackCount, x)))
    });

    return res;
  }

  async loadLikeFeedbacks(interval: string): Promise<IResponse> {
    const res = await client.post("analytics.feedbacks.likes", {interval: interval});
    runInAction(() => {
      this.likeFeedbacks.replace(res.data.map(x => deserialize(FeedbackCount, x)))
    });

    return res;
  }
}