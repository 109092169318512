import AuthStore from "./AuthStore";
import InvitesStore from "./InvitesStore";
import NotesStore from "./NotesStore";
import ProjectStore from "./ProjectsStore";
import UiStore from "./UiStore";
import VariantsStore from "./VariantsStore";
import NotesTreeStore from "./NotesTreeStore";
import IntegrationsStore from "./IntegrationsStore";
import ActivitiesStore from "./ActivitiesStore";
import AnalyticsStore from "./AnalyticsStore";
import RecentsStore from "./RecentsStore";
import SummaryStore from "./SummaryStore";
import PurchasesStore from "./PurchasesStore";

export default class RootStore {
  auth: AuthStore
  projects: ProjectStore
  ui: UiStore
  notes: NotesStore
  variants: VariantsStore
  invites: InvitesStore
  notesTree: NotesTreeStore
  integrations: IntegrationsStore
  activities: ActivitiesStore
  analytics: AnalyticsStore
  recents: RecentsStore
  summary: SummaryStore
  purchases: PurchasesStore

  constructor() {
    this.auth = new AuthStore(this);
    this.projects = new ProjectStore(this);
    this.ui = new UiStore(this);
    this.notes = new NotesStore(this);
    this.variants = new VariantsStore(this);
    this.invites = new InvitesStore(this);
    this.notesTree = new NotesTreeStore(this);
    this.integrations = new IntegrationsStore(this);
    this.activities = new ActivitiesStore(this);
    this.analytics = new AnalyticsStore(this);
    this.recents = new RecentsStore(this);
    this.summary = new SummaryStore(this);
    this.purchases = new PurchasesStore(this);
  }
}