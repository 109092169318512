import * as React from 'react';
import ProjectsStore from 'app/stores/ProjectsStore';
import { inject, observer } from 'mobx-react';
import {
  Button,
  defaultTheme,
  Label,
  Pane,
  RepeatIcon,
  ShareIcon,
  Strong,
  Switch,
  Text,
  Textarea,
  TextInput,
  toaster
} from 'evergreen-ui';
import Panel from 'app/components/Panel';
import { Link } from 'react-router-dom';
import translate from 'app/i18n';
import { MinimalButton, PrimaryButton } from 'app/components/Typography';

interface Props {
  isShown: boolean,
  onClick?: () => void,
  projects?: ProjectsStore
}

const RestrictIpPanel = observer((props: Props) => {
  const {projects, isShown, onClick} = props;

  const [enabled, setEnabled] = React.useState(false);
  const [ips, setIps] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const {restrictAccessIp, common} = translate();

  React.useEffect(() => {
    if(projects.current) {
      setEnabled(projects.current.restrictAccessIpsEnabled || false);
      setIps(projects.current.restrictAccessIps || '');
    }
  }, [projects.current]);

  const handleToggleChanged = () => {
    setEnabled(!enabled);
  }

  const handleIpsChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIps(e.currentTarget.value);
  }

  const handleSubmitChanges = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);
    try{
      await projects.setRestrictAccessIp(enabled, ips);
      toaster.success(common.updatedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setSaving(false);
    }
  };

  return <form onSubmit={handleSubmitChanges}>
    <Panel previewValue={<Switch disabled={projects.isReadonlyUser} onChange={handleToggleChanged} checked={enabled}/>}
                  header={<Strong>{restrictAccessIp.title}</Strong>} isShown={isShown} onClick={onClick}
                  footer={<PrimaryButton disabled={projects.isReadonlyUser} type="submit" appearance="primary" isLoading={saving}>{common.btnSaveChanges}</PrimaryButton>}>
      
      {projects.current && <Pane display="flex" marginY={12}>
        <Pane display="flex" marginY={12} flexDirection="column" width="100%">
          <Label marginBottom={5}>{restrictAccessIp.ips}</Label>
          <Pane display="flex" width="100%">
            <Textarea disabled={projects.isReadonlyUser} value={ips} onChange={handleIpsChange} placeholder={restrictAccessIp.ipsPlaceholder} required width="100%"/>
          </Pane>
        </Pane>
      </Pane>}

      <Text>{restrictAccessIp.descripton}</Text>
    </Panel>
  </form>
});

export default inject('projects')(RestrictIpPanel);