import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { Pane, SearchIcon, HistoryIcon, VerticalBarChartAscIcon, TrashIcon, CogIcon, BoxIcon, Button, ShareIcon, defaultTheme, Avatar, Heading, Tooltip, PersonIcon, NewPersonIcon, ConsoleIcon, CreditCardIcon, ArchiveIcon, Text, Position, ExpandAllIcon, Badge, ManualIcon, HelpIcon } from 'evergreen-ui';
import SidebarLink from './components/SidebarLink';
import UiStore from '../../stores/UiStore';
import SearchDialog from '../../dialogs/SearchDialog';
import ProjectsStore from '../../stores/ProjectsStore';
import SidebarSection from './components/SidebarSection';
import RecentsStore from '../../stores/RecentsStore';
import { useRouteMatch } from "react-router-dom";
import styled from 'styled-components';
import translate from '../../i18n';
import ProjectsMenu from 'app/menus/ProjectsMenu';
import application from "../../components/Application";
import { ExternalLink } from 'react-feather';

interface Props {
  ui?: UiStore,
  recents?: RecentsStore,
  projects?: ProjectsStore,
  showPlaceholder?: boolean
}

const Sidebar: React.FC<Props> = ({ui, recents, projects, showPlaceholder}) => {
  const settingsMatch = useRouteMatch("/settings/*");

  const {sidebar, searchDialog, accountMenu, personalSettingsDialog} = translate();

  const handleSearchClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    ui.setShowSearchDialog(true);
  }

  const handleOpenSupportWidget = (e: React.SyntheticEvent) => {
    e.preventDefault();

    (window as any).Chatra('show');
    (window as any).Chatra('expandWidget', true);
  }

  const renderRealMenu = () => {
    return <Pane paddingTop={12}>
      
      <ProjectsMenu />

      <Pane paddingTop={12}>
        <SidebarLink icon={<SearchIcon size={14}/>} afterIcon={<Badge textTransform="none" color="neutral" marginX={3}>{application.modKey()} + k</Badge>} label={searchDialog.search} url="/search" routeMatch={false} onClick={handleSearchClick}/>
        <SidebarLink icon={<ExternalLink size={14}/>} label={sidebar.openKB} url={projects.current?.domain} routeMatch={false} />
      </Pane>

      <Pane paddingTop={12}>
        <SidebarLink icon={<BoxIcon size={14}/>} label={sidebar.content} url="/home" routeMatch={true}/>
        <SidebarLink icon={<HistoryIcon size={14}/>} label={sidebar.allUpdates} url="/updates" routeMatch={true}/>
        <SidebarLink icon={<VerticalBarChartAscIcon size={14}/>} label={sidebar.analytics} url="/analytics" routeMatch={true}/>
        <SidebarLink icon={<TrashIcon size={14}/>} label={sidebar.trash} url="/trash" routeMatch={true}/>
        <SidebarLink icon={<CogIcon size={14}/>} label={sidebar.settings} url="/settings/project" highlight={settingsMatch !== null}/>

        {settingsMatch && <Pane marginTop={6}>
        <SidebarLink icon={<></>} label={sidebar.projectSettings} url="/settings/project" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={personalSettingsDialog.title} url="/settings/personal" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={sidebar.members} url="/settings/members" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={sidebar.invitations} url="/settings/invitations" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={sidebar.integrations} url="/settings/integrations" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={sidebar.billing} url="/settings/billing" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={sidebar.backup} url="/settings/backup" highlightType="borderLeft" routeMatch={true}/>
        <SidebarLink icon={<></>} label={sidebar.share} url="/settings/share" highlightType="borderLeft" routeMatch={true}/>
        </Pane>}
      </Pane>
      

      <Pane paddingTop={12}>
        <SidebarLink icon={<ManualIcon size={14}/>} label={accountMenu.documentation} url="https://demo.wannadocs.com" routeMatch={false} />
        <SidebarLink icon={<HelpIcon size={14}/>} label={accountMenu.contactSupport} url="/#" routeMatch={false} onClick={handleOpenSupportWidget}/>
      </Pane>

      {recents.all.length > 0 && <Pane paddingTop={15}>
        <SidebarSection label={sidebar.recent} />

        {recents.all.map(x => <SidebarLink key={x.id} label={x.title} url={`/notes/${x.id}`}/>)}
      </Pane>}

      {/* WIP: Guide */}
      {/* <Pane display="flex" justifyContent="center" alignContent="center" cursor="pointer" border="default" width={200} height={200} marginLeft={14} bottom={80} position="absolute" backgroundColor="white" borderRadius={3}>
        <Text position="absolute" right={0} top={-25} backgroundColor={defaultTheme.colors.text.muted} color="white" borderRadius={3} paddingX={3}>close</Text>
        <video autoPlay loop playsInline width={190} height={190}>
          <source src="/videos/editor-menu.mp4" type="video/mp4"/>
        </video>
      </Pane> */}
    </Pane>
  }

  const renderPlaceholdersMenu = () => {
    return <Pane paddingTop={12}>
      <Pane display="flex" justifyContent="flex-start" title={sidebar.openKB} textDecoration="none">
        <ProjectWrapper backgroundColor="#f0f3f9" flex={1} display="flex" alignItems="center" marginX={14} padding={7} borderRadius={5} height={54}>
          
        </ProjectWrapper>
      </Pane>

      <Pane padding={12}>
        <SidebarLinkPlaceholder width="70%"/>
        <SidebarLinkPlaceholder width="85%"/>
        <SidebarLinkPlaceholder width="85%"/>
        <SidebarLinkPlaceholder width="80%"/>
        <SidebarLinkPlaceholder width="60%"/>
      </Pane>
    </Pane>
  }

  return showPlaceholder ? renderPlaceholdersMenu() : renderRealMenu();
}

const SidebarLinkPlaceholder = styled(Pane)`
  display: flex;
  align-items: center;
  min-height: 27px;
  padding: 2px 14px;
  background: #f0f3f9;
  transition: background 20ms ease-in 0s;
  text-decoration: none;
  margin-bottom: 8px;
`

const ProjectWrapper = styled(Pane)`
  position: relative;

  &:hover{
    background-color: ${defaultTheme.colors.border.default};
  }
`

export default inject('ui', 'recents', 'projects')(observer(Sidebar));
