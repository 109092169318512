import { action, autorun, computed, IObservableArray, makeObservable, observable, reaction, toJS, transaction } from "mobx";
import { deserialize, serialize } from "serializr";
import Recent from "../models/Recent";
import RootStore from "./RootStore";

const MAX_SIZE = 100;
const STORE_NAME = "RecentsStore";

export default class RecentsStore {
  items: IObservableArray<Recent> = observable<Recent>([]);

  constructor(private readonly rootStore: RootStore){
    makeObservable(this, {
      items: observable,
      all: computed,
      add: action
    });

    // Rehydrate
    let data = [];
    try {
      data = JSON.parse(localStorage.getItem(STORE_NAME) || "[]");
    } catch (_) {
      // Safari private mode
    }

    if(data.length > 0){
      try{
        this.items.replace(data.map(x => deserialize(Recent, x)));
      }catch(e){
        console.log(e);
      }
    }

    autorun(() => {
      const data = this.items.map(x => serialize(Recent, toJS(x)));

      if(data.length > 0){
        try {
          localStorage.setItem(STORE_NAME, JSON.stringify(data.splice(0, MAX_SIZE)));
        } catch (_) {
          // Safari private mode
        }
      }
    });
  }

  add(item: Recent){
    transaction(() => {
      if(this.items.find(x => x.id === item.id)){
        this.items.replace(this.items.filter(x => x.id !== item.id));
      }
  
      this.items.unshift(item);
    });
  }

  get all(): Recent[] {
    return this.items.filter(x => x.projectId === this.rootStore.projects.current?.id).splice(0, 5);
  }
}