import * as React from 'react'
import { Switch, Route } from "react-router-dom";
import Updates from '../screens/Updates';
import Home from '../screens/Home';
import Join from '../screens/Join';
import Note from '../screens/Note';
import Analytics from '../screens/Analytics';
import * as Settings from '../screens/Settings';
import Layout from '../components/Layout';
import Trash from 'app/screens/Trash';
import Welcome from 'app/screens/Welcome';

const AuthenticatedRoutes: React.FC<{}> = ({}) => {
  return <Switch>
      <Route exact={true} path={"/welcome"} component={Welcome}/>
      <Layout>
        <Route exact={true} path={"/home"} component={Home}/>
        <Route exact={true} path={"/home/:variantId"} component={Home}/>
        <Route exact={true} path="/notes/:id" component={Note}/>
        <Route exact={true} path="/updates" component={Updates}/>
        <Route exact={true} path="/trash" component={Trash}/>
        <Route exact={true} path="/analytics" component={Analytics}/>
        <Route exact={true} path="/join/:id" component={Join}/>
        <Route exact={true} path="/settings/personal" component={Settings.Personal}/>
        <Route exact={true} path="/settings/project" component={Settings.Project}/>
        <Route exact={true} path="/settings/members" component={Settings.Members}/>
        <Route exact={true} path="/settings/invitations" component={Settings.Invitations}/>
        <Route exact={true} path="/settings/integrations" component={Settings.Integrations}/>
        <Route exact={true} path="/settings/billing/:tab?" component={Settings.Billing}/>
        <Route exact={true} path="/settings/backup" component={Settings.Backup}/>
        <Route exact={true} path="/settings/share" component={Settings.Share}/>
        {/* <Route component={NotFound} /> */}
      </Layout>
    </Switch>
}

export default AuthenticatedRoutes