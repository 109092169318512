import ITranslation from "./ITranslation";
import ruStrings from 'react-timeago/lib/language-strings/ru'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const ru: ITranslation = {
  timeAgo: buildFormatter(ruStrings),
  common: {
    updatedMessage: 'Обновлено',
    deletedMessage: 'Удалено',
    savedMessage: 'Сохранено',
    completedMessage: 'Выполнено',
    errorMessage: 'Ошибка при выполнении операции',
    btnSaveChanges: 'Сохранить изменения',
    btnSave: 'Сохранить',
    btnCancel: 'Отменить',
    btnUpdate: 'Обновить',
    btnCreate: 'Создать',
    btnDelete: 'Удалить',
    btnBuy: "Купить",
    loading: 'Загрузка...',
    filterPlaceholder: 'Фильтр...',
    noResults: 'Нет результатов',
    project: 'Проект',
    pagesNotFound: 'Страницы не найдены',
    noDataForGivenPeriod: 'Нет данных за выбранный период',
    noInvitesPending: 'Нет приглашений ожидающих подтверждения',
    codeHere: 'Код...',
    public: 'Публичная',
    private: 'Скрытая',
    published: 'Публичная',
    email: 'Почта',
    saved: 'Сохранено',
    saving: 'Сохранение',
    haveOverduePayment: 'Здравствуйте, хотим напомнить вам, что у вас просроченный платеж за лицензию',
    or: "или",
    loggedAsReadOnlyAccount: 'Вы вошли под аккаунтом только для чтения некоторые функции будут недоступны',
  },
  projectMenu: {
    paid: "Платный",
    trial: "Триал",
    member: "участник",
    members: "участники"
  },
  backlinks: {
    title: "обратная ссылка",
    pluralTitle: "обратные ссылки"
  },
  authForm: {
    failed: 'Ошибка аутентификации - нам не удалось войти в систему в это время. Пожалуйста, попробуйте ещё раз.',
    continueWith: 'Войти через',
    enterYourEmail: 'Почта',
    weJustSentYouLoginLink: 'Временная ссылка для входа отправлена на указанную вами почту. Пожалуйста, проверьте почтовый ящик.',
  },
  forgotScreen: {
    title: 'Забыли пароль?',
    documentTitle: 'Забыли пароль? – Wannadocs',
    dontHaveAnAccount: 'У вас еще нет учетной записи?'
  },
  signupScreen: {
    title: 'Регистрация',
    documentTitle: 'Регистрация – Wannadocs',
    alreadyHaveAnAccount: 'Уже есть аккаунт?'
  },
  loginScreen: {
    title: 'Аутентификация',
    documentTitle: 'Аутентификация – Wannadocs',
  },
  newProject: {
    title: 'Создать проект'
  },
  backupScreen: {
    title: 'Резервное копирование',
  },
  backupForm: {
    title: 'Резервное копирование',
    subtitle1: 'Резервное копирование всего проекта может занять некоторое время.',
    subtitle2: 'Все страницы и изображения будут упакованы в zip-архив и отправлены вам на почту. Укажите почту на которую отправить резервную копию:',
    beingCreated: 'Запущено резервное копирование',
    btnExport: 'Запросить резервную копию'
  },
  importDialog: {
    title: 'Импорт',
    importContentFrom: 'Импортировать страницу из',
    uploadYourNotesAs: 'Загрузить страницу как'
  },
  noteOptions: {
    title: 'Дополнительно',
    subtitle: 'Здесь вы можете управлять дополнительными настройками, отредактировать описание и теги страницы.',
    slug: 'Ссылка',
    refreshSlugFromTitle: 'Обновить ссылку из заголовка',
    openArticle: 'Открыть статью',
    description: 'Мета-тег Description',
    descriptionPlaceholder: 'От 70 до 155 символов',
    keywords: 'Мета-тег Keywords',
    keywordsPlaceholder: 'Введите ключевое слово и нажмите Enter',
    tableOfContents: 'Оглавление',
    btnApplyChangesAndClose: 'Сохранить и закрыть'
  },
  noteForm: {
    thisPageIsInTrash: 'Страница была удалена. Вы все ещё можете восстановить её из Корзины',
    giveYourNoteTitle: 'Дайте странице название',
    resumeLastEditingSession: 'Возобновить последний сеанс редактирования?',
    thereWereUnsavedChanges: 'Найдены несохраненные изменения из вашего последнего сеанса редактирования. Вы можете восстановить или отменить их.',
    sessionFrom: 'Сессия от',
    editorStartWriting: 'Нажмите здесь, чтобы начать редактировать'
  },
  moveTo: {
    title: 'Выберите вариант',
    moving: 'Перемещение',
    to: 'в',
    btnMove: 'Переместить'
  },
  noteSharing: {
    title: 'Поделиться',
    subtitle: 'Все, у кого есть ссылка, могут просматривать.',
    copyLink: 'Копировать',
    thisIsA: 'Это',
    searchIndexing: 'страница, поисковая индексация',
    enabled: 'включена',
    disabled: 'отключена'
  },
  noteMenu: {
    creatingPage: 'Создание новой страницы, подождите...',
    creatingDuplicate: 'Создание дубликата, подождите...',
    generatingPdf: 'Создание PDF-файла, подождите...',
    pdfGeneratingError: 'Ошибка создания PDF-файла',
    newChildPage: 'Новая подстраница',
    duplicate: 'Дублировать',
    moveToAnotherVariant: 'Переместить в вариант',
    share: 'Поделиться',
    removeFromFavorites: 'Удалить из избранного',
    addToFavorites: 'Добавить в избранное',
    more: 'Теги и описание',
    import: 'Импорт',
    exportAsPDF: 'Экспорт в PDF',
    restore: 'Восстановить',
    delete: 'Удалить'
  },
  iconSelector: {
    emoji: 'Emoji',
    uploadAnImage: 'Загрузить изображение',
    remove: 'Удалить',
    addIcon: 'Иконка'
  },
  imageFileInput: {
    dropYourFiles: 'Перетащите сюда файл',
    orBrowse: 'или выберите'
  },
  fileUploaderInput: {
    dropYourFiles: 'Перетащите сюда файл',
    orBrowse: 'или выберите',
    processing: 'Обработка...'
  },
  restrictAccess: {
    title: 'Ограничить доступ по паролю',
    password: 'Пароль',
    generate: 'Сгенерировать',
    descripton: 'Содержимое проекта будет видно только тем, у кого есть пароль. Он не будет проиндексирован поисковыми системами.'
  },
  restrictAccessIp: {
    title: 'Ограничить доступ по IP',
    ips: 'IP адреса',
    ipsPlaceholder: 'Введите IP адреса через запятую (198.0.0.1, 0.0.0.0/32, и т.д.)',
    descripton: 'Содержимое проекта будет видно только тем, кто находится в указанных IP диапазонах. Он не будет проиндексирован поисковыми системами.'
  },
  shareScreen: {
    title: 'Доступ'
  },
  shareableLink: {
    title: 'Поделиться ссылкой',
    open: 'Открыть',
    thisProjectIsPublic: 'Ссылкой на проект с публичной видимостью можно поделиться. Любой, у кого есть этот URL-адрес, может прочитать содержимое проекта, но не может его редактировать.',
    copyLink: 'Скопировать ссылку',
    youCanConfigureThisUrl: 'Вы можете настроить этот URL-адрес в',
    thisProjectIsPrivate: 'Данная функция недоступна для проектов со скрытой видимостью. Чтобы поделиться ссылкой на проект измените его видимость на «публичную».'
  },
  visibility: {
    title: 'Видимость проекта',
    textForPublic: 'Содержимое вашего проекта будет доступно всем.',
    textForPrivate: 'Содержимое проекта будет видно только авторизованным пользователям в личном кабинете. Для всех остальных ваш проект будет отображаться с 404 ошибкой (проект не найден)'
  },
  billingScreen: {
    title: 'Оплата',
    licenseActivated: 'Лицензия успешно активирована',
    btnApplyLicense: 'Активировать лицензию',
    active: 'Активная',
    pagesUsage: 'Страницы',
    onThePaidPlanHaveUnlimitedPages: 'В платной подписке проект имеет неограниченное количество страниц.',
    onTheFreePlanHasLimitedPages: 'В пробном периоде проект имеет ограниченное количество страниц.',
    availableStorage: 'Хранилище',
    onThePaidPlanYouImages: 'В платной подписке размер хранилища изображений и вложений ограничен 5 ГБ.',
    onTheFreePlanYouImages: 'В пробном периоде размер хранилища изображений и вложений ограничен до 100 МБ.',
  },
  customCss: {
    title: 'Пользовательские CSS'
  },
  customJs: {
    title: 'Пользовательский Javascript'
  },
  integrationsScreen: {
    title: 'Интеграции'
  },
  intercomChat: {
    title: 'Intercom чат'
  },
  intercomMessenger: {
    title: 'Intercom приложение',
    connected: 'Подключено',
    status: 'Статус',
    reinstall: 'Переустановить через Intercom Marketplace',
    install: 'Установить через Intercom Marketplace'
  },
  invitationsScreen: {
    btnInvite: 'Пригласить участников',
    btnSendInvites: 'Отправить',
    btnResend: 'Отправить',
    title: 'Приглашения',
    addPeopleTo: 'Добавить участников в',
    emails: 'Перечислите получателей через запятую',
    invitesSended: 'Приглашения успешно отправлены',
    invitesResended: 'Приглашение отправлено повторно',
    sendedAt: 'отправлено в',
    pending: 'В ожидании'
  },
  projectSettingsScreen: {
    title: 'Проект'
  },
  projectForm: {
    projectName: 'Название',
    projectNamePlaceholder: 'Моя база знаний',
    homePageUrl: 'Cайт',
    theme: 'Тема оформления',
    logo: 'Логотип',
    favicon: 'Фавикон',
    defaultDomain: 'Сгенерированный домен',
    customDomain: 'Собственный домен',
    color: 'Основной цвет темы оформления',
    dnsTooltip: 'Добавьте запись CNAME в записи DNS вашего домена:',
    storeFrontHome: 'Домашняя страница',
    firstPageHint: 'Первая страница как главная',
    listingPagesHint: 'Список страниц с иконками на главной',
    footerText: 'Текст нижнего колонтитула',
    addMetaTag: 'Добавить мета-тег',
    metaTagsTitle: 'Мета-теги',
  },
  membersScreen: {
    title: 'Участники',
    noMembersFound: 'Участники не найдены',
    filterMembers: 'Фильтр...',
    selectRole: 'Выбрать роль'
  },
  roles: {
    owner: 'Владелец',
    readOnly: 'Только чтение',
    admin: 'Администратор',
    editor: 'Редактор'
  },
  list: {
    empty: 'Пустой список',
    loadMore: 'Загрузить'
  },
  notesTree: {
    noPagesInside: 'Нет страниц',
    private: 'Скрытая',
    toc: 'ОГЛ.',
    edit: 'Редактировать',
    newPage: 'Новая страница',
    delete: 'Удалить'
  },
  createButton: {
    title: 'Создать страницу',
    noteTitle: 'Без заголовка',
    noteBody: '# Добро пожаловать'
  },
  homeScreen: {
    title: 'Контент',
    subtitle: 'Все страницы проекта.',
    filterNotes: 'Фильтр...'
  },
  analyticsScreen: {
    title: 'Аналитика',
    subtitle: 'Аналитика поможет лучше понять ваших пользователей.',
    selectInterval: 'Выберите период',
    intervals: {
      'day': 'За день',
      'week': 'За неделю',
      'month': 'За месяц',
      'six_months': 'За полгода',
      'year': 'За год',
    }
  },
  trashScreen: {
    title: 'Корзина',
    subtitle: 'Страницы попавшие в корзину могут быть восстановлены. Обратите внимание: после 30 дней пребывания в корзине страницы будут безвозвратно удалены.',
    deleted: 'удалена',
    restore: 'Восстановить'
  },
  popularViews: {
    title: 'Популярные страницы',
    page: 'Страница',
    views: 'Просмотры',
  },
  feedbacks: {
    title: 'Отзывы',
    page: 'Страница',
    count: 'Кол-во',
    likes: 'Положительные',
    dislikes: 'Отрицательные',
    neutrals: 'Нейтральные'
  },
  allUpdatesScreen: {
    title: 'Обновления',
    subtitle: 'Список последних обновлений страниц проекта.',
    projectUpdates: 'Обновления проекта'
  },
  dashboardScreen: {
    title: 'Обзор рабочего пространства',
    subtitle: 'Краткое резюме ваших действий во всех доступных вам проектах.',
    recent: 'Недавние',
    workedOn: 'В работе',
    drafts: 'Черновики',
    favorites: 'Избранное',
    noRecentPages: 'Нет недавних страниц',
    thisListWillGrow: 'Этот список будет расти по мере того, как вы создаете, редактируете и исследуете страницы.'
  },
  allUpdatesScreenListItem: {
    actions: {
      'notes.created': 'Создал',
      'notes.deleted': 'Удалил',
      'notes.restored': 'Востановил',
      'notes.updated': 'Обновил',
      'notes.moved': 'Переместил',
      'notes.imported': 'Импортировал',
      'notes.set_logo': 'Установил',
      'notes.remove_logo': 'Удалил',
      'notes.set_visible_status': 'Статус доступности изменен',

      'variants.created': 'Создал',
      'variants.deleted': 'Удалил',
      'variants.updated': 'Обновил',
    },
    types: {
      'note': 'страницу',
      'variant': 'вариант'
    }
  },
  variantDialog: {
    title: 'Вариант',
    newTitle: 'Новый вариант',
    description: 'Вариант – альтернативная версия контента. Например, вы можете использовать варианты для создания базы знаний на разных языках или документирования нескольких версий API в рамках одного проекта.',
    main: 'Основной',
  },
  variantForm: {
    name: 'Название',
    namePlaceholder: 'Например, v2.0.0 или English',
    slug: 'Ссылка',
    slugPlaceholder: 'english, en, etc',
    chooseLang: 'Выберите язык',
    chooseLangPlaceholder: 'Язык',
    setAsMain: 'Установить как основной вариант'
  },
  variantSelectMenu: {
    title: 'Выбрать вариант'
  },
  accountMenu: {
    settings: 'Профиль',
    documentation: 'Документация',
    contactSupport: 'Связаться с поддержкой',
    logout: 'Выйти',
    locale: 'Язык интерфейса'
  },
  personalSettingsDialog: {
    title: 'Профиль',
    photo: 'Аватар',
    fullName: 'Полное имя',
    username: 'Никнейм',
    email: 'Почта',
  },
  sidebar: {
    content: "Контент",
    allUpdates: "Обновления",
    analytics: "Аналитика",
    trash: "Корзина",
    settings: "Настройка",
    recent: "Недавние",
    project: "Проект",
    projectSettings: "Проект",
    members: "Участники",
    invitations: "Приглашения",
    integrations: "Интеграции",
    billing: "Оплата",
    backup: "Резервное копирование",
    share: "Доступ",
    openKB: "Открыть базу знаний"
  },
  header: {
    dashboard: "Главная",
    changeProject: "Сменить проект",
    projects: "Проекты..."
  },
  searchDialog: {
    placeholder: "Поиск по всем проектам...",
    empty: "Ничего не найдено.",
    error: "Произошла ошибка во время поиска:",
    search: "Поиск",
    results: "кол-во",
    select: "Выбрать",
    open: "Открыть"
  },
  deleteArticleDialog: {
    title: "Удалить статью?",
    subtitle: "Вы уверены, что хотите удалить эту статью?"
  },
  welcomeScreen:{
    title: "Добро пожаловать!",
    subtitle: "Для начала работы создайте проект."
  },
  deleteProjectDialog: {
    title: "Удалить проект",
    subtitle: "После удаления проекта его невозможно будет восстановить."
  },
  paywall: {
    title: 'Готовы к покупке?',
    subtitle: 'Похоже, вам нужен другой тарифный план, соответствующий вашим задачам.',
    btnUpgrade: 'Выбрать план!'
  },
  billingQuota: {
    title: 'Квота',
    used: 'Использовано',
    available: 'Доступно',
    usage: 'использование',
    pages: 'Страницы',
    contentVariants: 'Варианты',
    members: 'Участники',
    fileUploads: 'Место под файлы',
    languages: 'Языки',
    unlimited: 'Без лимита'
  },
  purchases: {
    title: 'Покупки',
    createdAt: 'Создан',
    nextBillDate: 'Дата следующей оплаты',
    validThrough: 'Действителен до',
    paid: 'Оплачен',
    getReceipt: 'Получить квитанцию',
    updatePaymentMethod: 'Обновить способ оплаты',
    refund: 'Возврат денег',
    cancelSubscription: 'Отметить подписку',
    wasCanceled: 'Ваша подписка была отменена.',
    complete: 'Покупка завершена успешно, вам нужно подождать несколько минут, для активации',
    statuses: {
      'active': 'Активен',
      'pending': 'Ожидает оплаты',
      'past_due': 'Просрочен',
      'deleted': 'Удален',
    }
  },
  pricingPlans: {
    title: 'Тарифные планы',
    names: {
      'trial': 'Триал',
      'personal_monthly': 'Начальный',
      'personal_yearly': 'Начальный',
      'team_monthly': 'Командный',
      'team_yearly': 'Командный',
      'enterprise': 'Корпоративный',
    },
    twoMonthFree: '1 месяц бесплатно',
    perMonth: 'в месяц',
    yearly: 'в год',
    payAnnually: 'Оплата за год',
    contactSales: 'Написать нам',
    usage: 'Использование',
    pages: 'Страницы',
    contentVariants: 'Варианты',
    members: 'Участники',
    fileUploads: 'Место под файлы',
    languages: 'Языки',
    features: 'Возможности',
    editorBlocksAndEmbeds: 'Все возможности редактора',
    customDomain: 'Собственный домен',
    sslCertificate: 'SSL сертификат',
    analytics: 'Аналитика',
    integrations: 'Интеграции',
    sharePermissions: 'Настройка доступа',
    accessNewFeatureEarly: 'Доступ к новым возможностям раньше',
    themeAdaptation: 'Адаптация шаблона оформления',
    openSource: 'Исходники',
    selfHosted: 'Self-hosted (необязательно)',
    support: 'Поддержка',
    emailAndLiveChat: 'Почта и Live чат',
    prioritySupport: 'Приоритетная поддержка',
    dedicatedManager: 'Выделенный менеджер',
    unlimited: 'Без лимита',
    justYou: 'Только вы',
    languagesCount: 'языков'
  },
  editor: {
    addColumnAfter: "Вставить столбец после",
    addColumnBefore: "Вставить столбец перед",
    addRowAfter: "Вставить строку после",
    addRowBefore: "Вставить строку перед",
    alignCenter: "Выровнять по центру",
    alignLeft: "Выровнять по левому краю",
    alignRight: "Выровнять по правому краю",
    bulletList: "Маркированный список",
    checkboxList: "Список задач",
    codeBlock: "Блок кода",
    codeCopied: "Скопировано в буфер обмена",
    codeInline: "Код",
    createLink: "Создать ссылку",
    createLinkError: "К сожалению, при создании ссылки произошла ошибка",
    createNewDoc: "Создать новый документ",
    deleteColumn: "Удалить столбец",
    deleteRow: "Удалить строку",
    deleteTable: "Удалить таблицу",
    deleteImage: "Удалить изображение",
    downloadImage: "Загрузить изображение",
    alignImageLeft: "Плавающая левая половина ширины",
    alignImageRight: "Размещать на половине ширины вправо",
    alignImageDefault: "По центру",
    em: "Курсив",
    embedInvalidLink: "К сожалению, эта ссылка не работает для этого типа встраивания",
    findOrCreateDoc: "Найти или создать документ...",
    h1: "Большой заголовок",
    h2: "Средний заголовок",
    h3: "Маленький заголовок",
    heading: "Заголовок",
    hr: "Разделитель",
    image: "Изображение",
    imageUploadError: "К сожалению, при загрузке изображения произошла ошибка",
    info: "Информация",
    infoNotice: "Информация",
    link: "Ссылка",
    linkCopied: "Ссылка скопирована в буфер обмена",
    mark: "Выделить",
    newLineEmpty: "Введите '/', чтобы выбрать элемент...",
    newLineWithSlash: "Продолжайте вводить для фильтрации...",
    noResults: "Нет результатов",
    openLink: "Открыть ссылку",
    orderedList: "Упорядоченный список",
    pageBreak: "Разделитель страницы",
    pasteLink: "Вставить ссылку...",
    pasteLinkWithTitle: (title: string): string => `Вставьте ссылку ${title}`,
    placeholder: "Заполнитель",
    quote: "Цитата",
    removeLink: "Удалить ссылку",
    searchOrPasteLink: "Найти или вставить ссылку...",
    strikethrough: "Зачеркнутый",
    strong: "Жирный",
    subheading: "Подзаголовок",
    table: "Таблица",
    tip: "Совет",
    tipNotice: "Совет",
    warning: "Предупреждение",
    warningNotice: "Предупреждение",
    spoiler: "Спойлер",
  }
}

export default ru;
