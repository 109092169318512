import { PrimaryButton } from "app/components/Typography";
import translate from "app/i18n";
import ProjectsStore from "app/stores/ProjectsStore";
import { Button, Heading, Pane, Paragraph, TextInput, toaster } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import AuthStore from "../../../../stores/AuthStore";

interface Props {
  projects?: ProjectsStore
  auth?: AuthStore
}

const Backup = observer((props: Props) => {
  const {projects, auth} = props;

  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const {common, backupForm} = translate();

  React.useEffect(() => {
    setEmail(auth.user?.email || "");
  }, [auth.user?.email]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try{
      setLoading(true)
      await projects.export(email);
      toaster.success(backupForm.beingCreated);
    }catch(e){
      toaster.danger(e.message);
    }finally{
      setLoading(false);
    }
  }

  const handleChangeEmail = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
  }

  return <Pane display="flex" flex="1" background="white" paddingTop={16} flexDirection="column" padding={10}>
    <form onSubmit={handleSubmit}>
      <Heading size={700}>{backupForm.title}</Heading>
      <Paragraph marginTop={21}>
        {backupForm.subtitle1}
        {backupForm.subtitle2}
      </Paragraph>

      <TextInput type="email" name="email" placeholder={common.email} value={email} marginTop={8} onChange={handleChangeEmail}/>

      <Pane marginTop={16}>
        <PrimaryButton appearance="primary" isLoading={loading}>{backupForm.btnExport}</PrimaryButton>
      </Pane>
    </form>
  </Pane>
});


export default inject('projects', 'auth')(Backup);