import { Heading, Pane, Text } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import AuthStore from '../../stores/AuthStore';
import RoutedLink from '../../components/RoutedLink';
import { Redirect } from 'react-router-dom';
import Centered from '../Centered';
import Box from 'ui-box';
import Form from './Form';
import Header from './Header';
import translate from 'app/i18n';

interface Props {
  auth?: AuthStore
}

const Forgot: React.FC<Props> = ({auth}) => {

  const {forgotScreen, signupScreen} = translate(true);

  React.useEffect(() => {
    document.title = forgotScreen.documentTitle;
  }, []);

  if (auth.authenticated){
    return <Redirect to="/home" />
  }else{
    return <Pane display="flex" flex="1" justifyContent="center" flexDirection="column">
        <Header />
        <Pane display="flex" flex="1" justifyContent="center" alignItems="center" marginTop={100}>
          <Box maxWidth={320}>
            <Heading size={900} marginTop="default" marginBottom="1em" fontWeight={700}>{forgotScreen.title}</Heading>
            
            <Form />

            <Box>
              <Text display="block" color="muted">{forgotScreen.dontHaveAnAccount} <RoutedLink href="/signup" color="neutral">{signupScreen.title}</RoutedLink></Text>
            </Box>
          </Box>
        </Pane>
    </Pane>
  } 
}

export default inject('auth')(observer(Forgot));
