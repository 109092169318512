import translate from 'app/i18n';
import { toaster } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { useHistory } from 'react-router-dom';
import Form, { State } from '../components/Project/components/Form';
import ProjectsStore from '../stores/ProjectsStore';
import UiStore from '../stores/UiStore';
import Dialog from './Dialog';

interface Props {
  ui?: UiStore,
  projects? :ProjectsStore,
}

const NewProjectDialog = (observer((props: Props) => {
  const {projects, ui} = props;
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();

  const {common, newProject} = translate();

  const handleSubmit = async (formState: State) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", formState.name);
    if(formState.logo) { formData.append("logo", formState.logo); }
    formData.append("primaryColor", formState.primaryColor);

    try{
      const newProject = await projects.create(formData);
      projects.setCurrent(newProject);
      setLoading(false);
      ui.hideDialog();
      history.replace('/home');
    }catch(e){
      console.error(e);
      toaster.danger(common.errorMessage);
      setLoading(false);
    }
  }

  const handleDialogClose = () => {
    ui.hideDialog();
  }

  return <Dialog onCloseComplete={handleDialogClose} isShown={true} hasFooter={false} title={newProject.title} width={600}>
    <Form isLoading={isLoading} onSubmit={handleSubmit} onCancel={handleDialogClose}/>
  </Dialog>
}));

export default inject('ui', 'projects')(NewProjectDialog);