import { defaultTheme, Heading, Pane, Text } from "evergreen-ui"
import * as React from "react"
import styled from "styled-components"

const Service = ({title, description, img, onClick}: {onClick(e: React.SyntheticEvent), title: string, description: string, img: string}) => {
  return <Wrapper onClick={onClick}>
    <Pane marginX={10}>
      <img width={40} height={40} src={img} alt={title} />
    </Pane>
    <Pane display="flex" flexWrap="wrap">
      <Heading width="100%" size={500}>{title}</Heading>
      <Text width="100%">{description}</Text>
    </Pane>
  </Wrapper>
}

const Wrapper = styled('div')`
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${defaultTheme.colors.text.default};

  &:hover{
    background: ${defaultTheme.colors.background.tint2};
  }
`

export default Service;