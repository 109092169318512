import { defaultTheme, Pane, toaster } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import MarkdownEditor from "@andreyblinov/rich-markdown-editor";
import styled from 'styled-components';
import UiStore from '../../../../stores/UiStore';
import { client } from '../../../../utils/ApiClient';
import EMBEDS from '../embeds';
import translate from 'app/i18n';
import NotesStore from 'app/stores/NotesStore';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import ProjectsStore from 'app/stores/ProjectsStore';

interface Props {
  ui?: UiStore,
  notes?: NotesStore,
  projects?: ProjectsStore
}

const Editor = (observer((props: Props) => {
  const innerRef = React.useRef<MarkdownEditor>(null);
  const {noteForm, editor} = translate();
  const {ui, notes, projects} = props;
  const history = useHistory();

  const handleImageUpload = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);

    try{
      const res = await client.sendFile('uploads.create', formData)
      return res.data.url;
    }catch(e){
      toaster.danger(e.message);
      console.error(e);
      return null;
    }
  }

  const focusAtEnd = () => {
    if (innerRef.current) {
      innerRef.current.focusAtEnd();
    }
  };

  const handleNoteTextSave = useDebouncedCallback(
    async (value: string) => {
      try{
        await notes.update({id: ui.currentNote.id, text: value});
      }catch(e){
        toaster.danger(e.message);
        console.error(e);
      }
    },
    300
  );

  const isInternalLink = (href: string) => {
    return href.startsWith("https://app.wannadocs.com/notes/") || href.startsWith("http://localhost");
  }

  const handleLinkClick = (href, event) => {
    if(isInternalLink(href)){
      const m = href.match('/notes/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})');
      if(m){
        const noteId = m[1];
        history.push(`/notes/${noteId}`);
      }else{
        window.open(href, '_blank');
      }
    }else{
      window.open(href, '_blank');
    }
  }

  return ui.currentNote.text && <Pane>
    <StyledMarkdownEditor
      onClickLink={handleLinkClick}
      ref={innerRef}
      value={null}
      defaultValue={ui.currentNote.text}
      embeds={EMBEDS}
      dictionary={editor}
      placeholder={noteForm.editorStartWriting}
      disableExtensions={["emoji"]}
      readOnly={projects.isReadonlyUser}
      onSearchLink={async searchTerm => {
        const suggestion = await notes.search(searchTerm);
        return suggestion.map(x => {
          return { title: x.title, subtitle: x.variantTitle, url: x.innerUrl }
        });
      }}
      onChange={(value) => {
        const text = value();
        ui.currentNote.setText(text);
        handleNoteTextSave(text);
      }}
      uploadImage={handleImageUpload}
    />
    <ClickablePadding onClick={focusAtEnd}/>
  </Pane>
}));

const ClickablePadding = styled.div`
  min-height: 15em;
  cursor: ${({ onClick }) => (onClick ? "text" : "default")};
  flex-grow: 100;
`;

const StyledMarkdownEditor = styled(MarkdownEditor)`
  margin-top: 20px;
  margin-bottom: 25px;
  margin-left: 0px;
  margin-right: 0px;

  h1, h2, h3 {
    color: rgb(55 53 47);
  }
  p, .heading-name {
    color: rgb(55 53 47);
  }

  .notice-block p{
    color: ${defaultTheme.colors.text.dark};
  }

  h1:not(.placeholder):before {
    content: "";
  }

  h2:not(.placeholder):before {
    content: "";
  }

  h3:not(.placeholder):before {
    content: "";
  }

  .block-menu-trigger{
    color: #B1BECC;
  }

  .block-menu-trigger svg{
    fill: #B1BECC;
  }
   
  a {
    color: #027bfc;
  }
`

export default inject('ui', 'notes', 'projects')(Editor);
