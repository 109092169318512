import translate from 'app/i18n';
import AuthStore from 'app/stores/AuthStore';
import { toaster, Text, WarningSignIcon, Dialog } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { useHistory } from 'react-router';
import Loader from '../../components/Loader';
import Form, {State} from '../../components/Settings/components/Project/Form';
import ProjectsStore from '../../stores/ProjectsStore';
import Layout from './Layout';
import {toJS} from "mobx";

interface Props {
  projects?: ProjectsStore,
  auth?: AuthStore
}

const Project = observer((props: Props) => {

  const {projects, auth} = props;

  const [isLoading, setLoading] = React.useState(false);
  const [showDeletingDialog, setShowDeletingDialog] = React.useState(false);

  const {projectSettingsScreen, common, deleteProjectDialog} = translate();
  const history = useHistory();

  const handleSubmit = async (formState: State) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", formState.name);
    formData.append("homePageUrl", formState.homePageUrl);
    formData.append("themeName", formState.themeName);
    formData.append("storefrontHome", formState.storefrontHome);
    formData.append("footerText", formState.footerText);
    if(formState.logo) formData.append("logo", formState.logo);
    if(formState.favicon) formData.append("favicon", formState.favicon);
    formData.append("customDomain", formState.customDomain);
    formData.append("primaryColor", formState.primaryColor);
    formData.append("metaTags", JSON.stringify(formState.metaTags));

    try{
      await projects.update(formData)
      toaster.success(common.updatedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setLoading(false);
    }
  }

  const handleShowDeleteDialog = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowDeletingDialog(true);
  }

  const handleDelete = async (close: () => void) => {
    await projects.delete(projects.current.id);
    await projects.list();
    setShowDeletingDialog(false);

    if(projects.data.length === 0){
      history.push("/welcome");
    }else{
      projects.setCurrent(projects.data[0]);
      history.push("/home");
    }
  }

  return <Layout header={projectSettingsScreen.title}>
    {projects?.current ?
     <Form onSubmit={handleSubmit} isLoading={isLoading} onDelete={auth.role === "owner" ? handleShowDeleteDialog : null}
           name={projects.current.name} subdomain={projects.current.subdomain}
           logoUrl={projects.current.logoUrl} faviconUrl={projects.current.faviconUrl}
           themeName={projects.current.themeName}
           customDomain={projects.current.customDomain || ''} primaryColor={projects.current.primaryColor}
           homePageUrl={projects.current.homePageUrl || ''}
           storefrontHome={projects.current.storefrontHome}
           footerText={projects.current.footerText}
           metaTags={toJS(projects.current.metaTags)}
     />
     :
     <Loader />
    }

    <Dialog
        isShown={showDeletingDialog}
        title={`${deleteProjectDialog.title}?`}
        intent="danger"
        onCloseComplete={() => setShowDeletingDialog(false)}
        onConfirm={handleDelete}
        confirmLabel={common.btnDelete}
        cancelLabel={common.btnCancel}
      >
      <Text display="flex" flex="1" alignItems="center" justifyContent="center" paddingY={8}>
        <WarningSignIcon marginRight={4}/> {deleteProjectDialog.subtitle}
      </Text>
    </Dialog>
  </Layout>
});

export default inject('projects', 'auth')(Project);
