import translate from 'app/i18n';
import { toaster } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import Loader from '../../components/Loader';
import Form from '../../components/Settings/components/Personal/Form';
import AuthStore from '../../stores/AuthStore';
import ProjectsStore from '../../stores/ProjectsStore';
import UiStore from '../../stores/UiStore';
import Layout from './Layout';

interface Props {
  auth?: AuthStore,
  projects?: ProjectsStore
}

const Personal = observer((props: Props) => {
  const {projects, auth} = props;
  const {personalSettingsDialog} = translate();

  return <Layout header={personalSettingsDialog.title}>
    {auth.user ?
     <Form />
     :
     <Loader />
    }
  </Layout>
});

export default inject('auth', 'projects')(Personal);