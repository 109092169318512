import { Tab, Tablist } from 'app/components/Tablist';
import SummaryStore from 'app/stores/SummaryStore';
import { Pane, Heading, Text } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { useEffect } from 'react';
import List from 'app/components/List';
import { runInAction } from 'mobx';
import Empty from './Empty';
import RecentItem from './RecentItem';
import translate from 'app/i18n';
import ActivitiesStore from 'app/stores/ActivitiesStore';
import ActivityItem from './ActivityItem';

interface Props {
  summary?: SummaryStore,
  activities?: ActivitiesStore,
}

const RecentNotesTablist = observer((props: Props) => {
  const { summary, activities } = props;

  const [tab, setTab] = React.useState<string>("updates");
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const { dashboardScreen, allUpdatesScreen } = translate();

  useEffect(() => {
    setLoading(true);
    activities.list().then(() => setLoading(false));
  }, []);

  const onChangeTab = async (tab: string) => {
    setTab(tab);
    setLoading(true);

    switch (tab) {
      case "updates":
        await activities.list();
        setLoading(false);
        break;
      case "workedOn":
        await summary.loadWorkedOn();
        setLoading(false);
        break;
      case "drafts":
        runInAction(() => summary.drafts.clear());
        await summary.loadDrafts();
        setLoading(false);
        break;
      case "favorites":
        runInAction(() => summary.favorites.clear());
        await summary.loadFavorites();
        setLoading(false);
        break;
      default:
        activities.list();
        break;
    }
  }

  return <>
    <Tablist marginTop={16} marginBottom={12}>
      <Tab isSelected={tab === "updates"} marginLeft={0} onSelect={() => onChangeTab("updates")}>{allUpdatesScreen.projectUpdates}</Tab>
      <Tab isSelected={tab === "workedOn"} marginLeft={0} onSelect={() => onChangeTab("workedOn")}>{dashboardScreen.workedOn}</Tab>
      <Tab isSelected={tab === "drafts"} onSelect={() => onChangeTab("drafts")}>{dashboardScreen.drafts}</Tab>
      <Tab isSelected={tab === "favorites"} onSelect={() => onChangeTab("favorites")}>{dashboardScreen.favorites}</Tab>
    </Tablist>

    <Pane display="flex" flex="0" flexDirection="column">
      {tab === "updates" && <List perPage={15} empty={<Empty />} isLoading={isLoading} data={activities.data}
        onClickLoadMore={async () => {
          const cursor = activities.data[activities.data.length - 1];
          return await activities.loadMore(cursor.id)
        }}
        onItemRender={(item) => {
          return <ActivityItem key={item.id} activity={item} />
        }}
      />}

      {tab === "workedOn" && <List perPage={15} empty={<Empty />} isLoading={isLoading} data={summary.workedOn}
        onItemRender={(item) => {
          return <RecentItem key={item.id} item={item} />
        }}
      />}

      {tab === "drafts" && <List perPage={15} empty={<Empty />} isLoading={isLoading} data={summary.drafts}
        onClickLoadMore={async () => {
          const cursor = summary.drafts[summary.drafts.length - 1];
          return await summary.loadDrafts(cursor.id);
        }}
        onItemRender={(item) => {
          return <RecentItem key={item.id} item={item} />
        }}
      />}

      {tab === "favorites" && <List perPage={15} empty={<Empty />} isLoading={isLoading} data={summary.favorites}
        onClickLoadMore={async () => {
          const cursor = summary.favorites[summary.favorites.length - 1];
          return await summary.loadFavorites(cursor.id);
        }}
        onItemRender={(item) => {
          return <RecentItem key={item.id} item={item} />
        }}
      />}
    </Pane>
  </>
});

export default inject('summary', 'activities')(RecentNotesTablist);