import BaseModel from "./BaseModel";
import {
  serializable
} from "serializr"

export default class BacklinkItem extends BaseModel {
  @serializable
  title: string = null;

  @serializable
  innerUrl: string = null;

  @serializable
  logoType: string = null;

  @serializable
  logoPresentable: string = null;
}