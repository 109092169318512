import { IResponse } from 'app/utils/ApiClient';
import { Text, InfoSignIcon, Pane, Button, ArrowDownIcon, defaultTheme } from 'evergreen-ui';
import { observer } from 'mobx-react';
import * as React from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import FadeIn from './FadeIn';
import translate from '../i18n';
import Loader from './Loader';

interface Props {
  data?: Array<any>,
  isLoading: boolean,
  perPage?: number,
  empty?: React.ReactNode,
  onItemRender(item: any): React.ReactElement,
  onClickLoadMore?(data: Array<any>): Promise<IResponse>,
}

const List: React.FC<Props> = ({perPage, empty, data, onClickLoadMore, isLoading, onItemRender}) => {

  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(onClickLoadMore === undefined ? false : true);
  const i18n = translate();

  const handleLoadMore = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoadingMore(true);

    try{
      const res = await onClickLoadMore(data)
      if(res.data.length === 0){
        setShowLoadMore(false);
      }
      setLoadingMore(false);
    }catch(err){
      setLoadingMore(false);
    }
  }

  const renderEmpty = () => {
    if(empty){
      return empty;
    }

    return <Text padding={100} alignItems="center" display="flex" flex="1" justifyContent="center">
      <InfoSignIcon marginRight={10}/> {i18n.list.empty}
    </Text>
  }

  if(isLoading) return <Loader /> 

  return <Pane flexDirection="column" flex="1" display="flex">
    <FadeIn>
      {data.length === 0 ? renderEmpty() : <StyledList>{data.map(x => onItemRender(x))}</StyledList>}
      {data.length !== 0 && showLoadMore && (perPage ? data.length >= perPage : true) && <Pane display="flex" flex="1" justifyContent="center" marginTop={16} marginBottom={30}>
        <Button style={{color: defaultTheme.colors.text.default}} appearance="minimal" iconBefore={ArrowDownIcon}  marginRight={16} onClick={handleLoadMore} isLoading={loadingMore}>
          {i18n.list.loadMore}
        </Button>
      </Pane>}
    </FadeIn>
  </Pane>
}

const StyledList = styled('ul')`
  padding: 0;
  margin: 0;
  list-style: none;
`

export default observer(List);