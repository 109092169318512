import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { Pane, PlusIcon, toaster } from 'evergreen-ui'
import NotesStore from '../stores/NotesStore';
import { useHistory } from 'react-router-dom';
import Variant from 'app/models/Variant';
import translate from '../i18n';
import ProjectsStore from 'app/stores/ProjectsStore';
import { PrimaryButton } from './Typography';

interface Props {
  notes?: NotesStore,
  projects?: ProjectsStore,
  variant?: Variant
}

const CreateButton = observer((props: Props) => {
  const { notes, variant, projects } = props;

  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const {createButton, common} = translate();

  const handleNewPage = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const newNote = await notes.create({
        parent_id: '__ROOT__',
        text: "\n\\",
        title: createButton.noteTitle,
        variant_id: variant.id,
        is_draft: true,
        type: "text"
      });
      setLoading(false);

      history.push(`/notes/${newNote.id}`);
    } catch (e) {
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }
  }

  return (
    <Pane display="flex">
      <PrimaryButton iconAfter={PlusIcon} height={32} appearance="primary" disabled={variant?.id === null || projects.isReadonlyUser} 
              onClick={handleNewPage} isLoading={loading} marginRight={8}>{createButton.title}</PrimaryButton>
    </Pane>
  )
});

export default inject('notes', 'projects')(CreateButton)