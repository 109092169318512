import { Pane, SearchIcon, Text } from "evergreen-ui";
import { observer } from "mobx-react";
import * as React from "react";
import Note from "../../models/Note";
import FadeIn from "../FadeIn";
import translate from "../../i18n";
import Loader from "../Loader";
import DroppableLine from "./components/DroppableLine";
import Item from "./components/Item";

interface Props {
  root?: Note,
  isLoading: boolean
}

const EmptyList = () => {

  const {common} = translate();  

  return <Text padding={100} alignItems="center" display="flex" flex="1" justifyContent="center">
    <SearchIcon marginRight={10}/> {common.pagesNotFound}
  </Text>
}

const Tree = (observer((props: Props) => {
  const { root, isLoading} = props;

  if(isLoading) return <Loader />
  if(!root) return <></>
  if(root.children.length === 0) return <EmptyList />

  return <Pane display="flex" flexDirection="column" flex="1">
    <FadeIn>
      {
        root.children.length > 0 ?
          <DroppableLine note={root.children[0]} mode={"ontop"} />
          :
          <DroppableLine note={root} mode={"children"} />
      }
      {root.children.map(note => {
        return <Item key={note.id} note={note} level={1} />
      })}
    </FadeIn>
  </Pane>
}));

export default Tree;