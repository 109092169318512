import Project from "../models/Project";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import { getCookie, setCookie } from "tiny-cookie";
import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import Invite from "../models/Invite";
import { client, IResponse } from "../utils/ApiClient";
import { deserialize } from "serializr";

const ROLES = [{
  label: 'Owner',
  value: 'owner'
}, {
  label: 'Read only',
  value: 'read_only'
},{
  label: 'Admin',
  value: 'admin'
},{
  label: 'Editor',
  value: 'editor'
}];

export default class ProjectsStore extends BaseStore<Project> {
  current?: Project = null;
  noProjects: boolean = false;

  constructor(protected readonly rootStore: RootStore){
    super(rootStore, Project, 'project');

    makeObservable(this, {
      current: observable,
      setCurrent: action,
      sendInvite: action,
      resendInvite: action,
      deleteInvite: action,
      currentUserRole: computed,
      isReadonlyUser: computed,
      noProjects: observable
    });
    
    const projectId = getCookie('project_id');

    setTimeout(async () => {
      await this.list();

      if(this.data.length === 0){
        runInAction(() => this.noProjects = true);
      }else{
        const project: Project = this.data[this.data.length - 1];
        this.setCurrent(this.data.find(x => x.id === projectId) || project)
      }
    });
  }

  setCurrent(project: Project | string) {
    if (typeof project === "string"){
      setCookie('project_id', project);
      this.current = this.data.find(x => x.id === project);
    }else{
      if(project){
        setCookie('project_id', project.id);
        this.current = project;
      }
    }
  }

  async sendInvite(email: string): Promise<Invite[]> {
    const res = await client.post(`${this.modelName}s.send_invite`, {email: email});
    const invites = res.data.map(x => observable(deserialize(Invite, x)));
    runInAction(() => {
      this.current.invites.push(...invites);
    });
    return invites;
  }

  async resendInvite(id: string): Promise<Invite> {
    const res = await client.post(`${this.modelName}s.resend_invite`, {id: id});
    const invite = observable(deserialize(Invite, res.data));
    runInAction(() =>{
      this.current.invites.replace(this.current.invites.map(x => x.id === id ? invite : x))
    });
    return invite;
  }

  async deleteInvite(id: string): Promise<IResponse> {
    const res = await client.post(`invites.delete`, {id: id});
    runInAction(() => {
      this.current.invites.replace(this.current.invites.filter(invite => invite.id !== id));
    });
    return res;
  }

  async changeRole(userId: string, role: string): Promise<IResponse> {
    const res = await client.post(`${this.modelName}s.change_role`, {id: userId, role: role});
    runInAction(() => {
      const member = this.current.members.find(x => x.user.id === userId);
      member.role = role;
    });
    return res;
  }

  async deleteMember(userId: string): Promise<IResponse> {
    const res = await client.post(`${this.modelName}s.delete_member`, {id: userId});
    runInAction(() => {
      this.current.members.replace(this.current.members.filter(x => x.user.id !== userId))
    });
    return res;
  }

  async export(email: string): Promise<IResponse> {
    return client.post(`${this.modelName}s.export`, {email: email});
  }

  get currentUserRole(): {label: string, value: string} {
    if(!this.current || !this.rootStore.auth.user){
      return null;
    }

    const role = this.current.members.find(x => x.user.id === this.rootStore.auth.user.id).role;
    return ROLES.find(x => x.value === role);
  }

  get isReadonlyUser(): boolean {
    return this.currentUserRole?.value === 'read_only';
  }

  async setVisibility(status: string): Promise<IResponse> {
    runInAction(() => this.current.visibilityStatus = status);
    return client.post(`${this.modelName}s.set_visibility`, {status: status});
  }

  async setRestrictAccess(enabled?: boolean, password?: string): Promise<IResponse> {
    runInAction(() => {
      this.current.restrictAccessEnabled = enabled;
      this.current.restrictAccessPassword = password
    });
    return client.post(`${this.modelName}s.set_restrict_access`, {enabled: enabled, password: password});
  }

  async setRestrictAccessIp(enabled?: boolean, ips?: string): Promise<IResponse> {
    runInAction(() => {
      this.current.restrictAccessIpsEnabled = enabled;
      this.current.restrictAccessIps = ips
    });
    return client.post(`${this.modelName}s.set_restrict_access_ip`, {enabled: enabled, ips: ips});
  }
}