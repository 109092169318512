import * as React from 'react';
import ProjectsStore from 'app/stores/ProjectsStore';
import { inject, observer } from 'mobx-react';
import Layout from '../Layout';
import { Pane } from 'evergreen-ui';
import ShareableLinkPanel from './components/ShareableLinkPanel';
import RestrictAccessPanel from './components/RestrictAccessPanel';
import VisibilityPanel from './components/VisibilityPanel';
import translate from 'app/i18n';
import Paywall from 'app/components/Paywall';
import RestrictIpPanel from "app/screens/Settings/Share/components/RestrictIpPanel";

interface Props {
  projects?: ProjectsStore
}


const Share = observer((props: Props) => {
  const {projects} = props;
  const [active, setActive] = React.useState<string>("VisibilityPanel")
  const {shareScreen} = translate();

  if(projects.current?.availableQuota?.hasPrivateKb === false){
    return <Paywall />
  }

  return <Layout header={shareScreen.title}>
    <Pane display="flex" flex="1" background="white" paddingTop={16} flexDirection="column" padding={10}>
      <VisibilityPanel isShown={active === "VisibilityPanel"} onClick={() => setActive("VisibilityPanel")}/>

      <Pane marginTop={16}>
        <ShareableLinkPanel isShown={active === "ShareableLinkPanel"} onClick={() => setActive("ShareableLinkPanel")}/>
      </Pane>
      <Pane marginTop={16}>
        <RestrictAccessPanel isShown={active === "RestrictAccessPanel"} onClick={() => setActive("RestrictAccessPanel")}/>
      </Pane>
      <Pane marginTop={16}>
        <RestrictIpPanel isShown={active === "RestrictIpPanel"} onClick={() => setActive("RestrictIpPanel")}/>
      </Pane>
    </Pane>
  </Layout>
});

export default inject('projects')(Share);