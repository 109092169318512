import * as React from 'react'
import { Text, defaultTheme, Pane } from 'evergreen-ui';
import styled from 'styled-components';
import TextWrap from '../../TextWrap';
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

interface Props {
  url: string,
  icon?: React.ReactNode,
  afterIcon?: React.ReactNode,
  label: string | React.ReactNode,
  onClick?(e: React.SyntheticEvent): void,
  highlight?: boolean;
  highlightType?: "background" | "borderLeft";
  routeMatch?: boolean;
}

const SidebarLink: React.FC<Props> = ({routeMatch, url, icon, afterIcon, label, onClick, highlight, highlightType}) => {
  const style: any = {}

  const match: boolean = routeMatch ? useRouteMatch(url) !== null : false;

  if (highlight || match){
    if(highlightType === 'background'){
      style.backgroundColor = defaultTheme.colors.border.default;
    }else if(highlightType === 'borderLeft'){
      style.boxShadow = `${defaultTheme.colors.intent.success} 4px 0px 0px inset`;
      style.backgroundColor = "transparent";
    }
  }

  const Wrapper = (!url || url.startsWith("https://") || url.startsWith("http://")) ? PlainLinkWrapper : RouterLinkWrapper;
  const target = (!url || url.startsWith("https://") || url.startsWith("http://")) ? "_blank" : null

  return (
    <Wrapper to={url} href={url} onClick={onClick} style={style} target={target}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{label}</Text>
      {afterIcon && afterIcon}
    </Wrapper>
  )
};

SidebarLink.defaultProps = {
  highlight: false,
  highlightType: "background",
  routeMatch: false
}

const IconWrapper = styled('div')`
  display: flex;
  width: 14px;
  height: 14px;
  padding: 4px;
  align-items: center;
  margin-right: 4px;
  color: ${defaultTheme.colors.text.default};
`

const RouterLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 27px;
  padding: 2px 14px;
  background: transparent;
  transition: background 20ms ease-in 0s;
  text-decoration: none;
  &:hover {
    background-color: ${defaultTheme.colors.border.default};
  }
`

const PlainLinkWrapper = styled('a')`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 27px;
  padding: 2px 14px;
  background: transparent;
  transition: background 20ms ease-in 0s;
  text-decoration: none;
  &:hover {
    background-color: ${defaultTheme.colors.border.default};
  }
`

export default SidebarLink;