import { action, computed, IObservableArray, makeObservable, observable } from "mobx";
import BaseModel from "./BaseModel";

import {
  list,
  object,
  serializable
} from "serializr"
import Member from "./Member";
import Invite from "./Invite";
import Quota from "./Quota";
import translate from "app/i18n";
import MetaTag from "app/models/MetaTag";

export default class Project extends BaseModel{
  @serializable
  name: string = null;

  @serializable
  subdomain: string = null;

  @serializable
  primaryColor: string = null;

  @serializable
  logoUrl: string = null;

  @serializable
  createdAt: string = null;

  @serializable
  updatedAt: string = null;

  @serializable(list(object(Member)))
  members: IObservableArray<Member> = observable<Member>([]);

  @serializable
  faviconUrl: string = null;

  @serializable
  domain: string = null;

  @serializable
  customDomain: string = null;

  @serializable
  homePageUrl: string = null;

  @serializable(list(object(Invite)))
  invites: IObservableArray<Invite> = observable<Invite>([]);

  @serializable
  notesCount: number = null;

  @serializable
  storageUsageInGb: number = null;

  @serializable
  themeName: string = null;

  @serializable
  visibilityStatus: string = null;

  @serializable
  restrictAccessEnabled: boolean = null;

  @serializable
  restrictAccessPassword: string = null;


  @serializable(object(Quota))
  availableQuota: Quota = null;

  @serializable(object(Quota))
  usedQuota: Quota = null;

  @serializable
  payable: boolean = null;

  @serializable
  storefrontHome: string = null;

  @serializable
  footerText: string = null;

  @serializable(list(object(MetaTag)))
  metaTags: IObservableArray<MetaTag> = observable<MetaTag>([]);

  @serializable
  restrictAccessIpsEnabled: boolean = null;

  @serializable
  restrictAccessIps: string = null;

  constructor(){
    super();

    makeObservable(this, {
      id: observable,
      name: observable,
      subdomain: observable,
      primaryColor: observable,
      logoUrl: observable,
      createdAt: observable,
      updatedAt: observable,
      members: observable,
      faviconUrl: observable,
      domain: observable,
      customDomain: observable,
      homePageUrl: observable,
      invites: observable,
      notesCount: observable,
      storageUsageInGb: observable,
      themeName: observable,
      visibilityStatus: observable,
      restrictAccessEnabled: observable,
      restrictAccessIpsEnabled: observable,
      restrictAccessPassword: observable,
      restrictAccessIps: observable,
      setVisibilityStatus: action,
      setRestrictAccessEnabled: action,
      setRestrictAccessPassword: action,
      setRestrictAccessIps: action,
      setRestrictAccessIpsEnabled: action,
      isPublicVisibility: computed,
      availableQuota: observable,
      usedQuota: observable,
      setAvailableQuota: action,
      setUsedQuota: action,
      payable: observable,
      pricingPlan: computed,
      storefrontHome: observable,
      footerText: observable,
      metaTags: observable
    })
  }

  setVisibilityStatus(value: string) {
    this.visibilityStatus = value;
  }

  setRestrictAccessEnabled(value: boolean) {
    this.restrictAccessEnabled = value;
  }

  setRestrictAccessIpsEnabled(value: boolean) {
    this.restrictAccessIpsEnabled = value;
  }

  setRestrictAccessPassword(value: string) {
    this.restrictAccessPassword = value;
  }

  setRestrictAccessIps(value: string) {
    this.restrictAccessIps = value;
  }

  setAvailableQuota(quota: Quota){
    this.availableQuota = quota;
  }

  setUsedQuota(quota: Quota){
    this.usedQuota = quota;
  }

  get isPublicVisibility(): boolean {
    return this.visibilityStatus === "public";
  }

  get pricingPlan(): string {
    const {projectMenu} = translate();
    return this.payable ? projectMenu.paid : projectMenu.trial;
  }
}
