import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { defaultTheme, Heading, Pane, Select, Strong, Tab, Tablist, Text } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import FeedbackCount from '../../models/FeedbackCount';
import styled from 'styled-components';

interface Props {
  item: FeedbackCount
}

const FeedbackItem = observer((props: Props) => {
  const {item} = props;

  return (
    <Wrapper display="flex" flex="1" justifyContent="space-between" alignItems="center">
      <Link to={`/notes/${item.noteId}`} style={{textDecoration: "none"}}><Strong>{item.title}</Strong></Link>
      <Text>{item.count}</Text>
    </Wrapper>
  )
});

const Wrapper = styled(Pane)`
  height: 34px;
  min-height: 34px;
  max-height: 34px;
  padding: 7px 7px;
  margin: 0px;

  &:hover {
    background-color: ${defaultTheme.colors.background.tint2};
  }
`

export default FeedbackItem
