import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from "mobx-react";
import Routes from '../app/routes'
import { BrowserRouter as Router } from "react-router-dom";
import stores from '../app/stores';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {enableLogging} from 'mobx-logger';

require.context('../images', true);


// enableLogging();
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
      <DndProvider backend={HTML5Backend}>
        <Provider {...stores}>
          <Router>
            <>
              <Routes />
            </>
          </Router>
        </Provider>
      </DndProvider>
    ,
    document.getElementById('root'),
  )
})