import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import Note from "../models/Note";
import { deserialize, serialize } from "serializr";
import { client, IResponse } from "../utils/ApiClient";
import { action, IObservableArray, makeObservable, observable, runInAction } from "mobx";

export default class NotesStore extends BaseStore<Note> {
  discarded: IObservableArray<Note> = observable<Note>([]);

  constructor(protected readonly rootStore: RootStore){
    super(rootStore, Note, 'note');

    makeObservable(this, {
      discarded: observable,
      reorder: action,
      duplicate: action,
      move: action,
      loadDiscarded: action,
      tree: action,
      softDelete: action,
      globalSearch: action,
      favorite: action,
      setVisibleStatus: action
    });
  }

  async reorder(root: Note, _dragged: Note){
    const res = await client.post(`${this.modelName}s.reorder`, { root: serialize(root) });
    return res;
  }

  async duplicate(id: string): Promise<Note>{
    const res = await client.post(`${this.modelName}s.duplicate`, { id: id });
    
    return this.add(res.data);
  }

  async move(id: string, moveToId: string): Promise<Note>{
    const res = await client.post(`${this.modelName}s.move`, { id: id, move_to_id: moveToId });
    
    return this.add(res.data);
  }

  async pdf(id: string): Promise<{path: string}>{
    const res = await client.post(`${this.modelName}s.pdf`, { id: id });

    return res.data;
  }

  async loadDiscarded(cursorId: string = null): Promise<IResponse>{
    const res = await client.post(`${this.modelName}s.list.discarded`, {cursorId: cursorId});

    runInAction(() =>{
      this.discarded.replace(this.discarded.concat(res.data.map(x => deserialize(Note, x))));
    });

    return res;
  }

  async restore(id: string, variantId: string = null): Promise<IResponse>{
    const res = await client.post(`${this.modelName}s.restore`, {id: id, variantId: variantId});

    runInAction(() =>{
      this.discarded.replace(this.discarded.filter(x => x.id !== id));
    });

    // this.rootStore.ui.currentNote?.setDeleted(false);
    // this.rootStore.ui.currentNote?.setSlug(res.data.slug);

    return res;
  }

  async globalSearch(q: string): Promise<Note[]>{
    if(!q){
      return [];
    }

    const res = await client.post(`${this.modelName}s.global_search`, {q: q});

    return res.data?.map(x => deserialize(Note, x)) || [];
  }

  async search(q: string, variantId?: string): Promise<Note[]>{
    if(!q){
      return [];
    }

    const res = await client.post(`${this.modelName}s.search`, {q: q, variantId: variantId});

    return res.data?.map(x => deserialize(Note, x)) || [];
  }

  async tree(variantId: string): Promise<Note>{
    const res = await client.post(`${this.modelName}s.tree`, {variantId: variantId});

    return deserialize(Note, res.data);
  }

  async setEmoji(id: string, data: any): Promise<Note>{
    const res = await client.post(`${this.modelName}s.set_logo`, { id: id, emoji: data });

    const note = deserialize(Note, res.data);

    runInAction(() =>{
      const exists = this.data.find(x => x.id === id);

      if(exists){
        exists.logoPresentable = note.logoPresentable;
        exists.logoType = note.logoType;
      }
    });

    return note;
  }

  async setLogoFile(id: string, data: FormData): Promise<Note>{
    data.append("id", id);

    const res = await client.post(`${this.modelName}s.set_logo`, data);

    const note = deserialize(Note, res.data);

    runInAction(() =>{
      const exists = this.data.find(x => x.id === id);

      if(exists){
        exists.logoPresentable = note.logoPresentable;
        exists.logoType = note.logoType;
      }
    });

    return note;
  }

  async removeLogo(id: string): Promise<IResponse>{
    runInAction(() =>{
      const exists = this.data.find(x => x.id === id);

      if(exists){
        exists.logoPresentable = null;
        exists.logoType = "none";
      }
    });
    
    const res = await client.post(`${this.modelName}s.remove_logo`, {id: id});
    return res;
  }

  // Do not remove reference in this.data
  async softDelete(id: string): Promise<IResponse> {
    return client.post(`${this.modelName}s.delete`, {id: id});
  }

  async favorite(id: string): Promise<IResponse> {
    return client.post(`${this.modelName}s.favorite`, {id: id});
  }

  async setVisibleStatus(id: string, status: string): Promise<Note>{
    const res = await client.post(`${this.modelName}s.set_visible_status`, {id: id, status: status});
    const note = deserialize(Note, res.data);

    runInAction(() =>{
      const exists = this.data.find(x => x.id === id);

      if(exists){ exists.setDraft(note.isDraft); }
    });

    return note;
  }
}