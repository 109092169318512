export interface IResponse {
  ok: boolean
  data: any
  error?: string,
  message?: string
}

class ApplicationError extends Error {}
class ValidationError extends ApplicationError {}

class ApiClient {
  baseUrl: string

  constructor(baseUrl = '/api/v1/') {
    this.baseUrl = baseUrl;
  }

  async post(path: string, body: Object | FormData = {}): Promise<IResponse> {

    let response = null;
    if (body instanceof FormData) {
      response = await fetch(this.baseUrl + path, {
        method: 'POST',
        body: body,
        cache: "no-cache"
      });
    }else{
      response = await fetch(this.baseUrl + path, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          pragma: "no-cache"
        },
        method: 'POST',
        body: JSON.stringify(body),
        cache: "no-cache"
      });
    }

    const success = response.status >= 200 && response.status < 300;

    if (success) {
      return response.json();
    }else{
      let parsed = {ok: false, data: null, message: '', error: ''}
      try {
        parsed = await response.json();
        throw new ValidationError(parsed.message);
      } catch (e) {
        throw new ApplicationError(e.message)
      }
    }
  }

  // @Deprecated
  async sendFile(path: string, body: FormData): Promise<IResponse> {
    const response = await fetch(this.baseUrl + path, {
      method: 'POST',
      body: body,
      cache: "no-cache"
    });

    const success = response.status >= 200 && response.status < 300;

    if (success) {
      return response.json();
    }else{
      let parsed = {ok: false, data: null, message: '', error: ''}
      try {
        parsed = await response.json();
        throw new ValidationError(parsed.message);
      } catch (e) {
        throw new ApplicationError(e.message)
      }
    }
  }

  async auth(email): Promise<IResponse> {
    return new ApiClient('/auth/').post('email.create', {email: email});
  }

  async authConfig(): Promise<IResponse> {
    return new ApiClient('/auth/').post('auth.config');
  }
}

export default ApiClient;
export const client = new ApiClient();