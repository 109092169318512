import BaseModel from "./BaseModel";
import {
  date,
  serializable
} from "serializr"
import { makeObservable, observable } from "mobx";

export default class Invite extends BaseModel {
  @serializable
  email: string = null;
  @serializable(date())
  sendedAt: Date = null;
  @serializable
  projectName: string = null;
  
  constructor(){
    super();

    makeObservable(this, {
      id: observable,
      email: observable,
      sendedAt: observable,
      projectName: observable
    })
  }
}