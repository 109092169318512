import { Pane } from "evergreen-ui";
import * as React from "react";
import styled from "styled-components";
const Logo = require('../../../images/favicon.png');

const Header: React.FC<{}> = ({}) => {
  return (
    <Wrapper>
      <Pane display="flex" flex="1" maxWidth={1020} height={100} alignItems="center" justifyContent="space-between">
        <Pane>
          <a href="https://wannadocs.com/">
            <img src={Logo} width={40} height={40}/>
          </a>
        </Pane>
      </Pane>
    </Wrapper>
  )
}

const Wrapper = styled('header')`
  display: flex;
  flex: 1;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: center;
  background-color: white;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
`

const NavList = styled('ul')`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    vertical-align: middle;

    a {
      margin-left: 0;
      color: rgba(0, 0, 0, .55);
      font-family: Basier Circle, Helvetica Neue, Segoe UI, Helvetica, Arial, Lucida Grande, sans-serif;
      font-size: 17px;
      -webkit-font-smoothing: antialiased;
      font-weight: 500;
      line-height: 50px;
      margin: 0 15px;
      transition: opacity .2s ease-in;
      cursor: pointer;
      text-decoration: none;

      &:hover{
        color: #000;
        position: relative;
      }

      &:hover:after {
        background-color: #000;
        border-radius: 1.5px;
        bottom: -6px;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
    }
  }
`

export default Header;
