import translate from "app/i18n";
import { Button, Heading, Pane, Text } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import FadeIn from "./FadeIn";

interface Props {
  auth?: AuthStore,
}

const Paywall = observer((props: Props) => {
  const {auth} = props;
  const history = useHistory();
  const {paywall} = translate();

  if(!auth.user) return <></>

  const handleUpgrade = (e: React.SyntheticEvent) => {
    e.preventDefault();
    history.push("/settings/billing/pricing");
  }

  return <Pane display="flex" flex="1" flexDirection="column" alignItems="center">
    <FadeIn>
      <Pane display="flex" flex="1" justifyContent="center" alignItems="center" marginTop={100} flexDirection="column" maxWidth={600}>
        <Heading size={900}> 🔥 {paywall.title}</Heading>
        <Text marginTop={16} size={600}>{paywall.subtitle}</Text>

        <Pane marginTop={24} maxWidth={600} width="100%">
          <Button is="a" onClick={handleUpgrade} href="/settings/billing/pricing" appearance="primary" height={40}>{paywall.btnUpgrade}</Button>
        </Pane>
      </Pane>
    </FadeIn>
  </Pane>
});


export default inject('auth')(Paywall);