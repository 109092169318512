import {
  action,
  computed,
  makeObservable,
  observable
} from "mobx";
import Note from "../models/Note";
import RootStore from "./RootStore";

export default class UiStore {
  dialog: React.ReactNode = null;
  currentNote?: Note = null;
  isLoaded: boolean = false;
  isShowSearchDialog: boolean = false;

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      showDialog: action,
      hideDialog: action,
      dialog: observable,
      currentNote: observable,
      setCurrentNote: action,
      isLoaded: observable,
      isShowSearchDialog: observable,
      setShowSearchDialog: action
    });
  }

  showDialog(dialog: React.ReactNode) {
    this.dialog = dialog;
  }

  hideDialog() {
    this.dialog = null;
  }

  setCurrentNote(note: Note) {
    this.currentNote = note;
  }

  setShowSearchDialog(show: boolean) {
    this.isShowSearchDialog = show;
  }
}
