import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import Integration from "../models/Integration";
import { computed, makeObservable } from "mobx";

export default class IntegrationsStore extends BaseStore<Integration> {
  constructor(protected readonly rootStore: RootStore){
    super(rootStore, Integration, 'integration');

    makeObservable(this, {
      customCss: computed,
      customJs: computed,
      chatra: computed,
      disqus: computed,
      intercomMessenger: computed,
    });
  }

  get customCss(): Integration {
    return this.data.find(x => x.type === "Integrations::CustomCss");
  }

  get customJs(): Integration {
    return this.data.find(x => x.type === "Integrations::CustomJs");
  }

  get chatra(): Integration {
    return this.data.find(x => x.type === "Integrations::Chatra");
  }

  get disqus(): Integration {
    return this.data.find(x => x.type === "Integrations::Disqus");
  }

  get intercomMessenger(): Integration {
    return this.data.find(x => x.type === "Integrations::IntercomMessenger");
  }

  get intercomChat(): Integration {
    return this.data.find(x => x.type === "Integrations::IntercomChatWidget");
  }
}