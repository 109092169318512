import translate from 'app/i18n';
import { Button, defaultTheme, Heading, Link, Pane, Small, Switch, Text } from 'evergreen-ui';
import * as React from 'react'
import styled from 'styled-components';
import application from "../../Application";

interface Props {
  onClickBuyPersonal(annually: boolean, e: React.SyntheticEvent);
  onClickBuyTeam(annually: boolean, e: React.SyntheticEvent);
  isLoading: boolean
}

const TableView = (props: Props) => {
  const [annually, setAnnually] = React.useState(false);
  const {onClickBuyPersonal, onClickBuyTeam, isLoading} = props;
  const {pricingPlans, common} = translate();

  const handleAnnuallyChange = () => {
    setAnnually(!annually);
  }

  const personalPrice = () => {
    return annually ? application.config.pricingPlans.personal.yearly.priceUsd : application.config.pricingPlans.personal.monthly.priceUsd;
  }

  const teamPrice = () => {
    return annually ? application.config.pricingPlans.team.yearly.priceUsd : application.config.pricingPlans.team.monthly.priceUsd;
  }

  const perMonth = () => {
    return annually ? pricingPlans.yearly : pricingPlans.perMonth;
  }

  return (
    <Wrapper style={{opacity: isLoading ? 0.3 : 1}}>
        <thead>
          <tr>
            <td>
              <Text display="flex" alignItems="center">{pricingPlans.payAnnually} <Switch onChange={handleAnnuallyChange} checked={annually} marginLeft={4} name={pricingPlans.payAnnually}/> </Text>
            </td>
            <th>
              <Pane display="flex" flexDirection="column">
                <Heading size={600}>{pricingPlans.names['personal_monthly']}</Heading>
                <Text fontWeight="bold" size={500}>$ {personalPrice()} <Small fontWeight="normal">{perMonth()}</Small></Text>
                {annually && <Text color="success">{pricingPlans.twoMonthFree}</Text>}
              </Pane>
            </th>
            <th>
              <Pane display="flex" flexDirection="column">
                <Heading size={600}>{pricingPlans.names['team_monthly']}</Heading>
                <Text fontWeight="bold" size={500}>$ {teamPrice()} <Small fontWeight="normal">{perMonth()}</Small></Text>
                {annually && <Text color="success">{pricingPlans.twoMonthFree}</Text>}
              </Pane>
            </th>
            <th>
              <Heading size={600}>{pricingPlans.names['enterprise']}</Heading>
              <Link href="mailto:sales@wannadocs.com" color="neutral">{pricingPlans.contactSales}</Link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="price-category">
              <Text fontWeight="bold">{pricingPlans.usage}</Text>
            </th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.pages}</Text></th>
            <td><Text color="black">100</Text></td>
            <td><Text color="black">500</Text></td>
            <td><Text color="black">{pricingPlans.unlimited}</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.contentVariants}</Text></th>
            <td><Text color="black">5</Text></td>
            <td><Text color="black">10</Text></td>
            <td><Text color="black">{pricingPlans.unlimited}</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.members}</Text></th>
            <td><Text color="black">{pricingPlans.justYou}</Text></td>
            <td><Text color="black">5</Text></td>
            <td><Text color="black">{pricingPlans.unlimited}</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.fileUploads}</Text></th>
            <td><Text color="black">1 GB</Text></td>
            <td><Text color="black">5 GB</Text></td>
            <td><Text color="black">{pricingPlans.unlimited}</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.languages}</Text></th>
            <td><Text color="black">6 {pricingPlans.languagesCount}</Text></td>
            <td><Text color="black">6 {pricingPlans.languagesCount}</Text></td>
            <td><Text color="black">6 {pricingPlans.languagesCount}</Text></td>
          </tr>
          {/* <tr>
            <th><Text color="muted">Version history</Text></th>
            <td></td>
            <td><Text color="black">30 days</Text></td>
            <td><Text color="black">Forever</Text></td>
          </tr> */}
          <tr>
            <th className="price-category">
              <Text fontWeight="bold">{pricingPlans.features}</Text>
            </th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.editorBlocksAndEmbeds}</Text></th>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.customDomain}</Text></th>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.sslCertificate}</Text></th>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.analytics}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.integrations}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.sharePermissions}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.accessNewFeatureEarly}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.themeAdaptation}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.openSource}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.selfHosted}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th className="price-category">
              <Text fontWeight="bold">{pricingPlans.support}</Text>
            </th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.emailAndLiveChat}</Text></th>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.prioritySupport}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
          <tr>
            <th><Text color="muted">{pricingPlans.dedicatedManager}</Text></th>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">-</Text></td>
            <td><Text color="black">+</Text></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <td>
              <Button isLoading={isLoading} onClick={(e) => onClickBuyPersonal(annually, e)} appearance="primary">{common.btnBuy}</Button>
            </td>
            <td>
              <Button isLoading={isLoading} onClick={(e) => onClickBuyTeam(annually, e)} appearance="primary">{common.btnBuy}</Button>
            </td>
            <td>
              <Link href="mailto:sales@wannadocs.com" size={500}>{pricingPlans.contactSales}</Link>
            </td>
          </tr>
        </tfoot>
      </Wrapper>
  )
  
}

const Wrapper = styled('table')`
  border-collapse: collapse;
  
  thead{
    position: sticky;
    top: 64px;
    background-color: white;
  }

  thead tr th{
    text-align: left;
    padding-bottom: 20px;
    padding-left: 5px;
    border-left: 1px solid ${defaultTheme.colors.border.default};
  }

  tbody th{
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  tfoot tr td, tbody tr td{
    padding-left: 5px;
  }

  tbody th.price-category{
    padding-top: 28px;
    padding-bottom: 10px;
  }

  thead tr, tbody tr{
    border-bottom: 1px solid ${defaultTheme.colors.border.default};
  }

  tfoot td, tbody td{
    border-left: 1px solid ${defaultTheme.colors.border.default};
  }

  tbody th{
    border-right: 1px solid ${defaultTheme.colors.border.default};
  }

  tfoot tr td{
    text-align: left;
    padding-top: 16px;
    padding-bottom: 8px;
  }
`

export default TableView;