import * as React from 'react'
import { inject, observer } from 'mobx-react';
import {Tooltip, Text, Badge, defaultTheme, Heading, Button, TrashIcon, InheritanceIcon, EditIcon, Pane } from 'evergreen-ui';
import styled from 'styled-components';
import Note from '../../../models/Note';
import Menu, {Wrapper as MenuWrapper} from './Menu';
import SquaredAvatar from '../../SquaredAvatar';
import Caret from './Caret';

interface Props {
  note?: Note
}

const Item = (observer((props: Props) => {
  const {note} = props;

  return (
    <Pane marginBottom={4}>
      <Wrapper style={{paddingLeft: 14}}>
        {note.logoType !== "none" &&
        <Pane display="flex" width={20} height={20} justifyContent="center" alignItems="center" marginRight={6}>
          {note.logoType === "file" && <SquaredAvatar size={16} src={note.logoPresentable} />}
          {note.logoType === "emoji" && <Pane dangerouslySetInnerHTML={{__html: note.logoPresentable}}/>}
        </Pane>}

        {note?.isDraft && <Badge color="yellow" marginRight={4}>D</Badge>}
        {note?.isTableOfContents && <Badge color="purple" marginRight={4}>TOC</Badge>}
        <Text>{note?.title === '' ? 'Untitled' : note?.title}</Text>
        <Menu note={note}/>
      </Wrapper>
    </Pane>
  )
}));

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 48px;
  padding: 2px 14px;
  background: transparent;
  transition: background 20ms ease-in 0s;

  &:hover {
    background-color: ${defaultTheme.colors.background.tint2};
  }

  &:hover ${MenuWrapper}{
    visibility: visible;
  }
`

export default inject('notesTree')(Item);