import * as React from 'react'
import { inject, observer } from 'mobx-react'
import UiStore from '../stores/UiStore'
import Dialog from './Dialog';
import Note from '../models/Note';
import NotesStore from '../stores/NotesStore';
import { Button, defaultTheme, Heading, Pane, SearchInput, Text, toaster } from 'evergreen-ui';
import styled from 'styled-components';
import VariantsStore from '../stores/VariantsStore';
import { useHistory } from 'react-router-dom';
import Variant from '../models/Variant';
import translate from 'app/i18n';

interface Props {
  ui?: UiStore,
  variants?: VariantsStore,
  notes?: NotesStore,
  note: Note
}

const MoveToDialog = (observer((props: Props) => {
  const history = useHistory();
  const [shown, setShown] = React.useState(true);
  const [moving, setMoving] = React.useState(false);

  const [selected, setSelected] = React.useState<Variant>(null);

  React.useEffect(() => {
    variants.list()
  }, []);

  const { note, notes, variants, ui } = props;
  const {common, moveTo} = translate();

  const handleDialogClose = () => {
    ui.hideDialog();
  }

  const handleCancel = () => {
    setShown(false);
  }

  const handleConfirmMove = async () => {
    try {
      setMoving(true);
      const movedNote = await notes.move(note.id, selected.id);
      setMoving(false);
      ui.hideDialog();
      history.push(`/notes/${movedNote.id}`);
    } catch (e) {
      setMoving(false);
      toaster.danger(e.message);
    }
  }

  const handleSelect = (note: Variant) => {
    setSelected(note);
  }

  return <Dialog
    width={600}
    isShown={shown}
    onCloseComplete={handleDialogClose}
    hasFooter={false}
    hasHeader={false}>
    <Heading size={800} paddingLeft={20}>{moveTo.title}</Heading>

    <Pane padding={20}>
      {/* <Pane marginBottom={20}>
        <SearchInput height={38} autoFocus={true} placeholder="Move to..." width="100%" size={400} onChange={handleFilterInput} />
      </Pane> */}

      <ResultsWrapper>
        {variants.data.map(x => {
          return <Selectable isSelected={x.id === selected?.id} key={x.id} onClick={() => handleSelect(x)}>
            <Text>{x.title}</Text>
          </Selectable>
        })}
      </ResultsWrapper>

      {selected && <Pane marginTop={20}><Text marginRight={5}>{moveTo.moving} {note.title} {moveTo.to} <strong>{selected.title}</strong></Text></Pane>}

      <Pane marginTop={20} display="flex" justifyContent="space-between" alignItems="baseline">
        <Button height={40} onClick={handleCancel}>{common.btnCancel}</Button>
        <Button height={40} appearance="primary" onClick={handleConfirmMove} isLoading={moving}>{moveTo.btnMove}</Button>
      </Pane>
    </Pane>
  </Dialog>
}));

const ResultsWrapper = styled('div')`
  border: 1px solid ${defaultTheme.colors.border.default};
  height: 200px;
  overflow-y: auto;
`

const Selectable = styled('div')<{ isSelected: boolean }>((props) => `
  color: ${defaultTheme.colors.text.default};
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  background: ${props.isSelected ? defaultTheme.colors.background.tint2 : 'transparent'};

  &:hover{
    background: ${defaultTheme.colors.background.tint2};
  }
`)

export default inject('ui', 'variants', 'notes')(MoveToDialog);