import * as React from "react"
import { Pane, Label } from "evergreen-ui"
const GdocsImage = require('../../images/gdocs.png')
const GithubImage = require('../../images/github.png')
const DropboxImage = require('../../images/dropbox.png')
const NotionImage = require('../../images/notion.png')
import { UploadyContext } from "@rpldy/uploady";
import Service from "./Service"
import FileUploader from "../../components/FileUploader"
import styled from "styled-components"
import { IResponse } from "../../utils/ApiClient"
import translate from "app/i18n"

interface Props {
  type: "url" | "file",
  onServiceClick?(type: 'gdocs'|'github'|'dropbox'|'notion'),
  onFinish?(response: IResponse)
}

const Uploader = ((props: Props) => {
  const  {type, onServiceClick, onFinish} = props;

  const uploadyContext = React.useContext(UploadyContext);
  const {common, importDialog} = translate();

  const handleOpenSelectFile = () => {
    uploadyContext.showFileUpload(uploadyContext.getOptions());
  }

  return <Pane>
    <FileUploader formatLabels={['DOCX', 'MD', 'HTML']} onFinish={onFinish} type={type}/>

    <Pane display="flex" flex="1" marginTop={20} flexDirection="column">
      <Label>{importDialog.importContentFrom}</Label>

      <ServicesList>
        <Service title="Google Docs" description={`${importDialog.uploadYourNotesAs} DOCX`} img={GdocsImage} onClick={() => {handleOpenSelectFile(); onServiceClick && onServiceClick('gdocs')}}/>
        <Service title="Github Wiki" description={`${importDialog.uploadYourNotesAs} MD`} img={GithubImage} onClick={() => {handleOpenSelectFile(); onServiceClick && onServiceClick('github')}}/>
        <Service title="Dropbox Paper" description={`${importDialog.uploadYourNotesAs} DOCX`} img={DropboxImage} onClick={() => { handleOpenSelectFile(); onServiceClick && onServiceClick('dropbox')}}/>
        <Service title="Notion" description={`${importDialog.uploadYourNotesAs} DOCX`} img={NotionImage} onClick={() => {handleOpenSelectFile(); onServiceClick && onServiceClick('notion')}}/>
      </ServicesList>
    </Pane>
  </Pane>
});

const ServicesList = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default Uploader;