import translate from "app/i18n";
import Purchase from "app/models/Purchase";
import AuthStore from "app/stores/AuthStore";
import { Badge, defaultTheme, Link, Pane, Text } from "evergreen-ui"
import { inject, observer } from "mobx-react";
import * as React from "react"
import styled from "styled-components"

interface Props {
  auth?: AuthStore,
  purchase?: Purchase
}

const Item = observer((props: Props) => {
  const {purchase} = props;
  const {purchases, pricingPlans} = translate();


  return <Wrapper flexDirection="column">
    <Pane backgroundColor="white" display="flex" flex="1" padding={12} borderRadius={3} justifyContent="space-between" alignItems="baseline">
      <Text size={500} fontWeight="bold">{pricingPlans.names[purchase.subscriptionPlanName] || purchase.subscriptionPlanName} {purchase.paymentProcessorSubscriptionId ? `#${purchase.paymentProcessorSubscriptionId.toUpperCase()}` : ''}</Text>

      {purchase.status === "active" && <Badge color="green">{purchases.statuses[purchase.status]}</Badge>}
      {purchase.status === "pending" && <Badge color="neutral">{purchases.statuses[purchase.status]}</Badge>}
      {purchase.status === "past_due" && <Badge color="yellow">{purchases.statuses[purchase.status]}</Badge>}
      {purchase.status === "deleted" && <Badge color="red">{purchases.statuses[purchase.status]}</Badge>}
    </Pane>
    <Pane display="flex" flex="1" paddingX={8} paddingY={16}>
      <Pane display="flex" flex="1" flexDirection="column">
        <Pane>
          <Text marginRight={8}>{purchases.createdAt}:</Text>
          <Text>{purchase.createdAt?.toLocaleDateString()}</Text>
        </Pane>

        <Pane marginTop={4}>
          {purchase.status === "active" && <Text marginRight={8}>{purchases.nextBillDate}:</Text>}
          {purchase.status === "deleted" && <Text marginRight={8}>{purchases.validThrough}:</Text>}
          <Text>{["active", "deleted"].indexOf(purchase.status) !== -1 ? purchase.nextBillDate?.toLocaleDateString() : '-'}</Text>
        </Pane>

        <Pane marginTop={4}>
          <Text marginRight={8}>{purchases.paid}:</Text>
          <Text>{purchase.status === "pending" ? '-' : `${purchase.paidPriceUsd} $`}</Text>
        </Pane>

        {purchase.status === "deleted" &&<Pane marginTop={4}>
          <Text color="danger">{purchases.wasCanceled}</Text>
        </Pane>}
      </Pane>

      {purchase.status === "active" && purchase.subscriptionPlanName !== "trial" && <Pane display="flex" flex="1" flexDirection="column">
        {purchase.receiptUrl && <Pane>
          <Link href={purchase.receiptUrl} target="_blank">{purchases.getReceipt}</Link>
        </Pane>}

        {purchase.updateUrl && <Pane marginTop={4}>
          <Link href={purchase.updateUrl} target="_blank">{purchases.updatePaymentMethod}</Link>
        </Pane>}

        <Pane marginTop={4}>
          <Link href="mailto:refund@wannadocs.com" target="_blank">{purchases.refund}</Link>
        </Pane>

        {purchase.cancelUrl && <Pane marginTop={12}>
          <Link style={{color: defaultTheme.colors.intent.danger}} href={purchase.cancelUrl} target="_blank">{purchases.cancelSubscription}</Link>
        </Pane>}
      </Pane>}

      {purchase.status === "deleted" && <Pane display="flex" flex="1" flexDirection="column">
        <Pane marginTop={4}>
          <Link href="mailto:refund@wannadocs.com" target="_blank">{purchases.refund}</Link>
        </Pane>
      </Pane>}
    </Pane>
  </Wrapper>
});

const Wrapper = styled(Pane)`
  padding: 4px;
  border-radius:3px;
  display: flex;
  flex: 1;
  background: #f5f5f5;
  margin-bottom: 16px;
`

export default  inject('auth')(Item);