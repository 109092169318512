import translate from "app/i18n";
import ProjectsStore from "app/stores/ProjectsStore";
import { defaultTheme, Heading, Text } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

interface Props {
  projects?: ProjectsStore
}

const QuotaTable = observer((props: Props) => {
  const {projects} = props;
  const {billingQuota} = translate();

  React.useEffect(() => {
    projects.info().then((project) => {
      projects.current?.setAvailableQuota(project.availableQuota);
      projects.current?.setUsedQuota(project.usedQuota);
    });
  }, []);

  const availability = (c: Number) => {
    if(c === null) return "";

    if(c < 0){
      return billingQuota.unlimited;
    }else{
      return c;
    }
  }

  return <Wrapper>
    <thead>
      <tr>
        <td></td>
        <th>
          <Heading size={600}>{billingQuota.used}</Heading>
        </th>
        <th>
          <Heading size={600}>{billingQuota.available}</Heading>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th className="price-category">
          <Text fontWeight="bold">{billingQuota.usage}</Text>
        </th>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <th><Text color="muted">{billingQuota.pages}</Text></th>
        <td><Text color="black">{projects.current?.usedQuota?.pages}</Text></td>
        <td><Text color="black">{availability(projects.current?.availableQuota?.pages)}</Text></td>
      </tr>
      <tr>
        <th><Text color="muted">{billingQuota.contentVariants}</Text></th>
        <td><Text color="black">{projects.current?.usedQuota?.variants}</Text></td>
        <td><Text color="black">{availability(projects.current?.availableQuota?.variants)}</Text></td>
      </tr>
      <tr>
        <th><Text color="muted">{billingQuota.members}</Text></th>
        <td><Text color="black">{projects.current?.usedQuota?.members}</Text></td>
        <td><Text color="black">{availability(projects.current?.availableQuota?.members)}</Text></td>
      </tr>
      <tr>
        <th><Text color="muted">{billingQuota.fileUploads}</Text></th>
        <td><Text color="black">{projects.current?.usedQuota?.spaceGb} GB</Text></td>
        <td><Text color="black">{availability(projects.current?.availableQuota?.spaceGb)} GB</Text></td>
      </tr>
      <tr>
        <th><Text color="muted">{billingQuota.languages}</Text></th>
        <td colSpan={2} style={{textAlign: 'center'}}><Text color="black">{projects.current?.usedQuota?.languages.map(x => x.toUpperCase()).join(', ')}</Text></td>
      </tr>
      {/* <tr>
        <th><Text color="muted">Version history(days)</Text></th>
        <td colSpan={2} style={{textAlign: 'center'}}><Text color="black">{availability(projects.current?.availableQuota?.versionHistoryDays)}</Text></td>
      </tr> */}
    </tbody>
  </Wrapper>
});

const Wrapper = styled('table')`
  border-collapse: collapse;
  
  thead{
    position: sticky;
    top: 64px;
    background-color: white;
  }

  thead tr th{
    text-align: left;
    padding-bottom: 20px;
    padding-left: 5px;
    border-left: 1px solid ${defaultTheme.colors.border.default};
  }

  tbody th{
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  tfoot tr td, tbody tr td{
    padding-left: 5px;
  }

  tbody th.price-category{
    padding-top: 28px;
    padding-bottom: 10px;
  }

  thead tr, tbody tr{
    border-bottom: 1px solid ${defaultTheme.colors.border.default};
  }

  tfoot td, tbody td{
    border-left: 1px solid ${defaultTheme.colors.border.default};
  }

  tbody th{
    border-right: 1px solid ${defaultTheme.colors.border.default};
  }

  tfoot tr td{
    text-align: left;
    padding-top: 16px;
    padding-bottom: 8px;
  }
`

export default inject('projects')(QuotaTable);