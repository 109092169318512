import * as React from "react";

interface Config<T> {
  state: T
  onSubmit? :(state: T) => void
  onCancel? :(state: T) => void
}

function useForm<T>(config: Config<T>): [
  T, React.Dispatch<React.SetStateAction<T>>, // useState
  (e: React.FormEvent<HTMLFormElement>) => void, // handleSubmit
  (e: React.FormEvent<HTMLFormElement>) => void, // handleCancel
  (name: string, onChange? :(e: any) => any) => (e: any) => void // useChange
  ] {
    const [formState, setFormState] = React.useState<T>(config.state);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      config.onSubmit && config.onSubmit(formState)
    }

    const handleCancel = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      config.onCancel && config.onCancel(formState)
    }

    const useChange = (name: string, onChange? :(e: any) => any) => {
      return (e: any): void => {
        if(typeof e.preventDefault !== 'undefined') e.preventDefault();

        const s = {...formState};
        if(onChange) { 
          s[name] = onChange(e);
        }else if(typeof e.currentTarget !== 'undefined'){
          s[name] = e.currentTarget.value; // React.SyntheticEvent
        }else{
          s[name] = e;
        }
        setFormState(s);
      }
    }

    return [formState, setFormState, handleSubmit, handleCancel, useChange];
}

export default useForm;