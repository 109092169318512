import { Button, Pane, TextInput } from "evergreen-ui";
import * as React from "react"
import Divider from "../../Divider";
import Label from "../../Label";
import ColorPresetSelector, { COLORS } from "./ColorPresetSelector";
import ImageFileInput from "../../ImageFileInput";
import useForm from "../../useForm";
import translate from "app/i18n";
import { MinimalButton, PrimaryButton } from "app/components/Typography";

interface Props {
  id?: string,
  name?: string,
  primaryColor?: string,
  currentLogoUrl?: string,
  isLoading?: boolean,
  onSubmit?(state: State): void,
  onCancel?(state: State): void
}

export interface State {
  name: string,
  primaryColor: string,
  logo: File
}

const Form: React.FC<Props> = (props) => {
  const {id, currentLogoUrl, name, primaryColor, isLoading, onSubmit, onCancel} = props;

  const [formState, setFormState, handleSubmit, handleCancel, useChange] = useForm<State>({
    state: {
      name: name || '',
      logo: null,
      primaryColor: primaryColor || COLORS[0]
    },
    onSubmit,
    onCancel
  });

  const handleNameChange = useChange('name');
  const handlePrimaryColorChange = useChange('primaryColor', (color: string) => color);
  const handleLogoChange = useChange('logo', (file: File) => file);
  const {common, projectForm} = translate();

  return <form onSubmit={handleSubmit}>
    <Label htmlFor="name" marginTop={20}>{projectForm.projectName}</Label>
    <TextInput required
              onChange={handleNameChange}
              autoFocus={true} name="name" id="name"
              placeholder={projectForm.projectNamePlaceholder}
              width="100%" height={38}
              value={formState.name}/>

    <Label htmlFor="logo" marginTop={20}>{projectForm.logo}</Label>
    <ImageFileInput name="logo" url={currentLogoUrl} onChange={handleLogoChange} backgroundColor={formState.primaryColor}/>

    <Label htmlFor="primaryColor" marginTop={20}>{projectForm.color}</Label>
    <Pane marginBottom={20}>
      <ColorPresetSelector onChange={handlePrimaryColorChange} />
    </Pane>

    <Divider />

    <Pane display="flex" flex="1" justifyContent="space-between" marginTop={20} marginBottom={40}>
      {onCancel && <MinimalButton onClick={handleCancel} height={40}>{common.btnCancel}</MinimalButton>}
      {id ? <PrimaryButton appearance="primary" height={40} isLoading={isLoading}>{common.btnUpdate}</PrimaryButton> : <PrimaryButton isLoading={isLoading} appearance="primary" height={40}>{common.btnCreate}</PrimaryButton>}
    </Pane>
  </form>
}

export default Form;