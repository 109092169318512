import AuthStore from "app/stores/AuthStore";
import ProjectsStore from "app/stores/ProjectsStore";
import PurchasesStore from "app/stores/PurchasesStore";
import { Pane } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import application from "../Application";
import TableView from "./components/TableView";

interface Props {
  auth?: AuthStore,
  purchases?: PurchasesStore,
  projects?: ProjectsStore,
  onCompletePurchase?(details: any);
}

const PricingTable = observer((props: Props) => {
  const {auth, purchases, projects, onCompletePurchase} = props;
  const [isLoading, setLoading] = React.useState(false);

  const {pricingPlans} = application.config;
  
  const handlePaddleOpen = (productId: string, pricingPlanId: string) => {
    setLoading(true);

    (window as any).Paddle.Checkout.open({
      product: productId,
      email: auth.user?.email,
      passthrough: projects.current?.id,
      loadCallback: function() {
        setLoading(false)
      },
      successCallback: function(data) {
        (window as any).Paddle.Order.details(data.checkout.id, function(details) {
          if(details.state === "processed"){
            purchases.create({
              subscriptionPlanId: pricingPlanId,
              paymentProcessor: 'paddle',
              paymentProcessorSubscriptionId: details.order.subscription_id
            });

            if(onCompletePurchase) onCompletePurchase(details);
          }
        });
      }
    });
  };

  const handleClickBuyPersonal = (annually: boolean, e: React.SyntheticEvent) => {
    e.preventDefault();

    if(annually){
      handlePaddleOpen(pricingPlans.personal.yearly.paymentProcessorPlanId, pricingPlans.personal.yearly.id);
    }else{
      handlePaddleOpen(pricingPlans.personal.monthly.paymentProcessorPlanId, pricingPlans.personal.monthly.id);
    }
  }

  const handleClickBuyTeam = (annually: boolean, e: React.SyntheticEvent) => {
    e.preventDefault();

    if(annually){
      handlePaddleOpen(pricingPlans.team.yearly.paymentProcessorPlanId, pricingPlans.team.yearly.id);
    }else{
      handlePaddleOpen(pricingPlans.team.monthly.paymentProcessorPlanId, pricingPlans.team.monthly.id);
    }
  }

  return <Pane display="flex" flex="1" flexDirection="column">
    <TableView onClickBuyTeam={handleClickBuyTeam} onClickBuyPersonal={handleClickBuyPersonal} isLoading={isLoading}/>
  </Pane>
});

export default inject('purchases', 'auth', 'projects')(PricingTable);