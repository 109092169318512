import * as React from 'react'
import { Pane, Text } from 'evergreen-ui';
import translate from 'app/i18n';

const Empty = () => {
  const { dashboardScreen } = translate();

  return <Pane padding={100} alignItems="center" display="flex" flex="1" justifyContent="center" flexDirection="column">
    <Pane display="flex" alignItems="center">
      <Text color="muted" textTransform="uppercase">{dashboardScreen.noRecentPages}</Text>
    </Pane>
    <Pane marginTop={10}>
      <Text>{dashboardScreen.thisListWillGrow}</Text>
    </Pane>
  </Pane>
};


export default Empty;