import * as React from "react"
import { inject, observer } from 'mobx-react';
import { useDrop } from "react-dnd";
import DragObject from "./DragObject";
import Note from "../../../models/Note";
import NotesStore from "../../../stores/NotesStore";
import VariantsStore from "../../../stores/VariantsStore";
import NotesTreeStore from "../../../stores/NotesTreeStore";

interface Props {
  notes?: NotesStore,
  notesTree?: NotesTreeStore,
  mode: "ontop"  |  "after" | "children",
  note?: Note
}

/*
[Workspace]
  [DroppableLine] <- ontop

  [SidebarLink]
  [DroppableLine] <- after

  [SidebarLink] <- when expanded
    [DroppableLine] <- children
  [/SidebarLink]

  [SidebarLink]
  [DroppableLine] <- after

  [SidebarLink]
  [DroppableLine] <- after
[/Workspace]
*/

const DroppableLine = inject('notesTree', 'notes')(observer((props: Props) => {
  const {note, mode, notesTree, notes} = props;

  if (!note) return <div></div>

  const [{isOver}, dropRef] = useDrop<DragObject, any, any>({accept: 'Note',
    drop: (_, monitor) => {
      const {id: draggedId} = monitor.getItem();

      if (!monitor.isOver({shallow: true})) return

      const draggedNote = notesTree.find(draggedId);

      if(notesTree.findAncestors(note).find(x => x.id === draggedNote.id)){
        return;
      }

      move(draggedNote)
    },

    collect: (monitor) => {
      if (!monitor.isOver({shallow: true})) return {isOver: false}

      return {
        isOver: monitor.isOver() ? true : false
      }
    }
  })

  const move = (draggedNote: Note) => {
    const parent = notesTree.find(note.parentId);

    // Jump to UP level when drag himself on bottom position
    if (note.id === draggedNote.id && parent.children.length === parent.children.indexOf(draggedNote) + 1){
      const grandParent = notesTree.find(parent.parentId);
      if(!grandParent) return;
      notesTree.moveTo(parent, draggedNote, grandParent.children, grandParent.id);
      notes.reorder(notesTree.root, draggedNote);
      return
    }

    if (note.id === draggedNote.id) return; // Prevent drag into himself

    switch (mode) {
      case 'children':
        notesTree.moveTo(note, draggedNote, note.children, note.id);
        notes.reorder(notesTree.root, draggedNote);
        break;
      case 'after':
        notesTree.moveTo(note, draggedNote, parent.children, parent.id);
        notes.reorder(notesTree.root, draggedNote);
        break;
      case 'ontop':
        notesTree.moveTo(null, draggedNote, parent.children, parent.id, "top");
        notes.reorder(notesTree.root, draggedNote);
        break;
      default:
        break;
    }
  }

  const overStyles = { backgroundColor: isOver ? '#00adff85' : 'transparent' }
  return <div ref={dropRef} style={{ height: '4px', ...overStyles }}></div>
}))

export default DroppableLine

