import translate from "app/i18n";
import PurchasesStore from "app/stores/PurchasesStore";
import { Pane, Table } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import EmptyList from "../EmptyList";
import Item from "./components/Item";

interface Props {
  purchases?: PurchasesStore
}

const List = observer((props: Props) => {
  const {purchases} = props;
  const {list} = translate();

  React.useEffect(() => {
    purchases.list();
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      purchases.list();
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, []);

  return <Pane display="flex" flex="1" flexDirection="column">
      {purchases.data.length === 0 ? <EmptyList text={list.empty}/> : purchases.reversed.map(x => <Item key={x.id} purchase={x} />)}
  </Pane>
});

export default inject('purchases')(List);