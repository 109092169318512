import Divider from 'app/components/Divider';
import translate from 'app/i18n';
import ImageFileInput, { RefObject } from 'app/components/ImageFileInput';
import Label from 'app/components/Label';
import AuthStore from 'app/stores/AuthStore';
import { Button, defaultTheme, Pane, TextInput, TextInputField, toaster } from 'evergreen-ui';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form';
import UiStore from 'app/stores/UiStore';
import ProjectsStore from 'app/stores/ProjectsStore';
import { MinimalButton, PrimaryButton } from 'app/components/Typography';

interface Props {
  ui?: UiStore,
  projects? :ProjectsStore,
  auth?: AuthStore
}

interface IFormInputs {
  name: string
  username: string
  photo: File
}

const Form = (observer((props: Props) => {
  const {projects, ui, auth} = props;
  const {common, personalSettingsDialog} = translate();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>();

  const handleFormCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();
    reset();
  }

  const onSubmit = async (data: IFormInputs) => {
    const formData = new FormData();
    formData.append("name", data.name);
    if(data.photo) { formData.append("photo", data.photo); }
    formData.append("username", data.username);

    try{
      const user = await auth.update(formData);
      setValue("username", user.username);
      runInAction(() => {
        projects.current.members.forEach(x => {
          if(x.user.id === user.id){
            x.user = user;
          }
        });
      })
      toaster.success(common.updatedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      ui.hideDialog();
    }
  }

  return <Pane is="form" onSubmit={handleSubmit(onSubmit)} marginTop={40}>
    <Pane paddingBottom={20} borderBottom="default">
      <Label>{personalSettingsDialog.photo}</Label>
      <Controller
        name="photo"
        control={control}
        defaultValue={null}
        render={({ field }) => <ImageFileInput backgroundColor={defaultTheme.colors.background.tint1} url={auth.user?.photoUrl} {...field}/>}
      />
    </Pane>

    <Pane paddingBottom={20} borderBottom="default" display="flex" marginTop={40} flexDirection="column">
      <Pane display="flex" justifyContent="space-between" flex="1">
        <Pane display="flex" flexDirection="column" flex="1">
          <Label>{personalSettingsDialog.fullName}</Label>
          <TextInput required defaultValue={auth.user?.name} placeholder={personalSettingsDialog.fullName} width="100%" {...register("name")} height={38}/>
        </Pane>

        <Pane display="flex" flexDirection="column" flex="1" marginLeft={10}>
          <Label>{personalSettingsDialog.username}</Label>
          <TextInput required defaultValue={auth.user?.username} placeholder={personalSettingsDialog.username} width="100%" {...register("username")} height={38}/>
        </Pane>
      </Pane>

      <Pane display="flex" flexDirection="column" flex="1" marginTop={20}>
        <Label>{personalSettingsDialog.email}</Label>
        <TextInput disabled defaultValue={auth.user?.email} width="100%" height={38}/>
      </Pane>
    </Pane>

    <Pane display="flex" flex="1" justifyContent="flex-end" marginTop={20} marginBottom={20}>
      <MinimalButton onClick={handleFormCancel} height={40}>{common.btnCancel}</MinimalButton>
      <PrimaryButton appearance="primary" height={40} marginLeft={10} type="submit">{common.btnSave}</PrimaryButton>
    </Pane>
  </Pane>
}));

export default inject('ui', 'projects', 'auth')(Form);