import BaseModel from "./BaseModel";
import {
  object,
  serializable
} from "serializr"
import User from "./User";
import { makeObservable, observable } from "mobx";

export default class Member extends BaseModel {
  @serializable
  role: string = null;
  @serializable(object(User))
  user: User = null;
  
  constructor(){
    super();

    makeObservable(this, {
      id: observable,
      role: observable,
      user: observable
    })
  }
}