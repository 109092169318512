import translate from "app/i18n";
import { Badge, Button, IconButton, Pane, Text, toaster, TrashIcon } from "evergreen-ui"
import { inject, observer } from "mobx-react";
import * as React from "react"
import styled from "styled-components"
import Invite from "../../../../models/Invite";
import ProjectsStore from "../../../../stores/ProjectsStore";

interface Props {
  invite: Invite,
  projects?: ProjectsStore
}

const Item = observer((props: Props) => {
  const {invite, projects} = props;

  const [isLoading, setLoading] = React.useState(false);
  const {invitationsScreen, common} = translate();

  const handleDelete = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    try{
      await projects.deleteInvite(invite.id);
      toaster.success(common.deletedMessage);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }
  }
  
  const handleResend = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    try{
      await projects.resendInvite(invite.id);
      toaster.success(invitationsScreen.invitesResended);
    }catch(e){
      console.error(e);
      toaster.danger(`${common.errorMessage}: ${e.message}`);
    }finally{
      setLoading(false);
    }
  }

  return <Wrapper>
    <Pane display="flex" flexDirection="column" marginLeft={10}>
      <Text>{invite.email} {invitationsScreen.sendedAt} {invite.sendedAt.toDateString()}</Text>
      <Text>
        <Badge color="green" isSolid>{invitationsScreen.pending}</Badge>
      </Text>
    </Pane>

    <Pane display="flex" justifyContent="flex-end" flex="1" alignItems="center">
      <Button onClick={handleResend} isLoading={isLoading}>{invitationsScreen.btnResend}</Button>
      <IconButton icon={TrashIcon} intent="danger" marginLeft={10} onClick={handleDelete} disabled={isLoading}/>
    </Pane>
  </Wrapper>
});

const Wrapper = styled('div')`
  padding: 15px;
  border-radius:3px;
  display: flex;
  flex: 1;
  align-items: center;
  transition: 0.1s;

  &:hover {
    background: rgb(55, 53, 47, 0.08);
  }
`

export default inject('projects')(Item);