import { defaultTheme, Pane, PaneOwnProps } from "evergreen-ui";
import * as React from "react";
import styled from "styled-components";
import SquaredAvatar from "../SquaredAvatar";

interface Props {
  isShown?: boolean,
  headerLogoUrl?: string,
  header?: React.ReactNode,
  children?: React.ReactNode,
  previewValue?: React.ReactNode,
  hidePreviewValueOnShow?: boolean,
  footer?: React.ReactNode,
  onClick?: () => void
}

const Panel = (props: Props) => {
  const { header, isShown, onClick, footer, hidePreviewValueOnShow, headerLogoUrl, previewValue, children } = props;

  return <Wrapper onClick={onClick} $isShown={isShown} border="default">
    <Pane display="flex" flex="1" alignItems="center" justifyContent="space-between">
      <Pane display="flex" alignItems="center">
        {headerLogoUrl ? 
          <Pane display="flex" alignItems="center">
            <SquaredAvatar src={headerLogoUrl} size={20} marginRight={5}/>
            {header && header}
          </Pane> 
          :
          <Pane display="flex" alignItems="center">{header && header}</Pane>
        }
      </Pane>
      {previewValue && <Pane>{isShown && hidePreviewValueOnShow ? <></> : previewValue}</Pane>}
    </Pane>

    {isShown && <Pane borderTop="default" marginTop={16} paddingTop={16} width="100%">
      {children && children}
    </Pane>}

    {isShown && footer && <Pane justifyContent="flex-end" display="flex" flex="1" borderTop="default" marginTop={16} paddingTop={16}>
      {footer && footer}
    </Pane>}
  </Wrapper>
};

const Wrapper = styled(Pane)<{ $isShown?: boolean}>((props) => `
  padding: 15px;
  border-radius:3px;
  display: flex;
  flex: 1;
  transition: 0.1s;
  flex-direction: column;
  background: ${props.$isShown ? 'white' : defaultTheme.colors.background.tint2};

  &:hover {
    cursor: ${props.$isShown ? 'default' : 'pointer'};
    background: ${props.$isShown ? 'white' : defaultTheme.colors.background.tint1};
  }
`)

export default Panel;