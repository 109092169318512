import { Heading, Pane, Text } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import AuthStore from '../../stores/AuthStore';
import RoutedLink from '../../components/RoutedLink';
import { Redirect } from 'react-router-dom';
import Box from 'ui-box';
import Form from './Form';
import Header from './Header';
import translate from 'app/i18n';

interface Props {
  auth?: AuthStore
}

const Signup: React.FC<Props> = ({ auth }) => {
  const {loginScreen, signupScreen} = translate(true);

  React.useEffect(() => {
    document.title = signupScreen.documentTitle
  }, []);

  if (auth.authenticated) {
    return <Redirect to="/home" />
  } else {
    return (
      <Pane display="flex" flex="1" justifyContent="center" flexDirection="column">
        <Header />

        <Pane display="flex" flex="1" justifyContent="center" alignItems="center" marginTop={100}>
          <Box maxWidth={320}>
            <Heading size={900} marginTop="default" marginBottom="1em" fontWeight={700}>{signupScreen.title}</Heading>

            <Form />

            <Box>
              <Text display="block" color="muted">{signupScreen.alreadyHaveAnAccount} <RoutedLink href="/login" color="neutral">{loginScreen.title}</RoutedLink></Text>
            </Box>
          </Box>
        </Pane>
      </Pane>
    )
  }
}

export default inject('auth')(observer(Signup));
