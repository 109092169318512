import translate from "app/i18n"
import NotesTreeStore from "app/stores/NotesTreeStore"
import { Badge, Heading, Pane, Paragraph, Position, SideSheet, toaster } from "evergreen-ui"
import { inject, observer } from "mobx-react"
import * as React from "react"
import { useHistory } from "react-router-dom"
import Form from "../components/Variant/components/Form"
import Variant from "../models/Variant"
import UiStore from "../stores/UiStore"
import VariantsStore from "../stores/VariantsStore"

interface Props {
  ui?: UiStore,
  variants?: VariantsStore,
  notesTree?: NotesTreeStore,
  variant?: Variant
}

const VariantDialog = (observer((props: Props) => {
  const { variant, variants, notesTree } = props;
  const history = useHistory();
  const [shown, setShown] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const {variantDialog} = translate()

  return <React.Fragment>
    <SideSheet isShown={shown} width={385} onCloseComplete={() => { props.ui?.hideDialog(); }}>
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16} borderBottom="muted">
          <Heading size={600}>{variant ? variantDialog.title : variantDialog.newTitle} {variant?.isMain && <Badge color="orange">{variantDialog.main}</Badge>} </Heading>
          {!variant &&
            <Paragraph size={400} color="muted">{variantDialog.description}</Paragraph>
          }
        </Pane>
      </Pane>

      <Pane flex="1" backgroundColor="white" padding={16}>
        <Form id={variant?.id} title={variant?.title} lang={variant?.lang} slug={variant?.slug} isMain={variant?.isMain} isLoading={isLoading} isAllowDelete={variants.data.length > 1}
          onSubmit={async (state) => {
            try {
              setLoading(true);
              const item = await (variant?.id ? variants.update({ ...state, id: variant?.id }) : variants.create(state));
 
              history.push(`/home/${item.id}`);
              setShown(false);
              props.ui?.hideDialog();
            } catch (e) {
              setLoading(false);
              toaster.danger(e.message);
            }
          }}

          onCancel={() => {
            setShown(false);
          }}

          onDelete={async () => {
            try {
              setLoading(true);
              await variants.delete(variant?.id);
              notesTree.reset();
              history.push(`/home`);

              setShown(false);
              props.ui?.hideDialog();
            } catch (e) {
              toaster.danger(e.message);
            }
          }}

          onSetAsMain={async () => {
            try {
              await variants.setMain(variant?.id);
              setShown(false);
            } catch (e) {
              toaster.danger(e.message);
            }
          }}
        />
      </Pane>
    </SideSheet>
  </React.Fragment>
}))

export default inject('ui', 'variants', 'notesTree')(VariantDialog);