import * as React from 'react'
import { observer } from 'mobx-react';
import { Alert, Heading, Pane, SelectMenuItem, Text } from 'evergreen-ui';
import FeedbackCount from '../../models/FeedbackCount';
import FeedbackItem from './FeedbackItem';
import { Tablist, Tab, Filter } from '../Tablist';
import TimeIntervalSelect from './TimeIntervalSelect';
import Loader from '../Loader';
import translate from '../../i18n';

interface Props {
  dislikes: FeedbackCount[];
  neutrals: FeedbackCount[];
  likes: FeedbackCount[];
  isLoading?: boolean;
  onSelectInterval?(value: SelectMenuItem): void
}

const Feedbacks = observer((props: Props) => {
  const { dislikes, neutrals, likes, onSelectInterval, isLoading } = props;
  const [tab, setTab] = React.useState("likes");

  const {feedbacks, common} = translate();

  const renderItems = (items: FeedbackCount[]) => {
    if(isLoading) return <Loader />

    if(items.length === 0){
      return <Alert intent="none" marginBottom={16}>{common.noDataForGivenPeriod}</Alert>
    }

    return items.map(x => <FeedbackItem key={x.noteId} item={x} />)
  }

  return (
    <Pane display="flex" flex="1" flexDirection="column">
      <Heading size={600}>{feedbacks.title}</Heading>
      <Tablist marginTop={16} marginBottom={12}>
        <Tab isSelected={tab === "likes"} onSelect={() => setTab("likes")} marginLeft={0}>{feedbacks.likes}</Tab>
        <Tab isSelected={tab === "dislikes"} onSelect={() => setTab("dislikes")}>{feedbacks.dislikes}</Tab>
        <Tab isSelected={tab === "neutrals"} onSelect={() => setTab("neutrals")}>{feedbacks.neutrals}</Tab>
      </Tablist>

      <Pane display="flex" flex="0" flexDirection="column" minHeight={105}>
        <Pane display="flex" flex="1" justifyContent="space-between" alignItems="center" marginBottom={7} paddingX={7} minHeight={34}>
          <Text color="muted">{feedbacks.page}</Text>
          <Text color="muted">{feedbacks.count}</Text>
        </Pane>

        {tab === "likes" && renderItems(likes)}
        {tab === "dislikes" && renderItems(dislikes)}
        {tab === "neutrals" && renderItems(neutrals)}
      </Pane>
    </Pane>
  )
});

export default Feedbacks
