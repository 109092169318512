import * as React from 'react'
import { Switch, Route } from "react-router-dom";
import Authenticated from '../components/Authenticated';
import AuthenticatedRoutes from './authenticated';

import {Login, Signup, Forgot} from '../screens/Login'

const Routes: React.FC<{}> = ({}) => {
  return <Switch>
    <Route exact={true} path="/" component={Login}/>
    <Route exact={true} path="/login" component={Login}/>
    <Route exact={true} path="/signup" component={Signup}/>
    <Route exact={true} path="/forgot" component={Forgot}/>

    <Authenticated>
      <AuthenticatedRoutes />
    </Authenticated>
  </Switch>
}

export default Routes