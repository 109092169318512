import { Heading, Pane, SideSheet, Tab, Tablist, toaster } from "evergreen-ui"
import { inject, observer } from "mobx-react"
import * as React from "react"
import Uploady from "@rpldy/uploady";
import ProjectsStore from "../../stores/ProjectsStore";
import UiStore from "../../stores/UiStore";
import Note from "../../models/Note";
import Uploader from "./Uploader";
import { IResponse } from "../../utils/ApiClient";
import NotesStore from "../../stores/NotesStore";
import { useHistory } from "react-router-dom";
import translate from "app/i18n";

interface Props {
  ui?: UiStore,
  projects?: ProjectsStore,
  note?: Note,
  notes?: NotesStore
}

const ImportDialog = (observer((props: Props) => {
  const {note, projects, ui, notes} = props;

  const [type, setType] = React.useState<'file' | 'url'>('file');
  const history = useHistory();

  const {common, importDialog} = translate();

  const handleServiceChange = (type: string) => {
    // if (type === 'github') {
    //   setType('url');
    // }else{
      setType('file');
    // }
  }

  const handleImportsFinished = (res: IResponse) => {
    ui.setCurrentNote(null); // force re-render Note/Form -> Editor component
    ui.setCurrentNote(notes.add(res.data));
    history.replace(`/notes/${res.data.id}`);
    toaster.success(common.completedMessage);
  }

  return <React.Fragment>
      <SideSheet isShown={true} width={385} onCloseComplete={() => { props.ui?.hideDialog(); }}>
        <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
            <Pane padding={16} borderBottom="muted">
              <Heading size={600}>{importDialog.title}</Heading>
            </Pane>

            {/* URL not supported right now: <Pane display="flex" padding={8}>
              <Tablist>
                <Tab isSelected={type === 'file'} onSelect={() => setType('file')}>FILE</Tab>
                <Tab isSelected={type === 'url'} onSelect={() => setType('url')}>URL</Tab>
              </Tablist>
            </Pane> */}
        </Pane>
        <Pane flex="1" backgroundColor="white" padding={16}>
            <Uploady 
              multiple={false}
              destination={{url: "/api/v1/notes.import"}} accept=".docx, .md, .html"
              params={{id: note.id}}
              fileFilter={(file: File): boolean => {
                  return file.size < 5242880; // 5MB
                }
              }
              >

              <Uploader type={type} onServiceClick={handleServiceChange} onFinish={handleImportsFinished}/>
            </Uploady>
        </Pane>
      </SideSheet>
  </React.Fragment>
}));

export default inject('ui', 'projects', 'notes')(ImportDialog);