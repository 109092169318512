import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import Invite from "../models/Invite";
import { client, IResponse } from "../utils/ApiClient";

export default class InvitesStore extends BaseStore<Invite> {
  constructor(protected readonly rootStore: RootStore){
    super(rootStore, Invite, 'invite');
  }

  async accept(id: string): Promise<IResponse> {
    return client.post(`${this.modelName}s.accept`, {id: id});
  }
}