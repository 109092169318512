import * as React from 'react'
import { observer } from 'mobx-react';
import { Alert, Pane, SelectMenuItem, Text } from 'evergreen-ui';
import ViewCount from '../../models/ViewCount';
import ViewItem from './ViewItem';
import { Tab, Tablist } from '../Tablist';
import Loader from '../Loader';
import translate from '../../i18n';

interface Props {
  items: ViewCount[];
  isLoading?: boolean;
  onSelectInterval?(value: SelectMenuItem): void
}

const PopularViews = observer((props: Props) => {
  const { items, onSelectInterval, isLoading } = props;
  const {popularViews, common} = translate();

  const renderContent = () => {

    if(isLoading) return <Loader />

    return items.length === 0 ? <Alert intent="none" marginBottom={16}>{common.noDataForGivenPeriod}</Alert> : items.map(x => <ViewItem key={x.noteId} item={x} />)
  }

  return (
    <Pane display="flex" flex="1" flexDirection="column">
      <Tablist marginBottom={12}>
        <Tab isSelected marginLeft={0}>{popularViews.title}</Tab>
      </Tablist>

      <Pane display="flex" flex="0" flexDirection="column" minHeight={100}>
        <Pane display="flex" flex="1" justifyContent="space-between" alignItems="center" marginBottom={7} paddingX={7} minHeight={34}>
          <Text color="muted">{popularViews.page}</Text>
          <Text color="muted">{popularViews.views}</Text>
        </Pane>
        {renderContent()}
      </Pane>
    </Pane>
  )
});

export default PopularViews
