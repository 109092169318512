import ITranslation from "./ITranslation";
import enStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import base from "@andreyblinov/rich-markdown-editor/dist/dictionary";

const en: ITranslation = {
  timeAgo: buildFormatter(enStrings),
  common: {
    updatedMessage: 'Successfully updated',
    deletedMessage: 'Successfully deleted',
    savedMessage: 'Successfully saved',
    completedMessage: 'Successfully completed',
    errorMessage: 'Error while operation performing',
    btnSaveChanges: 'Save changes',
    btnSave: 'Save',
    btnCancel: 'Cancel',
    btnUpdate: 'Update',
    btnCreate: 'Create',
    btnDelete: 'Delete',
    btnBuy: "Buy",
    loading: 'Loading...',
    filterPlaceholder: 'Filter...',
    noResults: 'No results',
    project: 'Project',
    pagesNotFound: 'Pages not found',
    noDataForGivenPeriod: 'No data for given period',
    noInvitesPending: 'No invites pending confirmation',
    codeHere: 'Code here...',
    public: 'Public',
    private: 'Private',
    published: 'Published',
    email: 'Email',
    saved: 'Saved',
    saving: 'Saving',
    haveOverduePayment: 'Hello, we want to remind you that you have an overdue license payment',
    or: "or",
    loggedAsReadOnlyAccount: 'You are logged in as a read-only account, some features will not be available',
  },
  projectMenu: {
    paid: "Paid",
    trial: "Trial",
    member: "member",
    members: "members"
  },
  backlinks: {
    title: "backlink",
    pluralTitle: "backlinks"
  },
  authForm: {
    failed: 'Authentication failed - we were unable to sign you in at this time. Please try again.',
    continueWith: 'Continue with',
    enterYourEmail: 'Enter your email address...',
    weJustSentYouLoginLink: 'We just sent you a temporary login link. Please check your inbox.',
  },
  forgotScreen: {
    title: 'Forgot password?',
    documentTitle: 'Forgot your password? – Wannadocs',
    dontHaveAnAccount: 'Don\'t have an account yet?'
  },
  signupScreen: {
    title: 'Sign up',
    documentTitle: 'Sign up – Wannadocs',
    alreadyHaveAnAccount: 'Already have an account?'
  },
  loginScreen: {
    title: 'Sign in',
    documentTitle: 'Sign in – Wannadocs',
  },
  newProject: {
    title: 'Create project'
  },
  backupScreen: {
    title: 'Backup',
  },
  backupForm: {
    title: 'Export',
    subtitle1: 'A full export might take some time, consider exporting a single document or collection if possible.',
    subtitle2: 'We’ll put together a zip of all your documents in Markdown format and email it to:',
    beingCreated: 'The backup is being created and will be sent soon',
    btnExport: 'Export'
  },
  importDialog: {
    title: 'Import',
    importContentFrom: 'Import content from',
    uploadYourNotesAs: 'Upload your notes as'
  },
  noteOptions: {
    title: 'More options',
    subtitle: 'Edit your note\'s description, and tags.',
    slug: 'Slug',
    refreshSlugFromTitle: 'Refresh slug from title',
    openArticle: 'Open article',
    description: 'Description',
    descriptionPlaceholder: 'SEO description',
    keywords: 'Keywords',
    keywordsPlaceholder: 'Add [Enter]...',
    tableOfContents: 'Table of contents',
    btnApplyChangesAndClose: 'Apply changes & close'
  },
  noteForm: {
    thisPageIsInTrash: 'This page is in Trash',
    giveYourNoteTitle: 'Give your note a title',
    resumeLastEditingSession: 'Resume last editing session?',
    thereWereUnsavedChanges: 'There were unsaved changes during your last editing session. You can restore these changes and resume editing, or discard them.',
    sessionFrom: 'Session from',
    editorStartWriting: 'Click here to start writing'
  },
  moveTo: {
    title: 'Move to variant',
    moving: 'Moving',
    to: 'to',
    btnMove: 'Move'
  },
  noteSharing: {
    title: 'Share to web',
    subtitle: 'Anyone with the link can view',
    copyLink: 'Copy link',
    thisIsA: 'This is a',
    searchIndexing: 'note, search indexing',
    enabled: 'enabled',
    disabled: 'disabled'
  },
  noteMenu: {
    creatingPage: 'Creating new page, please wait...',
    creatingDuplicate: 'Creating duplicate, please wait...',
    generatingPdf: 'Generating PDF, please wait...',
    pdfGeneratingError: 'PDF generating error',
    newChildPage: 'New child page',
    duplicate: 'Duplicate',
    moveToAnotherVariant: 'Move to another variant',
    share: 'Share',
    removeFromFavorites: 'Remove from favorites',
    addToFavorites: 'Add to favorites',
    more: 'Tags & Description',
    import: 'Import',
    exportAsPDF: 'Export as PDF',
    restore: 'Restore',
    delete: 'Delete'
  },
  iconSelector: {
    emoji: 'Emoji',
    uploadAnImage: 'Upload an image',
    remove: 'Remove',
    addIcon: 'Add icon'
  },
  imageFileInput: {
    dropYourFiles: 'Drop your file here',
    orBrowse: 'or browse'
  },
  fileUploaderInput: {
    dropYourFiles: 'Drop your file here',
    orBrowse: 'or browse',
    processing: 'Processing...'
  },
  restrictAccess: {
    title: 'Restrict Access',
    password: 'Password',
    generate: 'Generate',
    descripton: 'The content will only be visible to those with the password. It won’t be indexed on Google.'
  },
  restrictAccessIp: {
    title: 'Restrict Access by IP',
    ips: 'IPs',
    ipsPlaceholder: 'Enter IPs separated by commas: 198.0.0.1, 0.0.0.0/32, etc',
    descripton: 'The content will only be visible to those with the IP. It won’t be indexed on Google.'
  },
  shareScreen: {
    title: 'Share'
  },
  shareableLink: {
    title: 'Shareable Link',
    open: 'Open',
    thisProjectIsPublic: 'This projects is public. Anyone with this URL can read it but not edit it.',
    copyLink: 'Copy link',
    youCanConfigureThisUrl: 'You can configure this URL in the',
    thisProjectIsPrivate: 'This project is private and does not have a shareable link.'
  },
  visibility: {
    title: 'Visibility',
    textForPublic: 'Your public content will be available publicly to anyone.',
    textForPrivate: 'The content will only be visible to your team and yourself.'
  },
  billingScreen: {
    title: 'Billing',
    licenseActivated: 'License successfully activated',
    btnApplyLicense: 'Apply license',
    active: 'Active',
    pagesUsage: 'Pages usage',
    onThePaidPlanHaveUnlimitedPages: 'On the paid plan, your workspace have unlimited pages.',
    onTheFreePlanHasLimitedPages: 'On the free plan, your workspace has limited pages.',
    availableStorage: 'Available storage',
    onThePaidPlanYouImages: 'On the payed plan, your images and attachments storage is limited to 5 GB.',
    onTheFreePlanYouImages: 'On the free plan, your images and attachments storage is limited to 100 MB.',
  },
  customCss: {
    title: 'Custom CSS'
  },
  customJs: {
    title: 'Custom Javascript'
  },
  integrationsScreen: {
    title: 'Integrations'
  },
  intercomChat: {
    title: 'Intercom chat'
  },
  intercomMessenger: {
    title: 'Intercom Wannadocs App',
    connected: 'Connected',
    status: 'Status',
    reinstall: 'Reinstall with Intercom',
    install: 'Install with Intercom'
  },
  invitationsScreen: {
    btnInvite: 'Invite members',
    btnSendInvites: 'Send invites',
    btnResend: 'Resend',
    title: 'Invitations',
    addPeopleTo: 'Add people to',
    emails: 'Emails',
    invitesSended: 'Invites sended successfully',
    invitesResended: 'Invite resended successfully',
    sendedAt: 'sended at',
    pending: 'Pending'
  },
  projectSettingsScreen: {
    title: 'Project settings'
  },
  projectForm: {
    projectName: 'Project name',
    projectNamePlaceholder: 'Name of your project',
    homePageUrl: 'Home page url',
    theme: 'Theme',
    logo: 'Logo',
    favicon: 'Favicon',
    defaultDomain: 'Default domain',
    customDomain: 'Custom domain',
    color: 'Color',
    dnsTooltip: 'Add a CNAME record to your domain DNS records:',
    storeFrontHome: 'home page',
    firstPageHint: 'The first page as root',
    listingPagesHint: 'Listing pages with icons',
    footerText: 'Footer text',
    addMetaTag: 'Add meta tag',
    metaTagsTitle: 'Meta tags',
  },
  membersScreen: {
    title: 'Members',
    noMembersFound: 'No members found',
    filterMembers: 'Filter members...',
    selectRole: 'Select role'
  },
  roles: {
    owner: 'Owner',
    readOnly: 'Read only',
    admin: 'Admin',
    editor: 'Editor'
  },
  list: {
    empty: 'No items',
    loadMore: 'Load more'
  },
  notesTree: {
    noPagesInside: 'No pages inside',
    private: 'Private',
    toc: 'TOC',
    edit: 'Edit',
    newPage: 'New page',
    delete: 'Delete'
  },
  createButton: {
    title: 'Create page',
    noteTitle: 'Untitled',
    noteBody: '# Welcome'
  },
  homeScreen: {
    title: 'Content',
    subtitle: 'This is the content of your knowledge base.',
    filterNotes: 'Filter notes...'
  },
  analyticsScreen: {
    title: 'Analytics',
    subtitle: 'Some useful analytics for your knowledge base.',
    selectInterval: 'Select interval',
    intervals: {
      'day': 'Last day',
      'week': 'Last week',
      'month': 'Last month',
      'six_months': 'Last 6 months',
      'year': 'Last year',
    }
  },
  trashScreen: {
    title: 'Trash',
    subtitle: 'This pages will be permanently deleted in 30 days unless restored.',
    deleted: 'deleted',
    restore: 'Restore'
  },
  popularViews: {
    title: 'Popular pages',
    page: 'Page',
    views: 'Views',
  },
  feedbacks: {
    title: 'Feedbacks',
    page: 'Page',
    count: 'Count',
    likes: 'Likes',
    dislikes: 'Dislikes',
    neutrals: 'Neutrals'
  },
  allUpdatesScreen: {
    title: 'All updates',
    subtitle: 'Updates for all pages in the knowledge base.',
    projectUpdates: 'Project updates'
  },
  dashboardScreen: {
    title: 'Workspaces overview',
    subtitle: 'Quick summary for your all projects.',
    recent: 'Recent',
    workedOn: 'Worked on',
    drafts: 'Drafts',
    favorites: 'Favorites',
    noRecentPages: 'No recent pages',
    thisListWillGrow: 'This list will grow as you create, edit, and explore pages.'
  },
  allUpdatesScreenListItem: {
    actions: {
      'notes.created': 'Created the',
      'notes.deleted': 'Deleted the',
      'notes.restored': 'Restored the',
      'notes.updated': 'Updated the',
      'notes.moved': 'Moved the',
      'notes.imported': 'Imported the',
      'notes.set_logo': 'Setted',
      'notes.remove_logo': 'Removed',
      'notes.set_visible_status': 'Visibility status changed',

      'variants.created': 'Created the',
      'variants.deleted': 'Deleted the',
      'variants.updated': 'Updated the',
    },
    types: {
      'note': 'note',
      'variant': 'variant'
    }
  },
  variantDialog: {
    title: 'Variant',
    newTitle: 'New variant',
    description: 'Create alternative content to document multiple versions of an API or library; or translations.',
    main: 'Main',
  },
  variantForm: {
    name: 'Variant name',
    namePlaceholder: 'ex: v2.0.0, English, etc',
    slug: 'Slug',
    slugPlaceholder: 'english, en, etc',
    chooseLang: 'Choose a language',
    chooseLangPlaceholder: 'Language',
    setAsMain: 'Set as main variant'
  },
  variantSelectMenu: {
    title: 'Select variant'
  },
  accountMenu: {
    settings: 'Settings',
    documentation: 'Documentation',
    contactSupport: 'Contact Support',
    logout: 'Logout',
    locale: 'Dashboard locale'
  },
  personalSettingsDialog: {
    title: 'Personal settings',
    photo: 'Photo',
    fullName: 'Full name',
    username: 'Username',
    email: 'Email address',
  },
  sidebar: {
    content: "Content",
    allUpdates: "All updates",
    analytics: "Analytics",
    trash: "Trash",
    settings: "Settings",
    recent: "Recent",
    project: "Project",
    projectSettings: "Project settings",
    members: "Members",
    invitations: "Invitations",
    integrations: "Integrations",
    billing: "Billing",
    backup: "Backup",
    share: "Share",
    openKB: "Open Knowledge base"
  },
  header: {
    dashboard: "Dashboard",
    changeProject: "Change project",
    projects: "Projects..."
  },
  searchDialog: {
    placeholder: "Search your workspace...",
    empty: "Nothing has been found.",
    error: "Error while searching:",
    search: "Search",
    results: "results",
    select: "Select",
    open: "Open"
  },
  deleteArticleDialog: {
    title: "Delete article?",
    subtitle: "Are you sure you want to delete this article?"
  },
  welcomeScreen:{
    title: "Welcome!",
    subtitle: "To get started, create a project."
  },
  deleteProjectDialog: {
    title: "Delete this project",
    subtitle: "Once you delete a project, there is no going back. Please be certain."
  },
  paywall: {
    title: 'Ready to upgrade?',
    subtitle: 'Looks like you need a bigger plan to suit your tasks.',
    btnUpgrade: 'Upgrade now!'
  },
  billingQuota: {
    title: 'Quota',
    used: 'Used',
    available: 'Available',
    usage: 'usage',
    pages: 'Pages',
    contentVariants: 'Content variants',
    members: 'Members',
    fileUploads: 'File uploads',
    languages: 'Languages',
    unlimited: 'Unlimited'
  },
  purchases: {
    title: 'Purchases',
    createdAt: 'Created at',
    nextBillDate: 'Next bill date',
    validThrough: 'Valid through',
    paid: 'Paid',
    getReceipt: 'Get receipt',
    updatePaymentMethod: 'Update payment method',
    refund: 'Refund',
    cancelSubscription: 'Cancel subscription',
    wasCanceled: 'Your subscription was canceled.',
    complete: 'Purchase completed successfully, you need to wait a few minutes to activate',
    statuses: {
      'active': 'Active',
      'pending': 'Pending',
      'past_due': 'Past due',
      'deleted': 'Deleted',
    }
  },
  pricingPlans: {
    title: 'Pricing plans',
    names: {
      'trial': 'Trial',
      'personal_monthly': 'Starter',
      'personal_yearly': 'Starter',
      'team_monthly': 'Team',
      'team_yearly': 'Team',
      'enterprise': 'Enterprise',
    },
    twoMonthFree: '1 month free',
    perMonth: 'per month',
    yearly: 'yearly',
    payAnnually: 'Pay annually',
    contactSales: 'Contact sales',
    usage: 'usage',
    pages: 'Pages',
    contentVariants: 'Content variants',
    members: 'Members',
    fileUploads: 'File uploads',
    languages: 'Languages',
    features: 'Features',
    editorBlocksAndEmbeds: 'Editor blocks & embeds',
    customDomain: 'Custom domain',
    sslCertificate: 'SSL certificate',
    analytics: 'Analytics',
    integrations: 'Integrations',
    sharePermissions: 'Share permissions',
    accessNewFeatureEarly: 'Access new features early',
    themeAdaptation: 'Theme adaptation',
    openSource: 'Open source',
    selfHosted: 'Self-hosted (optional)',
    support: 'Support',
    emailAndLiveChat: 'Email & Live chat',
    prioritySupport: 'Priority support',
    dedicatedManager: 'Dedicated manager',
    unlimited: 'Unlimited',
    justYou: 'Just you',
    languagesCount: 'languages',
  },
  editor: base
}

export default en;
