import BaseModel from "./BaseModel";
import {
    date,
    serializable
} from "serializr"
import {action, makeObservable, observable} from "mobx";

export default class MetaTag extends BaseModel{
    @serializable
    key: string = null;
    @serializable
    value: string = null;

    constructor(id: string, key: string, value: string){
        super();
        this.id = id;
        this.key = key;
        this.value = value;
    }
}
