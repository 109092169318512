import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import List from 'app/components/List';
import ListItem from './ListItem';
import NotesStore from 'app/stores/NotesStore';
import { runInAction } from 'mobx';
import Breadcrumbs from 'app/components/Breadcrumbs';
import ProjectsStore from 'app/stores/ProjectsStore';
import translate from 'app/i18n';
import { Heading, Paragraph } from 'app/components/Typography';

interface Props {
  notes?: NotesStore,
  projects?: ProjectsStore
}

const Trash = observer((props: Props) => {
  React.useEffect(() => {
    document.title = "Wannadocs - Trash";
  }, []);

  const { notes, projects } = props;
  const [isLoading, setLoading] = React.useState(false);

  const {trashScreen} = translate();

  useEffect(() => {
    if (notes.discarded.length === 0) {
      setLoading(true);
    }
    runInAction(() => notes.discarded.clear());
    notes.loadDiscarded().then(() => setLoading(false));
  }, [])

  return (<>
    <Pane display="flex" flex="1" backgroundColor="white">
      <Pane display="flex" flex="1" flexDirection="column" paddingX={17} paddingY={24}>
        <Pane borderBottom="default" paddingBottom={16} marginX={15} display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center" marginBottom={20} justifyContent="space-between">
            <Heading size={900}>{trashScreen.title}</Heading>
          </Pane>

          <Pane paddingBottom={16}>
            <Paragraph size={400}>{trashScreen.subtitle}</Paragraph>
          </Pane>
        </Pane>

        <Pane marginX={15} marginTop={4}>
          <List isLoading={isLoading} data={notes.discarded}
            onClickLoadMore={async () => {
              const cursor = notes.discarded[notes.discarded.length - 1];
              return await notes.loadDiscarded(cursor.id)
            }}
            onItemRender={(item) => {
              return <ListItem key={item.id} note={item}/>
            }}
          />
        </Pane>
      </Pane>
    </Pane>
    </>
  )
});

export default inject('notes', 'projects')(Trash);