import { ArrowBottomLeftIcon, Button, CaretDownIcon, CircleIcon, defaultTheme, MediaIcon, Menu, Pane, Popover, Position, SelectMenu, Spinner, SymbolCircleIcon, Tab, Tablist, Text, toaster } from 'evergreen-ui';
import * as React from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { inject, observer } from 'mobx-react';
import NotesStore from '../../../stores/NotesStore';
import Note from '../../../models/Note';
import styled from 'styled-components';
import translate from 'app/i18n';
import SquaredAvatar from 'app/components/SquaredAvatar';
import BacklinkItem from 'app/models/BacklinkItem';
import { useHistory } from 'react-router';

interface Props {
  note: Note,
  notes?: NotesStore
}

const BacklinksSelector: React.FC<Props> = ({note, notes}) => {

  const {backlinks} = translate();

  const history = useHistory();

  const logo = (backlink: BacklinkItem) => {
    if(backlink.logoType === "none"){
      return <></>
    }

    return (
      <Pane display="flex" width={20} height={20} justifyContent="center" alignItems="center" marginRight={6}>
        {backlink.logoType === "file" && <SquaredAvatar size={16} src={backlink.logoPresentable} />}
        {backlink.logoType === "emoji" && <Pane dangerouslySetInnerHTML={{__html: backlink.logoPresentable}}/>}
      </Pane>
    )
  }

  const handleSelectLink = (backlink: BacklinkItem) => {
    return () => {
      history.push(`/notes/${backlink.id}`);
    }
  }

  return <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              {note.backlinks.map(backlink => {
                return <Menu.Item onSelect={handleSelectLink(backlink)} display="flex" icon={ArrowBottomLeftIcon} key={backlink.id}>
                  <Pane display="flex" alignItems="center" justifyContent="flex-start">{logo(backlink)} {backlink.title}</Pane>
                </Menu.Item>
              })}
            </Menu>
          }
        >
        <Pane marginBottom={10} marginLeft={8}>
          <Button
              type="button"
              style={{ color: defaultTheme.colors.text.default }}
              iconBefore={ArrowBottomLeftIcon}
              marginTop={6}
              appearance="minimal">

              {note.backlinks.length} {note.backlinks.length === 1 ? backlinks.title : backlinks.pluralTitle}
          </Button>
        </Pane>
  </Popover>
}

const ItemTextWrapper = styled(Text)`
  &:hover{
    color: ${defaultTheme.colors.text.selected};
  }
`

const ItemWrapper = styled(Pane)`
  width: 100%;
  cursor: pointer;
  flex: 1;
  display: flex;
  padding: 6px 12px;
  align-items: center;

  &:hover ${ItemTextWrapper}{
    color: ${defaultTheme.colors.text.selected};
  }

  &:hover{
    background-color: ${defaultTheme.colors.background.tint2};
  }
`

export default inject('notes')(observer(BacklinksSelector));