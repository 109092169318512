import * as React from 'react'
import { inject, observer } from 'mobx-react';
import { defaultTheme, Button, TrashIcon, InheritanceIcon, EditIcon, toaster, Dialog } from 'evergreen-ui';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import Note from '../../../models/Note';
import NotesStore from '../../../stores/NotesStore';
import NotesTreeStore from '../../../stores/NotesTreeStore';
import UiStore from '../../../stores/UiStore';
import RecentsStore from '../../../stores/RecentsStore';
import translate from 'app/i18n';
import ProjectsStore from 'app/stores/ProjectsStore';

interface Props {
  note: Note,
  ui?: UiStore,
  notes?: NotesStore,
  notesTree?: NotesTreeStore,
  recents?: RecentsStore,
  projects?: ProjectsStore,
  handleDelete?(e: React.SyntheticEvent)
}

const Menu = (observer((props: Props) => {
  const history = useHistory();
  const { note, notes, notesTree, ui, recents, projects, handleDelete } = props;
  const [creating, setCreating] = React.useState(false);
  const i18n = translate();

  const handleViewNote = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    ui.setCurrentNote(null);

    recents.add({id: note.id, title: note.title, projectId: note.projectId});
    history.push(`/notes/${note.id}`);
  }

  // const handleDelete = (e: React.SyntheticEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   notesTree.setExpanded(note.id);
  //   // setShowDeletingDialog(true);
  //   // notesTree.remove(note.id);

  //   // notes.delete(note.id);
  // }

  const handleNewNote = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setCreating(true);

    try {
      const newNote = await notes.create({
        parent_id: note.id,
        text: "\\",
        title: i18n.createButton.noteTitle,
        variant_id: note.variantId,
        is_draft: true,
        type: "text"
      });

      notesTree.setExpanded(note.id);
      setCreating(false);

      recents.add({id: newNote.id, title: newNote.title, projectId: newNote.projectId});
      history.push(`/notes/${newNote.id}`);
    } catch (e) {
      console.error(e);
      setCreating(false);
      toaster.danger(`${i18n.common.errorMessage}: ${e.message}`);
    }
  }

  return (
    <Wrapper>
      <Button onClick={handleViewNote} style={{ color: defaultTheme.colors.text.default }} marginRight={14} appearance="minimal" iconBefore={EditIcon}>
        {i18n.notesTree.edit}
      </Button>
      <Button disabled={projects.isReadonlyUser} onClick={handleNewNote} style={{ color: defaultTheme.colors.text.default }} marginRight={14} appearance="minimal" iconBefore={InheritanceIcon} isLoading={creating}>
        {i18n.notesTree.newPage}
      </Button>
      {handleDelete && <Button disabled={projects.isReadonlyUser} onClick={handleDelete} intent="danger" marginRight={14} appearance="minimal" iconBefore={TrashIcon}>
        {i18n.notesTree.delete}
      </Button>}
    </Wrapper>
  )
}));

export const Wrapper = styled('div')`
  margin-left: 0px;
  color: ${defaultTheme.colors.text.default};
  margin-left: 14px;
  visibility: hidden;
`

export default inject('notes', 'variants', 'notesTree', 'ui', 'recents', 'projects')(Menu);