import * as React from 'react'
import { Text, Pane, Strong } from 'evergreen-ui';
import styled from 'styled-components';

interface Props {
  label: string,
}

const SidebarSection = ((props: Props) => {
  const { label } = props;

  return (
    <Wrapper>
      <Text color="muted" size={300} fontWeight={500}>{label}</Text>
    </Wrapper>
  )
});

const Wrapper = styled(Pane)`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 27px;
  padding: 2px 14px;
`

export default SidebarSection;