import {
  serializable
} from "serializr"

export default class Recent {
  @serializable
  id: string = null;

  @serializable
  projectId: string = null;

  @serializable
  title: string = null;
}