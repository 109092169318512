
interface PricePlan {
  monthly: {
    id: string,
    paymentProcessorPlanId: string,
    priceUsd: Number
  },
  yearly: {
    id: string,
    paymentProcessorPlanId: string,
    priceUsd: Number
  }
}

interface Config {
  env: string,
  pricingPlans: {
    personal: PricePlan,
    team: PricePlan,
  }
}

interface SuperdocsWindow extends Window {
  superdocs: Config;
}

declare var window: SuperdocsWindow;

const Application = {
  config: window.superdocs,
  isMacOs: () => { return navigator.platform.toUpperCase().indexOf('MAC') >= 0; },
  modKey: () => { return Application.isMacOs() ? '⌘' : 'Ctrl'; },
}

export default Application;