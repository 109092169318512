import { Alert, Button, Pane, Text, TextInput, toaster } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react'
import AuthStore from '../../stores/AuthStore';
import { useState } from 'react';
import Divider from '../../components/Divider';
import { GitHub, Mail, Slack } from 'react-feather';
import { client } from '../../utils/ApiClient';
import translate from 'app/i18n';

interface Props {
  auth?: AuthStore
}

const Form: React.FC<Props> = ({ auth }) => {
  const [hideSubmitButton, setHideSubmitButton] = useState(false);
  const [email, setEmail] = useState("");
  const {common, authForm} = translate(true);

  const renderErrors = () => {
    if (window.location.hash === '#auth-error') {
      return <Alert intent="warning" title={authForm.failed} marginBottom="1em" />
    }
    return <></>
  }

  const handleContinueWithGithub = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.href = auth.config.find(x => x.name === 'github').callbackUrl
  }

  const handleContinueWithSlack = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.href = auth.config.find(x => x.name === 'slack').callbackUrl
  }

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      try{
        const res = await client.auth(email);
        if (res.ok) {
          setHideSubmitButton(true);
        }
      }catch(e){
        console.error(e);
        toaster.danger(`Error while login: ${e.message}`);
      }
    }
  }

  return <Pane>
    {renderErrors()}

    {auth.providers.includes('github') && <Button iconBefore={GitHub} height={40} width="100%" marginBottom="1em" onClick={handleContinueWithGithub}>{authForm.continueWith} Github</Button>}
    {auth.providers.includes('slack') && <Button iconBefore={Slack} height={40} width="100%" marginBottom="1em" onClick={handleContinueWithSlack}>{authForm.continueWith} Slack</Button>}

    <Divider />
    <Pane is="form" onSubmit={handleFormSubmit}>
      <TextInput
        type="email" name="email"
        required={true}
        placeholder={authForm.enterYourEmail}
        marginBottom="1em" marginTop="1em"
        onChange={handleEmailChange}
        height={40} width="100%" />

      {hideSubmitButton ?
        <Text display="block" color="muted" marginBottom="1em">{authForm.weJustSentYouLoginLink}</Text>
        :
        <Button
          iconBefore={Mail}
          appearance="primary"
          height={40} width="100%"
          type="submit"
          marginBottom="1em">{authForm.continueWith} Email</Button>
      }
    </Pane>
  </Pane>
}

export default inject('auth')(observer(Form));
