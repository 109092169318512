import translate from 'app/i18n';
import Loader from 'app/components/Loader';
import { Pane } from 'evergreen-ui';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import IntegrationsStore from '../../../stores/IntegrationsStore';
import Layout from '../Layout';
import ChatraPanel from './components/ChatraPanel';
import CustomCssPanel from './components/CustomCssPanel';
import CustomJavascriptPanel from './components/CustomJavascriptPanel';
import DisqusPanel from './components/DisqusPanel';
import IntercomChatWidgetPanel from './components/IntercomChatWidgetPanel';
import IntercomMessengerPanel from './components/IntercomMessengerPanel';
import ProjectsStore from 'app/stores/ProjectsStore';
import Paywall from 'app/components/Paywall';

interface Props {
  projects?: ProjectsStore,
  integrations?: IntegrationsStore
}

const Integrations = observer((props: Props) => {
  const {integrations, projects} = props;
  const [loading, setLoading] = React.useState(true);
  const [active, setActive] = React.useState<string>("CustomJavascriptPanel");
  const {integrationsScreen} = translate();

  React.useEffect(() =>{
    setLoading(true);
    (async () => {
      integrations.list().then(() => setLoading(false));
    })();
  }, []);

  if(projects.current?.availableQuota?.hasIntegrations === false){
    return <Paywall />
  }

  return <Layout header={integrationsScreen.title}>
    {loading ? <Loader /> :
      <Pane display="flex" flex="1" background="white" paddingTop={16} flexDirection="column" padding={10}>
        <CustomJavascriptPanel integration={integrations.customJs} isShown={active === "CustomJavascriptPanel"} onClick={() => setActive("CustomJavascriptPanel")}/>

        <Pane marginTop={16}>
          <CustomCssPanel integration={integrations.customCss} isShown={active === "CustomCssPanel"} onClick={() => setActive("CustomCssPanel")}/>
        </Pane>

        <Pane marginTop={16}>
          <ChatraPanel integration={integrations.chatra} isShown={active === "ChatraPanel"} onClick={() => setActive("ChatraPanel")}/>
        </Pane>

        <Pane marginTop={16}>
          <DisqusPanel integration={integrations.disqus} isShown={active === "DisqusPanel"} onClick={() => setActive("DisqusPanel")}/>
        </Pane>

        <Pane marginTop={16}>
          <IntercomMessengerPanel integration={integrations.intercomMessenger} isShown={active === "IntercomMessengerPanel"} onClick={() => setActive("IntercomMessengerPanel")}/>
        </Pane>

        <Pane marginTop={16}>
          <IntercomChatWidgetPanel integration={integrations.intercomChat} isShown={active === "IntercomChatWidgetPanel"} onClick={() => setActive("IntercomChatWidgetPanel")}/>
        </Pane>
      </Pane>
    }
  </Layout>
});

export default inject('integrations', 'projects')(Integrations);