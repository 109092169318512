import Note from "app/models/Note";
import { client, IResponse } from "app/utils/ApiClient";
import { action, IObservableArray, makeObservable, observable, runInAction } from "mobx";
import { deserialize } from "serializr";
import RootStore from "./RootStore";

export default class SummaryStore {
  workedOn: IObservableArray<Note> = observable<Note>([]);
  drafts: IObservableArray<Note> = observable<Note>([]);
  favorites: IObservableArray<Note> = observable<Note>([]);

  constructor(private readonly rootStore: RootStore){
    makeObservable(this, {
      workedOn: observable,
      drafts: observable,
      favorites: observable,

      loadWorkedOn: action,
      loadDrafts: action,
      loadFavorites: action,
    });
  }

  async loadWorkedOn() {
    const res = await client.post(`summary.worked_on`);
    const notes = res.data.map(x => observable(deserialize(Note, x)));
    runInAction(() => {
      this.workedOn.replace(notes);
    });
  }

  async loadDrafts(cursorId?: string): Promise<IResponse> {
    const res = await client.post(`summary.drafts`, {cursorId: cursorId});
    const notes = res.data.map(x => observable(deserialize(Note, x)));

    runInAction(() => {
      this.drafts.replace(this.drafts.concat(notes));
    });

    return res;
  }

  async loadFavorites(cursorId?: string): Promise<IResponse> {
    const res = await client.post(`notes.favorites`, {cursorId: cursorId});
    const notes = res.data.map(x => observable(deserialize(Note, x)));

    runInAction(() => {
      this.favorites.replace(this.favorites.concat(notes));
    });

    return res;
  }
}