import ITranslation from "./ITranslation";
import en from "./en";
import ru from "./ru";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
}

const translate = (checkQuery: boolean = false): ITranslation => {
  let locale = getLocale();

  if(checkQuery){
    let localeFromQuery = getQueryVariable("dashboard.locale");
    if(localeFromQuery && (localeFromQuery === "ru" || localeFromQuery === "en")){
      locale = localeFromQuery;
      setLocale(locale);
    }
  }

  if(locale === "ru"){
    return ru;
  }else{
    return en;
  }
}

export const LOCALE_KEY = "Superdocs.DashboardLocale";

export const getLocale = (): string => {
  try{
    return localStorage.getItem(LOCALE_KEY) || "en";
  }catch(e){
    console.error(e);
    return "en";
  }
}

export const setLocale = (locale: string) => {
  try{
    localStorage.setItem(LOCALE_KEY, locale);
  }catch(e){
    console.error(e);
  }
}

export const isLocale = (locale: string): boolean => {
  return getLocale() === locale;
}

export default translate;