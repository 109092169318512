import * as React from 'react';
import ProjectsStore from 'app/stores/ProjectsStore';
import { inject, observer } from 'mobx-react';
import { Button, defaultTheme, Pane, ShareIcon, Strong, Switch, Text, TextInput } from 'evergreen-ui';
import Panel from 'app/components/Panel';
import { Link } from 'react-router-dom';
import translate from 'app/i18n';
import { MinimalButton, PrimaryButton } from 'app/components/Typography';

interface Props {
  isShown: boolean,
  onClick?: () => void,
  projects?: ProjectsStore
}

const ShareableLinkPanel = observer((props: Props) => {
  const {projects, isShown, onClick} = props;
  const {shareableLink, projectSettingsScreen} = translate();

  const handleCopyLink = (e: React.SyntheticEvent) => {
    e.preventDefault();

    navigator.clipboard.writeText(projects.current?.domain);
  }

  const handleOpenLink = (e: React.SyntheticEvent) => {
    e.preventDefault();

    window.open(projects.current?.domain);
  }

  return <Panel header={<Strong>{shareableLink.title}</Strong>}
                isShown={isShown} onClick={onClick}
                footer={projects.current?.isPublicVisibility ? <PrimaryButton appearance="primary" iconBefore={ShareIcon} onClick={handleOpenLink}>{shareableLink.open}</PrimaryButton> : null}>
    
    {projects.current?.isPublicVisibility &&
      <Pane>
        <Text>{shareableLink.thisProjectIsPublic}</Text>
        <Pane display="flex" marginY={12}>
          <TextInput disabled value={projects.current?.domain}/>
          <MinimalButton marginLeft={2} onClick={handleCopyLink}>{shareableLink.copyLink}</MinimalButton>
        </Pane>
        <Text>{shareableLink.youCanConfigureThisUrl} <Link to="/settings/project">{projectSettingsScreen.title}</Link></Text>
      </Pane>
    }

    {projects.current?.visibilityStatus === "private" &&
      <Pane>
        <Text>{shareableLink.thisProjectIsPrivate}</Text>
      </Pane>
    }
  </Panel>
});

export default inject('projects')(ShareableLinkPanel);