import User from "../models/User";
import RootStore from "./RootStore";
import { getCookie, removeCookie } from "tiny-cookie";
import { action, autorun, computed, flow, IObservableArray, makeObservable, observable, runInAction, set, toJS } from "mobx";
import { client, IResponse } from "../utils/ApiClient";
import { deserialize, serialize } from "serializr";


interface Config {
  name: string
  callbackUrl?: string
}

export default class AuthStore {
  user?: User = null;
  token?: string = null;
  config?: IObservableArray<Config> = [] as any;

  constructor(private readonly rootStore: RootStore){
    makeObservable(this, {
      user: observable,
      token: observable,
      config: observable,
      authenticated: computed,
      providers: computed,
      fetch: action,
      fetchConfig: action,
      clear: action,
      update: action,
      role: computed,
    });

    this.token = getCookie('access_token');

    setTimeout(() => this.fetchConfig())

    if (this.token){
      setTimeout(() => this.fetch());
    }
  }

  get authenticated(): boolean {
    return !!this.token;
  }

  clear() {
    // TODO: move to config file
    removeCookie('access_token', { domain: '.wannadocs.com' });
    removeCookie('project_id', { domain: '.wannadocs.com' });
    // staging
    removeCookie('access_token', { domain: '.superdocs.dev' });
    removeCookie('project_id', { domain: '.superdocs.dev' });
    // clear old cookie with old domain
    removeCookie('access_token');
    removeCookie('project_id');

    this.token = null;
  }

  async fetch(): Promise<User | null>{
    try{
      const res = await client.post('auth.info');
      if (res && res.ok){
        const userInfo = deserialize(User, res.data.user);
        runInAction(() => {
          this.user = userInfo;
        });
        return userInfo;
      }else{
        this.clear();
      }
    }catch(err){
      this.clear();
      console.error(err);
    }
    return null;
  }

  async fetchConfig(): Promise<IResponse>{
    const res = await client.authConfig();
    if (res && res.ok){
      runInAction(() => this.config = res.data.config);
    }
    return res;
  }

  async update(params: Object | FormData): Promise<User>{
    const res = await client.post('users.update', params);
    runInAction(() => set(this.user, deserialize(User, res.data)));
    return this.user;
  }

  get providers() {
    return this.config.map(x => x.name)
  }
  
  get role(): string {
    return this.rootStore.projects.current.members.find(x => x.user.id === this.user?.id)?.role;
  }
}