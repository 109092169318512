import {
  list,
  primitive,
  serializable
} from "serializr"
import { IObservableArray, makeAutoObservable, observable } from "mobx";

export default class Quota {
  @serializable
  hasAnalytics: boolean = false;

  @serializable
  hasIntegrations: boolean = false;

  @serializable
  hasPrivateKb: boolean = false;

  @serializable(list(primitive()))
  languages: IObservableArray<string> = observable<string>([]);

  @serializable
  members: Number = null;

  @serializable
  pages: Number = null;

  @serializable
  spaceGb: Number = null;

  @serializable
  versionHistoryDays: Number = null;

  @serializable
  variants: Number = null;

  constructor(){
    makeAutoObservable(this);
  }
}