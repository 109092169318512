import * as React from 'react'
import { inject, observer } from 'mobx-react';
import AuthStore from '../stores/AuthStore';
import {Button, Text, defaultTheme, Heading, HomeIcon, Pane, PlusIcon, ProjectsIcon, SelectMenuItem, ErrorIcon, WarningSignIcon} from 'evergreen-ui'
import Sidebar from './Sidebar';
import UiStore from '../stores/UiStore';
import AccountMenu from 'app/menus/AccountMenu';
import ProjectsStore from 'app/stores/ProjectsStore';
import ActionSelectMenu from './ActionSelectMenu';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import NewProjectDialog from 'app/dialogs/NewProjectDialog';
import SearchDialog from 'app/dialogs/SearchDialog';
import translate from '../i18n';

interface Props {
  auth?: AuthStore,
  ui?: UiStore,
  projects?: ProjectsStore,
  header?: React.ReactNode
  children: React.ReactNode,
}

const Layout = (observer((props: Props) => {
    const {auth, ui, projects} = props;
    const history = useHistory();
    const dashboardMatch = useRouteMatch("/dashboard");
    const sidebarWidth = 240;

    const handleChangeProject = (item: SelectMenuItem) => {
      projects.setCurrent(item.value as string);
    
      history.push('/home');
    }

    const handleClickDashboard = () => {
      history.push('/dashboard');
    }

    const handleCreateNewProject = () => {
      ui?.showDialog(<NewProjectDialog />);
    }

    const handleOpenSupportWidget = (e: React.SyntheticEvent) => {
      e.preventDefault();

      (window as any).Chatra('show');
      (window as any).Chatra('expandWidget', true);
    }

    const {header, accountMenu, common} = translate();

    if(projects.noProjects){
      return <Redirect to="/welcome" />
    }

    return (
      <Pane display="flex" flex="1" flexDirection="column">
        {/* <Pane display="flex" paddingX={18} paddingY={16} justifyContent="space-between" alignItems="center" flex="1" borderBottom="default" backgroundColor="white" minHeight={50} width="100%" position="fixed" top={0} zIndex={9}>
          <Pane display="flex" alignItems="center">
            <Pane>
              <Heading size={600}>Wannadocs</Heading>
            </Pane>

            <Pane marginLeft={24} display="flex" alignItems="center">
              <Button style={{color: defaultTheme.colors.text.default }} 
                      marginRight={12} appearance="minimal"
                      isActive={dashboardMatch !== null}
                      onClick={handleClickDashboard} iconBefore={HomeIcon}>
                {header.dashboard}
              </Button>

              <ActionSelectMenu
                iconBefore={ProjectsIcon}
                title={header.changeProject}
                selected={projects.current?.id}
                options={projects.data.map((x) => ({label: x.name, value: x.id, icon: x.logoUrl}))}
                buttonTitle={projects.current?.name || header.projects}
                onSelect={handleChangeProject}
              >

                <PlusIcon onClick={handleCreateNewProject}/>
              </ActionSelectMenu>
            </Pane>
          </Pane>

          <Pane display="flex" alignItems="center">
            <SearchDialog />
            <AccountMenu />
          </Pane>
        </Pane> */}

        <Pane display="flex" flex="1">
          <Pane width={sidebarWidth} position="fixed" minHeight="100vh" flexDirection="column" backgroundColor={defaultTheme.colors.background.blueTint}>
            <Sidebar showPlaceholder={projects.current === null}/>
          </Pane>
          <Pane display="flex" flex="1" marginLeft={sidebarWidth}>
            <Pane display="flex" flex="1" flexDirection="column">

              {(projects.current && projects.current.payable === false) && <Pane maxHeight={75}  display="flex" flex="1" justifyContent="center" backgroundColor={defaultTheme.colors.background.redTint} paddingX={17} paddingY={24}>
                <Text color="#212121" size={400} display="flex" alignItems="center"> <ErrorIcon marginRight={4}/>
                  {common.haveOverduePayment},&nbsp;
                  <Link to="/settings/billing/pricing">{common.btnBuy.toLowerCase()}</Link>&nbsp;{common.or}&nbsp;<a href="#" onClick={handleOpenSupportWidget}>{accountMenu.contactSupport.toLowerCase()}</a>.
                </Text>
              </Pane>}

              {projects.current && projects.isReadonlyUser && <Pane maxHeight={75}  display="flex" flex="1" justifyContent="center" backgroundColor={defaultTheme.colors.background.greenTint} paddingX={17} paddingY={24}>
                <Text color="#212121" size={400} display="flex" alignItems="center"> <WarningSignIcon marginRight={4}/>
                {common.loggedAsReadOnlyAccount}
                </Text>
              </Pane>}

              {props.children}
              {ui?.dialog}
              <SearchDialog />
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    )
}));

export default inject('auth', 'ui', 'projects')(Layout);
