import * as React from 'react'
import { inject, observer } from 'mobx-react'
import UiStore from '../stores/UiStore'
import Dialog from './Dialog';
import { Pane, Spinner, Text } from 'evergreen-ui';

interface Props {
  ui?: UiStore,
  text: string
}

const WaitDialog = (observer((props: Props) => {
  const {ui, text} = props;

  const handleDialogClose = () => {
    ui.hideDialog();
  }

  return <Dialog
              width={600}
              isShown={true}
              onCloseComplete={handleDialogClose}
              hasFooter={false}
              hasHeader={false}>

            <Pane display="flex" flexDirection="column" alignItems="center" padding={20}>
              <Spinner />
              <Text size={400} marginTop={25}>{text}</Text>
            </Pane>
        </Dialog>
}));

export default inject('ui')(WaitDialog);