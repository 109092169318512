import { CaretRightIcon, defaultTheme } from "evergreen-ui";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

interface Props {
  expanded: boolean,
  onClick?: (e: React.SyntheticEvent) => void
}

const Caret = (observer((props: Props) => {
  const {expanded, onClick} = props;

  return <Wrapper deg={expanded ? 90 : 0} onClick={onClick}>
    <CaretRightIcon size={18}/>
  </Wrapper>
}));

const Wrapper = styled('div')<{deg?: number, onClick?: (e: React.SyntheticEvent) => void}>((props) => `
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  color: ${defaultTheme.colors.text.default};
  transform: rotate(${props.deg || 0}deg);

  &:hover {
    background: rgb(55, 53, 47, 0.08);
    border-radius: 5px;
  }
`)

export default Caret;